import {
  Grid,
  Typography,
  Box,
  Button,
  Paper,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
} from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { SystemConfigStore } from "../../../../Store";
import { useState } from "react";
import { APIURL } from "../../../../Context/configs";
import Cookies from "js-cookie";
export default function MandatoryTraining() {
  const [EditDiaOpen, SetEditDiaOpen] = useState(false);
  const [AddDiaOpen, SetAddDiaOpen] = useState(false);
  const [DeletDiaOpen, SetDeleteDiaOpen] = useState(false);
  const [TRToDo, SetTRToDo] = useState(0);
  const TrainingList = SystemConfigStore((state) => state.TrainingList);
  const SetTrainingList = SystemConfigStore((state) => state.SetTrainingList);
  const ChaneSnackBarShow = SystemConfigStore(
    (state) => state.ChaneSnackBarShow
  );
  const ChangeSnackMessage = SystemConfigStore(
    (state) => state.ChangeSnackMessage
  );
  const CloseDialog = () => {
    SetEditDiaOpen(false);
    SetAddDiaOpen(false);
    SetDeleteDiaOpen(false);
  };
  const openEditdialog = (tid) => {
    SetTRToDo(tid);
    SetEditDiaOpen(true);
  };
  const openDeleteialog = (tid) => {
    SetTRToDo(tid);
    SetDeleteDiaOpen(true);
    console.log(tid);
  };
  const openAddialog = () => {
    SetAddDiaOpen(true);
  };
  const NewTraining = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    fetch(APIURL + "manager/portaldata/NewTraining.php", {
      method: "post",
      body: JSON.stringify({
        trn: data.get("trn"),
        tru: data.get("tru"),
        IDEN: Cookies.get("userID"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          ChangeSnackMessage(item.content);
          if (item.id == 1) {
            ChaneSnackBarShow();
            CloseDialog();
            fetch(APIURL + "manager/portaldata/mandatorytraining.php", {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Request-Headers": "Content-Type, Authorization",
                "Access-Control-Allow-Credentials": "true",
              },
            })
              .then((res) => res.json())
              .then((result) => {
                SetTrainingList(result);
              });
          }
        });
      });
  };
  const UpdateTraining = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    fetch(APIURL + "manager/portaldata/UpdateTraining.php", {
      method: "post",
      body: JSON.stringify({
        trn: data.get("trn"),
        tru: data.get("tru"),
        tid: data.get("tid"),
        IDEN: Cookies.get("userID"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          ChangeSnackMessage(item.content);
          if (item.id == 1) {
            ChaneSnackBarShow();
            CloseDialog();
            fetch(APIURL + "manager/portaldata/mandatorytraining.php", {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Request-Headers": "Content-Type, Authorization",
                "Access-Control-Allow-Credentials": "true",
              },
            })
              .then((res) => res.json())
              .then((result) => {
                SetTrainingList(result);
              });
          }
        });
      });
  };
  const DeletTraining = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    fetch(APIURL + "manager/portaldata/DeleteTraining.php", {
      method: "post",
      body: JSON.stringify({
        tid: data.get("tid"),
        trn: data.get("trn"),
        IDEN: Cookies.get("userID"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          ChangeSnackMessage(item.content);
          if (item.id == 1) {
            ChaneSnackBarShow();
            CloseDialog();
            fetch(APIURL + "manager/portaldata/mandatorytraining.php", {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Request-Headers": "Content-Type, Authorization",
                "Access-Control-Allow-Credentials": "true",
              },
            })
              .then((res) => res.json())
              .then((result) => {
                SetTrainingList(result);
              });
          }
        });
      });
  };
  return (
    <>
      <Paper elevation={3} sx={{ mt: 2, p: 3 }}>
        <Grid container spacing={3}>
          <Grid item lg={6} md={12} sm={12}>
            <Typography variant="h5" color={"#2f3193"} sx={{ my: 2 }}>
              Mandatory Training
            </Typography>
          </Grid>
          <Grid item lg={6} md={12} sm={12}>
            <Button
              variant="contained"
              size="small"
              fullWidth
              sx={{ my: 2 }}
              onClick={openAddialog}
            >
              <ControlPointIcon sx={{ mx: 2 }} />
              Add New
            </Button>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            {TrainingList === null ? (
              <Typography
                variant="h5"
                textAlign={"center"}
                fontWeight={"bold"}
                marginY={2}
              >
                No Data To Preview
              </Typography>
            ) : (
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead sx={{ backgroundColor: "#333" }}>
                    <TableRow>
                      <TableCell sx={{ flex: 2, color: "#fff" }}>
                        Training Name
                      </TableCell>
                      <TableCell
                        sx={{ flex: 1, textAlign: "center", color: "#fff" }}
                      >
                        Manage
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {TrainingList.map((t) => (
                      <TableRow key={t.tr_id}>
                        <TableCell>{t.tr_name}</TableCell>
                        <TableCell sx={{ flex: 1, textAlign: "center" }}>
                          <IconButton
                            size="large"
                            edge="start"
                            color="primary"
                            aria-label="menu"
                            sx={{ m: 0, p: 1 }}
                            title="Edit Training"
                            onClick={(tid) => openEditdialog(t.tr_id)}
                          >
                            <BorderColorIcon />
                          </IconButton>
                          <IconButton
                            size="large"
                            edge="start"
                            color="error"
                            aria-label="menu"
                            sx={{ m: 0, p: 1 }}
                            title="Delete Training"
                            onClick={(tid) => openDeleteialog(t.tr_id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Dialog open={AddDiaOpen} onClose={CloseDialog} fullWidth maxWidth="md">
        <DialogTitle color={"#2f3193"}>Add New Training</DialogTitle>
        <DialogContent>
          <Box component="form" onSubmit={NewTraining} sx={{ mt: 1 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  variant="standard"
                  margin="normal"
                  required={true}
                  fullWidth
                  size="small"
                  id="trn"
                  label="Training Name"
                  name="trn"
                  autoComplete="trn"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  variant="standard"
                  margin="normal"
                  required={true}
                  fullWidth
                  size="small"
                  id="tru"
                  label="Training URL"
                  name="tru"
                  autoComplete="tru"
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12} sx={{ textAlign: "right" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Add Training
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  color="error"
                  sx={{ mt: 3, mb: 2, ml: 2 }}
                  onClick={CloseDialog}
                >
                  Close Form
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={DeletDiaOpen} onClose={CloseDialog} fullWidth maxWidth="md">
        <DialogTitle color={"#2f3193"}>Delete Training</DialogTitle>
        <DialogContent>
          <Box component="form" onSubmit={DeletTraining} sx={{ mt: 1 }}>
            <Grid item className="d-none">
              <TextField
                id="tid"
                label="tid"
                name="tid"
                autoComplete="tid"
                value={TRToDo}
              />
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {TrainingList.filter((t) => t.tr_id === TRToDo).map((row) => (
                  <Alert key={row.tr_id} severity="warning">
                    Are You Sure You Want To Delete Training {row.tr_name}
                    <Grid item className="d-none">
                      <TextField
                        id="trn"
                        label="trn"
                        name="trn"
                        autoComplete="trn"
                        value={row.tr_name}
                      />
                    </Grid>
                  </Alert>
                ))}
              </Grid>
              <Grid item xs={12} md={12} lg={12} sx={{ textAlign: "right" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Delete Training
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  color="error"
                  sx={{ mt: 3, mb: 2, ml: 2 }}
                  onClick={CloseDialog}
                >
                  Close Form
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={EditDiaOpen} onClose={CloseDialog} fullWidth maxWidth="md">
        <DialogTitle color={"#2f3193"}>Edit Training</DialogTitle>
        <DialogContent>
          <Box component="form" onSubmit={UpdateTraining} sx={{ mt: 1 }}>
            <Box className="d-none">
              <TextField
                id="tid"
                label="tid"
                name="tid"
                autoComplete="tid"
                value={TRToDo}
              />
            </Box>
            {TrainingList.filter((t) => t.tr_id === TRToDo).map((row) => (
              <Grid container spacing={3}>
                <Grid key={row.tr_id} item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    variant="standard"
                    margin="normal"
                    required={true}
                    fullWidth
                    size="small"
                    id="trn"
                    label="Training Name"
                    name="trn"
                    autoComplete="trn"
                    defaultValue={row.tr_name}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    variant="standard"
                    margin="normal"
                    required={true}
                    fullWidth
                    size="small"
                    id="tru"
                    label="Training URL"
                    name="tru"
                    autoComplete="tru"
                    defaultValue={row.tr_link}
                  />
                </Grid>
              </Grid>
            ))}
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12} sx={{ textAlign: "right" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Update Training
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  color="error"
                  sx={{ mt: 3, mb: 2, ml: 2 }}
                  onClick={CloseDialog}
                >
                  Close Form
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
