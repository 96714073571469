export const APIURL = "https://hrapi.my-soc.org/";
export const BASEURI = "https://iom.my-soc.org/";
export const STORAGEAPI = "https://storage.my-soc.org/";
export const AlternativeURI = "https://hr.my-soc.org/";
export const FilterYears = [
  {
    value: "2022",
    label: "2022",
  },
  {
    value: "2021",
    label: "2021",
  },
  {
    value: "2020",
    label: "2020",
  },
];
export const FilterMonths = [
  {
    value: "Jan",
    label: "January",
  },
  {
    value: "Feb",
    label: "February",
  },
  {
    value: "Mar",
    label: "March",
  },
  {
    value: "Apr",
    label: "April",
  },
  {
    value: "May",
    label: "May",
  },
  {
    value: "Jun",
    label: "June",
  },
  {
    value: "Jul",
    label: "July",
  },
  {
    value: "Aug",
    label: "August",
  },
  {
    value: "Sep",
    label: "September",
  },
  {
    value: "Oct",
    label: "Ocotober",
  },
  {
    value: "Nov",
    label: "November",
  },
  {
    value: "Dec",
    label: "December",
  },
];
