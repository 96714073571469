import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
  Grid,
  Button,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Alert,
} from "@mui/material";
import Cookies from "js-cookie";
import { useState, Fragment } from "react";
import { StaffStore } from "../../../../Store";
import { APIURL } from "../../../../Context/configs";
export default function EditDialog() {
  const UpdateStaff = StaffStore((state) => state.UpdateStaff);
  const ChaneEditDialog = StaffStore((state) => state.ChaneEditDialog);
  const ChaneSnackBarShow = StaffStore((state) => state.ChaneSnackBarShow);
  const ChangeSnackMessage = StaffStore((state) => state.ChangeSnackMessage);
  const StaffEditDialog = StaffStore((state) => state.StaffEditDialog);
  const StaffToHandle = StaffStore((state) => state.StaffToHandle);
  const Staff = StaffStore((state) => state.Staff);
  const [stfpriv, setstfpriv] = useState(0);
  const [iompriv, setiompriv] = useState(0);
  const [emppriv, setemppriv] = useState(0);
  const [paypriv, setpaypriv] = useState(0);
  const [leapriv, setleapriv] = useState(0);
  const [evapriv, setevapriv] = useState(0);
  const [syspriv, setsyspriv] = useState(0);
  const [formAction, setformAction] = useState("info");
  const [showWarning, SetshowWarning] = useState(false);
  const UpdateAdmin = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    fetch(APIURL + "manager/staff/editstaff.php", {
      method: "post",
      body: JSON.stringify({
        fn: data.get("fullname"),
        po: data.get("position"),
        em: data.get("email"),
        un: data.get("username"),
        pw: data.get("password"),
        cpw: data.get("cpassword"),
        p1: stfpriv,
        p2: iompriv,
        p3: emppriv,
        p4: paypriv,
        p5: leapriv,
        p6: evapriv,
        p7: syspriv,
        ref: data.get("ref"),
        act: formAction,
        adid: data.get("adid"),
        IDEN: Cookies.get("userID"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          ChangeSnackMessage(item.content);
          if (item.id == 1) {
            ChaneSnackBarShow();
            ChaneEditDialog();
            fetch(APIURL + "manager/staff/liststaff.php", {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Request-Headers": "Content-Type, Authorization",
                "Access-Control-Allow-Credentials": "true",
              },
            })
              .then((res) => res.json())
              .then((result) => {
                UpdateStaff(result);
              });
          }
        });
      });
  };
  const ChangeFormAction = (param) => {
    if (param === "privilege") {
      Staff.filter((data) => data.adminid === StaffToHandle).forEach((row) => {
        setstfpriv(row.staff);
        setiompriv(row.iom);
        setemppriv(row.employees);
        setpaypriv(row.payroll);
        setleapriv(row.leaves);
        setevapriv(row.evaluations);
        setsyspriv(row.systemlogs);
      });
    }
    setformAction(param);
  };
  const CloseDialog = () => {
    ChaneEditDialog();
    SetshowWarning(false);
    setformAction("info");
  };
  return (
    <Dialog
      open={StaffEditDialog}
      onClose={CloseDialog}
      maxWidth="md"
      fullWidth
    >
      {Staff.filter((st) => st.adminid === StaffToHandle).map((row) => (
        <Fragment key={row.adminid}>
          <DialogTitle color={"#2f3193"}>
            Edit SOC Staff {row.adminfullname}
          </DialogTitle>
          <DialogContent>
            <Box
              component="form"
              onSubmit={UpdateAdmin}
              noValidate
              sx={{ mt: 1 }}
            >
              <Box className="d-none">
                <TextField
                  type="hidden"
                  id="ref"
                  label="ref"
                  name="ref"
                  autoComplete="ref"
                  value={row.userref}
                />
                <TextField
                  type="hidden"
                  id="adid"
                  label="adid"
                  name="adid"
                  autoComplete="adid"
                  value={row.adminid}
                />
              </Box>
              <Grid container spacing={3}>
                <Grid item lg={12} md={12} sm={12} sx={{ textAlign: "right" }}>
                  <Button
                    color="primary"
                    onClick={() => ChangeFormAction("info")}
                  >
                    Personal Information
                  </Button>
                  <Button
                    color="warning"
                    onClick={() => ChangeFormAction("login")}
                  >
                    Account Login Information
                  </Button>
                  <Button
                    color="error"
                    onClick={() => ChangeFormAction("privilege")}
                  >
                    Staff Privilege
                  </Button>
                </Grid>
                {formAction === "info" && (
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography sx={{ fontWeight: "bold" }} color={"#2f3193"}>
                      Personal info
                    </Typography>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required={true}
                        fullWidth
                        size="small"
                        id="fullname"
                        label="Fullname"
                        name="fullname"
                        autoComplete="fullname"
                        autoFocus
                        defaultValue={row.adminfullname}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required={true}
                        fullWidth
                        size="small"
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        defaultValue={row.adminemail}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required={true}
                        fullWidth
                        size="small"
                        id="position"
                        label="Position"
                        name="position"
                        autoComplete="position"
                        defaultValue={row.adminposition}
                      />
                    </Grid>
                  </Grid>
                )}
                {formAction === "privilege" && (
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography
                      sx={{ fontWeight: "bold", mb: 2 }}
                      color={"#2f3193"}
                    >
                      Privileges
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        sx={{ display: "none" }}
                      >
                        <TextField
                          type="hidden"
                          id="fullname"
                          name="fullname"
                          value={row.adminfullname}
                        />
                      </Grid>
                      <Grid item lg={6} md={12} sm={12}>
                        <FormControl variant="standard" fullWidth size="small">
                          <InputLabel htmlFor="staff">SOC Staff</InputLabel>
                          <Select
                            labelId="staff"
                            id="staff"
                            label="staff"
                            onChange={(event) => setstfpriv(event.target.value)}
                            value={stfpriv}
                          >
                            <MenuItem value="1">Yes</MenuItem>
                            <MenuItem value="0">No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} md={12} sm={12}>
                        <FormControl variant="standard" fullWidth size="small">
                          <InputLabel htmlFor="iom">IOM HR</InputLabel>
                          <Select
                            labelId="iom"
                            id="iom"
                            label="iom"
                            onChange={(event) => setiompriv(event.target.value)}
                            value={iompriv}
                          >
                            <MenuItem value="1">Yes</MenuItem>
                            <MenuItem value="0">No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} md={12} sm={12}>
                        <FormControl variant="standard" fullWidth size="small">
                          <InputLabel htmlFor="employees">Employees</InputLabel>
                          <Select
                            labelId="employees"
                            id="employees"
                            label="employees"
                            onChange={(event) => setemppriv(event.target.value)}
                            value={emppriv}
                          >
                            <MenuItem value="1">Yes</MenuItem>
                            <MenuItem value="0">No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} md={12} sm={12}>
                        <FormControl variant="standard" fullWidth size="small">
                          <InputLabel htmlFor="payroll">Payroll</InputLabel>
                          <Select
                            labelId="payroll"
                            id="payroll"
                            label="payroll"
                            onChange={(event) => setpaypriv(event.target.value)}
                            value={paypriv}
                          >
                            <MenuItem value="1">Yes</MenuItem>
                            <MenuItem value="0">No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} md={12} sm={12}>
                        <FormControl variant="standard" fullWidth size="small">
                          <InputLabel htmlFor="leaves">
                            Leaves & Vacations
                          </InputLabel>
                          <Select
                            labelId="leaves"
                            id="leaves"
                            label="leaves"
                            onChange={(event) => setleapriv(event.target.value)}
                            value={leapriv}
                          >
                            <MenuItem value="1">Yes</MenuItem>
                            <MenuItem value="0">No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} md={12} sm={12}>
                        <FormControl variant="standard" fullWidth size="small">
                          <InputLabel htmlFor="evaluations">
                            Evaluations
                          </InputLabel>
                          <Select
                            labelId="evaluations"
                            id="evaluations"
                            label="evaluations"
                            onChange={(event) => setevapriv(event.target.value)}
                            value={evapriv}
                          >
                            <MenuItem value="1">Yes</MenuItem>
                            <MenuItem value="0">No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} md={12} sm={12}>
                        <FormControl variant="standard" fullWidth size="small">
                          <InputLabel htmlFor="systemlogs">
                            System Configuration
                          </InputLabel>
                          <Select
                            labelId="systemlogs"
                            id="systemlogs"
                            label="systemlogs"
                            onChange={(event) => setsyspriv(event.target.value)}
                            value={syspriv}
                          >
                            <MenuItem value="1">Yes</MenuItem>
                            <MenuItem value="0">No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {formAction === "login" && (
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography sx={{ fontWeight: "bold" }} color={"#2f3193"}>
                      Account info
                    </Typography>
                    <Grid item xs={12} md={12} lg={12} sx={{ display: "none" }}>
                      <TextField
                        type="hidden"
                        id="fullname"
                        name="fullname"
                        value={row.adminfullname}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required={true}
                        fullWidth
                        size="small"
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        defaultValue={row.username}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        type="password"
                        fullWidth
                        size="small"
                        id="password"
                        label="Password"
                        name="password"
                        autoComplete="password"
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        type="password"
                        fullWidth
                        size="small"
                        id="cpassword"
                        label="Confirm Password"
                        name="cpassword"
                        autoComplete="cpassword"
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} md={12} lg={12} sx={{ padding: 3, mt: 5 }}>
                  {showWarning && (
                    <Alert variant="outlined" severity="warning">
                      Password and Password confirmation is not matched
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ textAlign: "right" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Update SOC Staff
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="error"
                    sx={{ mt: 3, mb: 2, ml: 2 }}
                    onClick={CloseDialog}
                  >
                    Close Form
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </Fragment>
      ))}
    </Dialog>
  );
}
