import {
  Paper,
  Box,
  TextField,
  Grid,
  Button,
  Typography,
  Alert,
} from "@mui/material";
import { useState, useEffect } from "react";
import { APIURL } from "../../../../Context/configs";
import Cookies from "js-cookie";
export default function VideoPlayer() {
  const [DataToView, SetDatatoView] = useState([]);
  const [showWarning, SetshowWarning] = useState(false);
  const [WarningMessage, SetWarningMessage] = useState("");
  const [WarningColor, SetWarningColor] = useState("success");
  useEffect(() => {
    fetch(APIURL + "organization/account/accountview.php", {
      method: "post",
      body: JSON.stringify({
        ref: Cookies.get("userRef"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetDatatoView(result);
      });
  }, []);

  const UpdateAdmin = (event) => {
    SetshowWarning(false);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (data.get("password") === "" && data.get("cpassword") === "") {
    } else {
      if (data.get("password") != data.get("cpassword")) {
        SetWarningMessage("Password and Password confirmation is not matched");
        SetWarningColor("warning");
        setTimeout(() => {
          SetshowWarning(true);
        }, 1000);
      } else {
        fetch(APIURL + "organization/account/accountupdate.php", {
          method: "post",
          body: JSON.stringify({
            fn: data.get("fullname"),
            em: data.get("email"),
            ph: data.get("phone"),
            un: data.get("username"),
            pw: data.get("password"),
            cpw: data.get("cpassword"),
            ref: data.get("ref"),
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Request-Headers": "Content-Type, Authorization",
            "Access-Control-Allow-Credentials": "true",
          },
        })
          .then((res) => res.json())
          .then((result) => {
            result.forEach((item) => {
              SetWarningMessage(item.content);
              if (item.id == 1) {
                SetWarningColor("success");
                fetch(APIURL + "organization/account/accountview.php", {
                  method: "post",
                  body: JSON.stringify({
                    ref: Cookies.get("userRef"),
                  }),
                  headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Request-Headers":
                      "Content-Type, Authorization",
                    "Access-Control-Allow-Credentials": "true",
                  },
                })
                  .then((res) => res.json())
                  .then((result) => {
                    SetDatatoView(result);
                  });
              } else {
                SetWarningColor("error");
              }
            });
            SetshowWarning(true);
          });
      }
    }
    setTimeout(() => {
      SetshowWarning(false);
    }, 3000);
  };
  return (
    <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
      <Typography variant="h5" color={"#2f3193"} sx={{ mb: 2 }}>
        My Profile
      </Typography>
      {DataToView.map((row) => (
        <Box
          key={row.adminid}
          component="form"
          onSubmit={UpdateAdmin}
          noValidate
          sx={{ mt: 1 }}
        >
          <div className="d-none">
            <TextField
              type="hidden"
              id="ref"
              label="ref"
              name="ref"
              autoComplete="ref"
              value={row.ref}
            />
            <TextField
              type="hidden"
              id="adid"
              label="adid"
              name="adid"
              autoComplete="adid"
              value={row.adminid}
            />
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                variant="standard"
                margin="normal"
                required={true}
                fullWidth
                size="small"
                id="fullname"
                label="Fullname"
                name="fullname"
                autoComplete="fullname"
                autoFocus
                defaultValue={row.fullname}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                variant="standard"
                margin="normal"
                required={true}
                fullWidth
                size="small"
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                defaultValue={row.email}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                variant="standard"
                margin="normal"
                fullWidth
                size="small"
                id="phone"
                label="Phone"
                name="phone"
                autoComplete="phone"
                defaultValue={row.phone}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                variant="standard"
                margin="normal"
                required={true}
                fullWidth
                size="small"
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                defaultValue={row.username}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                variant="standard"
                margin="normal"
                type="password"
                fullWidth
                size="small"
                id="password"
                label="Password"
                name="password"
                autoComplete="password"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                variant="standard"
                margin="normal"
                type="password"
                fullWidth
                size="small"
                id="cpassword"
                label="Confirm Password"
                name="cpassword"
                autoComplete="cpassword"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {showWarning && (
                <Alert variant="outlined" severity={WarningColor}>
                  {WarningMessage}
                </Alert>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ textAlign: "right" }}
            >
              <Button
                type="submit"
                variant="contained"
                color="success"
                sx={{ mt: 3, mb: 2 }}
              >
                Update Profile
              </Button>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Paper>
  );
}
