import {
  Container,
  Grid,
  Breadcrumbs,
  Typography,
  Paper,
  Button,
  TextField,
  MenuItem,
  Box,
} from "@mui/material";

import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { APIURL, FilterMonths } from "../../../Context/configs";
import { RingLoader } from "../../Common/Loaders";
import { PayrollStore } from "../../../Store";
import ViewPayroll from "../Elements/Payroll/ViewPayroll";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
export default function Payroll() {
  const [isLoading, setisLoading] = useState(true);
  const [PisLoading, setPisLoading] = useState(false);
  const [ShowPayroll, SetShowPayroll] = useState(false);
  const [PageToView, SetPageToView] = useState("all");
  const [DateToView, SetDateToView] = useState("");
  const [GridData, SetGridData] = useState([]);
  const [MonthToview, SetMonthToView] = useState("");
  const [YearToview, SetYearToView] = useState("");
  const [NoDatatoPriv, SetNodataToPriv] = useState(false);
  const SetData = PayrollStore((state) => state.SetDataToShow);
  const ShowData = PayrollStore((state) => state.DataToShow);
  const SetYear = new Date().getFullYear();
  const SetMonth = new Date().toLocaleString("en-US", { month: "short" });
  const [YearsLister] = useState([]);
  useEffect(() => {
    document.title = "StarsOrbit HRM - Monthly Payroll";
    YearsLister.splice(0, YearsLister.length);
    for (let i = parseInt(new Date().getFullYear()); i > 2018; i--) {
      YearsLister.push({ y: i });
    }
    fetch(APIURL + "manager/payroll/payrollshowall.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setisLoading(false);
        SetData(result);
      });
  }, [YearsLister]);
  const ShowPayrollFRM = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let Dm = data.get("mf");
    let Dy = data.get("yf");
    SetMonthToView(Dm);
    SetYearToView(Dy);
    ShowPayrollComp("filter", Dm, Dy);
  };
  const ShowPayrollComp = (p1, p2, p3) => {
    SetShowPayroll(false);
    setPisLoading(true);
    SetPageToView(p1);
    SetDateToView(p2 + "-" + p3);
    if (ShowData === null) {
      SetNodataToPriv(true);
    } else {
      SetNodataToPriv(false);
      if (p1 === "all") {
        SetGridData(ShowData);
      } else {
        SetGridData(ShowData.filter((a) => a.month === p2 && a.year === p3));
      }
    }

    setTimeout(() => {
      setPisLoading(false);
      SetShowPayroll(true);
    }, 3000);
  };
  return (
    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink className="breabcrumblink" to="/admin">
              Admin Panel
            </NavLink>
            <Typography color="text.primary">Payroll</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      {isLoading ? (
        <Paper elevation={3} className="siteLoader">
          <RingLoader />
        </Paper>
      ) : (
        <>
          <Box component="form" onSubmit={ShowPayrollFRM}>
            <Grid container spacing={3} sx={{ mb: 3, mt: 3 }}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={6} md={4} lg={4} sx={{ my: 1 }}>
                      <TextField
                        select
                        size="small"
                        fullWidth
                        label="Year"
                        name="yf"
                        defaultValue={SetYear}
                      >
                        {YearsLister.map((y) => (
                          <MenuItem key={y.y} value={y.y}>
                            {y.y}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4} sx={{ my: 1 }}>
                      <TextField
                        select
                        size="small"
                        fullWidth
                        label="Month"
                        defaultValue={SetMonth}
                        name="mf"
                      >
                        {FilterMonths.map((FM) => (
                          <MenuItem key={FM.value} value={FM.value}>
                            {FM.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2} sx={{ my: 1 }}>
                      <Button
                        variant="outlined"
                        fullWidth
                        color="success"
                        type="submit"
                      >
                        <FilterAltIcon sx={{ mr: 2 }} /> Filter
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2} sx={{ my: 1 }}>
                      <Button
                        variant="outlined"
                        fullWidth
                        color="warning"
                        onClick={() => ShowPayrollComp("all", "")}
                      >
                        <FormatListBulletedIcon sx={{ mr: 2 }} /> View All
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Box>
          {PisLoading ? (
            <Paper elevation={3} className="siteLoader" sx={{ mt: 3 }}>
              <RingLoader />
            </Paper>
          ) : (
            <>
              {ShowPayroll && (
                <>
                  {NoDatatoPriv ? (
                    <Paper elevation={3} className="siteLoader" sx={{ mt: 3 }}>
                      <Typography sx={{ textAlign: "center" }} variant="h5">
                        No Data To Preview
                      </Typography>
                    </Paper>
                  ) : (
                    <ViewPayroll
                      datarype={PageToView}
                      datadate={DateToView}
                      data={GridData}
                      month={MonthToview}
                      year={YearToview}
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
}
