import {
  Container,
  Grid,
  Breadcrumbs,
  Typography,
  Paper,
  Button,
  TextField,
  MenuItem,
  Box,
} from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { APIURL, FilterMonths } from "../../../Context/configs";
import { RingLoader } from "../../Common/Loaders";
import { SoftwareStore } from "../../../Store";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AddCardIcon from "@mui/icons-material/AddCard";
import {
  ActionMessage,
  ViewPayroll,
  NewPayroll,
  Loading,
} from "../Elements/SoftwareData";
export default function SoftwareData() {
  const [isLoading, setisLoading] = useState(true);
  const [PisLoading, setPisLoading] = useState(false);
  const [ViewPay, setViewPay] = useState(false);
  const OpenNew = SoftwareStore((state) => state.ChaneNewPayShow);
  const SetDataToShow = SoftwareStore((state) => state.SetDataToShow);
  const SetYear = new Date().getFullYear();
  const SetMonth = new Date().toLocaleString("en-US", { month: "short" });
  const [YearsLister] = useState([]);
  useEffect(() => {
    document.title = "StarsOrbit HRM - Software Data";
    YearsLister.splice(0, YearsLister.length);
    for (let i = parseInt(new Date().getFullYear()); i > 2018; i--) {
      YearsLister.push({ y: i });
    }
    setTimeout(() => {
      setisLoading(false);
    }, 3000);
  }, [YearsLister]);
  const ShowPayrollFRM = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setPisLoading(true);
    fetch(APIURL + "manager/software/grabdata.php", {
      method: "post",
      body: JSON.stringify({
        Dm: data.get("mf"),
        Dy: data.get("yf"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetDataToShow(result);
        setPisLoading(false);
        setViewPay(true);
      });
  };
  return (
    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink className="breabcrumblink" to="/admin">
              Admin Panel
            </NavLink>
            <Typography color="text.primary">Software Data</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      {isLoading ? (
        <Paper elevation={3} className="siteLoader">
          <RingLoader />
        </Paper>
      ) : (
        <>
          <Box sx={{ textAlign: "right" }}>
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={OpenNew}
            >
              <AddCardIcon sx={{ mr: 2 }} /> Add New Payroll Sheet
            </Button>
          </Box>
          <Box component="form" onSubmit={ShowPayrollFRM}>
            <Grid container spacing={3} sx={{ mb: 3, mt: 3 }}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={6} md={5} lg={5} sx={{ my: 1 }}>
                      <TextField
                        select
                        size="small"
                        fullWidth
                        label="Year"
                        name="yf"
                        defaultValue={SetYear}
                      >
                        {YearsLister.map((y) => (
                          <MenuItem key={y.y} value={y.y}>
                            {y.y}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={6} sm={6} md={5} lg={5} sx={{ my: 1 }}>
                      <TextField
                        select
                        size="small"
                        fullWidth
                        label="Month"
                        defaultValue={SetMonth}
                        name="mf"
                      >
                        {FilterMonths.map((FM) => (
                          <MenuItem key={FM.value} value={FM.value}>
                            {FM.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2} sx={{ my: 1 }}>
                      <Button
                        variant="outlined"
                        fullWidth
                        color="success"
                        type="submit"
                      >
                        <FilterAltIcon sx={{ mr: 2 }} /> Filter
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Box>
          {PisLoading ? (
            <Box sx={{ textAlign: "center" }}>
              <RingLoader />
            </Box>
          ) : (
            <Box>{ViewPay && <ViewPayroll />}</Box>
          )}
        </>
      )}
      <Loading />
      <ActionMessage />
      <NewPayroll />
    </Container>
  );
}
