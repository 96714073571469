import { Paper, Grid } from "@mui/material";
import { HealthInsStore } from "../../../../Store";
import HTMLReactParser from "html-react-parser";
import HIAr from "../../../../Assets/VID/hi-arabic.mp4";
import HIEn from "../../../../Assets/VID/hi-english.mp4";
import { STORAGEAPI } from "../../../../Context/configs";

export default function HiPage() {
  const DataToShow = HealthInsStore((state) => state.DataToShow);
  return (
    <Paper elevation={3} sx={{ border: "1px #999 solid", mt: 4, mb: 4, p: 3 }}>
      <Grid container spacing={2}>
        {DataToShow.map((d) => (
          <Grid
            key={d.id}
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className={d.lang === "english" ? "EngHI" : "AraHI"}
          >
            {HTMLReactParser(d.text)}
          </Grid>
        ))}
        <Grid item xs={12} sm={12} md={12} lg={6} sx={{ mt: 2, mb: 2 }}>
          <video src={HIEn} className="videoPlayer" controls />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} sx={{ mt: 2, mb: 2 }}>
          <video src={HIAr} className="videoPlayer" controls />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} sx={{ mt: 2, mb: 2 }}>
          <a
            target="_blank"
            href={STORAGEAPI + "HI/Health-Insurance-Benefits.pdf"}
            className="HiLinks"
          >
            Health Insurance Benefits
          </a>
          <a
            target="_blank"
            href={STORAGEAPI + "HI/Reimbursement-Claim.pdf"}
            className="HiLinks"
          >
            Reimbursement Claim
          </a>
          <a
            target="_blank"
            href={STORAGEAPI + "HI/User-Guide.pdf"}
            className="HiLinks"
          >
            User Guide
          </a>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} sx={{ mt: 2, mb: 2 }}>
          <a
            target="_blank"
            href={STORAGEAPI + "HI/جدول-المنافع.pdf"}
            className="HiLinks"
          >
            جدول المنافع
          </a>
          <a
            target="_blank"
            href={STORAGEAPI + "HI/اعادة-التسديد.pdf"}
            className="HiLinks"
          >
            اعادة التسديد
          </a>
          <a
            target="_blank"
            href={STORAGEAPI + "HI/دليل-المستخدم.pdf"}
            className="HiLinks"
          >
            دليل المستخدم
          </a>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <a
            target="_blank"
            href={STORAGEAPI + "HI/Network-Iraq.ppsx"}
            className="HiLinks"
          >
            Network Iraq - الشبكة في العراق
          </a>
        </Grid>
      </Grid>
    </Paper>
  );
}
