import { Paper, Box, Grid, Typography } from "@mui/material";
import { EvaluationProceessStore } from "../../../../Store";
import HTMLReactParser from "html-react-parser";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfViewer from "./PdfViewer";
export default function View() {
  const Eval = EvaluationProceessStore((state) => state.Eval);
  return (
    <Box>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography variant="h6" className="dateview">
          Employee Ojectives
        </Typography>
        {Eval[0].objectives === null ? (
          <Typography variant="h6" sx={{ textAlign: "center", my: 1 }}>
            No Objectives Applied By Employee
          </Typography>
        ) : (
          <Box
            sx={{
              my: 1,
            }}
          >
            {HTMLReactParser(Eval[0].objectives)}
          </Box>
        )}
      </Paper>
      {Eval[0].ds_done === 1 ? (
        <Paper elevation={3} sx={{ p: 2, my: 2 }}>
          <Typography variant="h6" className="dateview">
            A. Achievements against Terms of Reference
          </Typography>
          <Typography variant="body2" className="dateview" sx={{ mt: 2 }}>
            * To be filled by Supervisor
          </Typography>
          <Box sx={{ my: 1 }}>
            <Typography variant="body2">
              Rating: <span className="dateview">{Eval[0].rating}</span>
            </Typography>
          </Box>
          <Box sx={{ my: 1 }}>
            <Typography variant="body2">
              COMMENTS ON OVERALL PERFORMANCE :
            </Typography>
            {Eval[0].ds_comment === null ? (
              <Typography variant="h6" sx={{ textAlign: "center", my: 1 }}>
                Focal Point Not Commented Yet
              </Typography>
            ) : (
              <Box
                sx={{
                  my: 1,
                }}
              >
                {HTMLReactParser(Eval[0].ds_comment)}
              </Box>
            )}
          </Box>
          <Grid
            container
            spacing={1}
            sx={{
              my: 1,
            }}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant="body2">
                Focal Point: <span className="dateview">{Eval[0].ds_name}</span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ textAlign: "end" }}>
              <Typography variant="body2">
                Comment Date:{" "}
                <span className="dateview">{Eval[0].ds_comment_date}</span>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <Paper elevation={3} sx={{ p: 2, my: 2 }}>
          <Typography
            variant="h6"
            className="dateview"
            sx={{ textAlign: "center" }}
          >
            Focal Point Did Not Evaluate Yet
          </Typography>
        </Paper>
      )}
      {Eval[0].sl_done === 1 ? (
        <Paper elevation={3} sx={{ p: 2, my: 2 }}>
          <Typography variant="h6" className="dateview">
            B. COMMENTS BY 2<sup>ND</sup> Level Supervisor (if any)
          </Typography>
          <Typography variant="body2" className="dateview" sx={{ mt: 2 }}>
            * To be filled by 2<sup>nd</sup> Level Supervisor
          </Typography>
          <Typography variant="body2" sx={{ mb: 1, mt: 2 }}>
            COMMENTS ON OVERALL PERFORMANCE :
          </Typography>
          {Eval[0].ds_comment === null ? (
            <Typography variant="h6" sx={{ textAlign: "center", my: 1 }}>
              Head of Sub Office Not Commented Yet
            </Typography>
          ) : (
            <Box
              sx={{
                my: 1,
              }}
            >
              {HTMLReactParser(Eval[0].sl_comment)}
            </Box>
          )}
          <Grid
            container
            spacing={1}
            sx={{
              my: 1,
            }}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant="body2">
                Head of Sub Office:{" "}
                <span className="dateview">{Eval[0].sl_name}</span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ textAlign: "end" }}>
              <Typography variant="body2">
                Comment Date:{" "}
                <span className="dateview">{Eval[0].sl_comment_date}</span>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <Paper elevation={3} sx={{ p: 2, my: 2 }}>
          <Typography
            variant="h6"
            className="dateview"
            sx={{ textAlign: "center" }}
          >
            Head Of Sub Office Did Not Evaluate Yet
          </Typography>
        </Paper>
      )}
      {Eval[0].staff_done === 1 ? (
        <Paper elevation={3} sx={{ p: 2, my: 2 }}>
          <Typography variant="h6" className="dateview">
            C. Comments by Staff Members on his/her own performance
          </Typography>
          <Typography variant="body2" className="dateview" sx={{ mt: 2 }}>
            * To be filled by staff
          </Typography>
          <Box sx={{ my: 1 }}>
            <Typography variant="body" className="text-soc">
              {HTMLReactParser(Eval[0].staff_agreement)}
            </Typography>
          </Box>
          <Box sx={{ my: 1 }}>
            <Typography variant="body2">COMMENTS</Typography>
            {Eval[0].ds_comment === null ? (
              <Typography variant="h6" sx={{ textAlign: "center", my: 1 }}>
                Focal Point Not Commented Yet
              </Typography>
            ) : (
              <Box
                sx={{
                  my: 1,
                }}
              >
                {HTMLReactParser(Eval[0].staff_comment)}
              </Box>
            )}
          </Box>
          <Grid
            container
            spacing={1}
            sx={{
              my: 1,
            }}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant="body2">
                Staff: <span className="dateview">{Eval[0].fullname}</span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ textAlign: "end" }}>
              <Typography variant="body2">
                Comment Date:{" "}
                <span className="dateview">{Eval[0].staff_comment_date}</span>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <Paper elevation={3} sx={{ p: 2, my: 2 }}>
          <Typography
            variant="h6"
            className="dateview"
            sx={{ textAlign: "center" }}
          >
            Staff Did Not Evaluate Yet
          </Typography>
        </Paper>
      )}
      {Eval[0].progress === 100 && (
        <Box sx={{ my: 2, textAlign: "end" }}>
          <PDFDownloadLink
            className="pdfLink"
            document={<PdfViewer evData={Eval} />}
            fileName={
              "Evaluation of " +
              Eval[0].fullname +
              " For " +
              Eval[0].req_formonth +
              " - " +
              Eval[0].req_foryear +
              ".pdf"
            }
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Download PDF"
            }
          </PDFDownloadLink>
        </Box>
      )}
    </Box>
  );
}
