import ForumSharpIcon from "@mui/icons-material/ForumSharp";
import { Paper, Typography } from "@mui/material";
import { LeaveProceessStore } from "../../../../Store";
export default function LeaveHeader() {
  const Leave = LeaveProceessStore((state) => state.Leave);
  return (
    <Paper
      elevation={2}
      sx={{
        backgroundColor: "#333",
        px: 1,
        py: 3,
        display: "flex",
        alignItems: "center",
      }}
    >
      <ForumSharpIcon sx={{ mx: 3, color: "#fff" }} />
      <Typography variant="h6" color={"#fff"}>
        Leave Cancel Request From ({Leave[0].soc_ref}) {Leave[0].fullname}
      </Typography>
    </Paper>
  );
}
