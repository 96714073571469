import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  Box,
  Typography,
  TextField,
} from "@mui/material";
import ReactQuill from "react-quill";
import { APIURL } from "../../../../Context/configs";
import { EmpDataStore, EmployeesStore } from "../../../../Store";
export default function PerDialog() {
  const Objectivetkn = EmpDataStore((state) => state.Objectivetkn);
  const EmpData = EmployeesStore((state) => state.EmpData);
  const SetObjectiveApp = EmpDataStore((state) => state.SetObjectiveApp);
  const SetSnackBarShow = EmpDataStore((state) => state.SetSnackBarShow);
  const SetSnackMessage = EmpDataStore((state) => state.SetSnackMessage);
  const SetSnackBarType = EmpDataStore((state) => state.SetSnackBarType);
  const SetLoadingShow = EmpDataStore((state) => state.SetLoadingShow);
  const SetRequestDialog = EmpDataStore((state) => state.SetRequestDialog);
  const RequestDialog = EmpDataStore((state) => state.RequestDialog);
  const CloseDialog = () => {
    SetRequestDialog();
  };
  const sendRequest = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    fetch(APIURL + "employee/evaluation/ObjectivePermission.php", {
      method: "post",
      body: JSON.stringify({
        soc: data.get("soc"),
        fn: data.get("fullname"),
        sn: data.get("svn"),
        se: data.get("sve"),
        tkn: data.get("tkn"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetSnackMessage(item.content);
          SetSnackBarType("info");
          setTimeout(() => {
            SetSnackBarShow();
            SetLoadingShow();
            CloseDialog();
          }, 2000);
        });
      });
  };
  return (
    <Dialog open={RequestDialog} onClose={CloseDialog} maxWidth="lg" fullWidth>
      <DialogTitle color={"#2f3193"}>
        Request Permission TO Edit Objectives
      </DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={sendRequest} sx={{ mt: 1 }}>
          {EmpData.map((e) => (
            <Grid key={e.lid} container spacing={3}>
              <div className="d-none">
                <TextField id="soc" name="soc" defaultValue={e.ref} />
                <TextField
                  id="fullname"
                  name="fullname"
                  defaultValue={e.fullname}
                />
                <TextField id="tkn" name="tkn" defaultValue={Objectivetkn} />
              </div>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  variant="standard"
                  margin="normal"
                  required
                  fullWidth
                  size="small"
                  id="svn"
                  label="Focal Point Name"
                  name="svn"
                  autoComplete="svn"
                  autoFocus
                  defaultValue={e.supervisor}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  variant="standard"
                  margin="normal"
                  required
                  fullWidth
                  size="small"
                  id="sve"
                  label="Focal Point Email"
                  name="sve"
                  autoComplete="sve"
                  defaultValue={e.svemail}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: "right" }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Send Request
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  color="error"
                  sx={{ mt: 3, mb: 2, ml: 2 }}
                  onClick={CloseDialog}
                >
                  Close Form
                </Button>
              </Grid>
            </Grid>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
