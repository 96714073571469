import { Container, Grid, Breadcrumbs, Typography, Paper } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { RingLoader } from "../../Common/Loaders";
import VideoPlayer from "../Elements/Tutorial/VideoPlayer";

export default function Tutorial() {
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    document.title = "StarsOrbit HRM - How To Use";
    setTimeout(() => {
      setisLoading(false);
    }, 3000);
  }, []);
  return (
    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink className="breabcrumblink" to="/iom">
              IOM HR Panel
            </NavLink>
            <Typography color="text.primary">How To Use</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      {isLoading ? (
        <Paper elevation={3} className="siteLoader">
          <RingLoader />
        </Paper>
      ) : (
        <VideoPlayer />
      )}
    </Container>
  );
}
