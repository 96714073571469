import { Box, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { APIURL } from "../../../Context/configs";
import { EmpDataStore, EmployeesStore } from "../../../Store";
import { RingLoader } from "../../Common/Loaders";
import { BC, Loading, ActionMessage } from "../Elements/Common/";
import {
  DeleteLeave,
  LeaveCalendar,
  LeaveList,
  RequestLeave,
  LeaveBalances,
  LeavesNav,
  CancelLeave,
} from "../Elements/Leaves";
export default function Leaves() {
  const EmpData = EmployeesStore((state) => state.EmpData);
  const SetLeaveList = EmpDataStore((state) => state.SetLeaveList);
  const SetLeaveBalances = EmpDataStore((state) => state.SetLeaveBalances);
  const SetLeaveTaken = EmpDataStore((state) => state.SetLeaveTaken);
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    document.title = "StarsOrbit HRM - Vacations & Leaves";
    fetch(APIURL + "employee/leave/listbalance.php", {
      method: "post",
      body: JSON.stringify({
        soc: EmpData[0].ref,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetLeaveBalances(result);
      });
    fetch(APIURL + "employee/leave/takenleaves.php", {
      method: "post",
      body: JSON.stringify({
        soc: EmpData[0].ref,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetLeaveTaken(result);
      });
    fetch(APIURL + "employee/leave/listleaves.php", {
      method: "post",
      body: JSON.stringify({
        soc: EmpData[0].ref,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetLeaveList(result);
      });
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  }, []);
  return (
    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
      <BC data="Vacations & Leaves" />
      {isLoading ? (
        <Box sx={{ py: 5, my: 5, textAlign: "center" }}>
          <RingLoader />
        </Box>
      ) : (
        <>
          <LeavesNav />
          <RequestLeave />
          <LeaveBalances />
          <LeaveList />
          <LeaveCalendar />
          <DeleteLeave />
          <CancelLeave />
          <ActionMessage />
          <Loading />
        </>
      )}
    </Container>
  );
}
