import { Routes, Route, Navigate } from "react-router-dom";
import {
  EmployeeContainer,
  OrganizationContainer,
  AdminContainer,
} from "./Contianers";
import {
  LoginPage,
  ResetPage,
  Evaluation,
  CancelLeave,
  ApproveLeave,
  Objective,
} from "./Components/PortalHome/Pages";
import { userLoginStore } from "./Store";
export default function App() {
  const LoggedIn = userLoginStore((state) => state.isLoggedIn);
  const userRole = userLoginStore((state) => state.userRole);
  const isFirstRun = userLoginStore((state) => state.isFirstRun);
  const NavLinkToGo = () => {
    return <Navigate to={"/" + userRole} />;
  };
  return (
    <Routes>
      {isFirstRun ? (
        <Route
          exact
          path="/"
          element={LoggedIn ? <NavLinkToGo /> : <LoginPage />}
        />
      ) : (
        <Route exact path="/" element={<LoginPage />} />
      )}

      <Route exact path="/login" element={<LoginPage />} />
      <Route exact path="/reset" element={<ResetPage />} />
      <Route exact path="/evaluation/:ev/*" element={<Evaluation />} />
      <Route exact path="/approveleave/:lv/*" element={<ApproveLeave />} />
      <Route exact path="/cancelleave/:lv/*" element={<CancelLeave />} />
      <Route exact path="/objective/:obj/*" element={<Objective />} />
      <Route path="/admin/*" element={<AdminContainer />} />
      <Route path="/iom/*" element={<OrganizationContainer />} />
      <Route path="/employee/*" element={<EmployeeContainer />} />
    </Routes>
  );
}
