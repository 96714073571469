import { create } from "zustand";
const SystemConfigStore = create((set) => ({
  LogsShow: [],
  TrainingList: [],
  SysConfShow: [],
  SysMainShow: [],
  SnackBarShow: false,
  SnackBarMessage: "",
  LoadingShow: false,
  SetTrainingList: (data) => set((state) => ({ TrainingList: data })),
  SetSysMainShow: (data) => set((state) => ({ SysMainShow: data })),
  SetSysConfShow: (data) => set((state) => ({ SysConfShow: data })),
  SetLogsShow: (data) => set((state) => ({ LogsShow: data })),
  SetLoadingShow: () => set((state) => ({ LoadingShow: !state.LoadingShow })),
  ChaneSnackBarShow: () =>
    set((state) => ({ SnackBarShow: !state.SnackBarShow })),
  ChangeSnackMessage: (mes) => set((state) => ({ SnackBarMessage: mes })),
}));

export default SystemConfigStore;
