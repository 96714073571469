import { create } from "zustand";
const LeaveProceessStore = create((set) => ({
  Leave: [],
  LeaveBal: [],
  SnackBarShow: false,
  SnackBarType: "success",
  SnackBarMessage: "",
  LoadingShow: false,
  SetLeave: (data) => set((state) => ({ Leave: data })),
  SetLeaveBal: (data) => set((state) => ({ LeaveBal: data })),
  SetSnackBarShow: () =>
    set((state) => ({ SnackBarShow: !state.SnackBarShow })),
  SetLoadingShow: () => set((state) => ({ LoadingShow: !state.LoadingShow })),
  SetSnackMessage: (mes) => set((state) => ({ SnackBarMessage: mes })),
  SetSnackBarType: (t) => set((state) => ({ SnackBarType: t })),
}));

export default LeaveProceessStore;
