import {
  Paper,
  Box,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Divider,
  Button,
} from "@mui/material";
import { EvaluationProceessStore } from "../../../../Store";
import HTMLReactParser from "html-react-parser";
import ReactQuill from "react-quill";
import { useState } from "react";
import { APIURL } from "../../../../Context/configs";
export default function FocalPoint() {
  const [commentText, setcommentText] = useState("");
  const Eval = EvaluationProceessStore((state) => state.Eval);
  const SetSnackBarShow = EvaluationProceessStore(
    (state) => state.SetSnackBarShow
  );
  const SetLoadingShow = EvaluationProceessStore(
    (state) => state.SetLoadingShow
  );
  const SetSnackMessage = EvaluationProceessStore(
    (state) => state.SetSnackMessage
  );
  const SetSnackBarType = EvaluationProceessStore(
    (state) => state.SetSnackBarType
  );
  const Evaluate = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    fetch(APIURL + "portalhome/evaluation/doevaluation.php", {
      method: "post",
      body: JSON.stringify({
        ev: data.get("evid"),
        cd: data.get("dur"),
        cdt: data.get("dt"),
        ra: data.get("ra"),
        com: commentText,
        act: "SV",
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetSnackMessage(item.content);
          if (item.id == 1) {
            SetSnackBarType("success");
          } else {
            SetSnackBarType("error");
          }
        });
        setTimeout(() => {
          SetSnackBarShow();
          SetLoadingShow();
        }, 2000);
      });
  };
  return (
    <Box component="form" onSubmit={Evaluate}>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography variant="h6" className="dateview">
          Employee Ojectives
        </Typography>
        {Eval[0].objectives === null ? (
          <Typography variant="h6" sx={{ textAlign: "center", my: 1 }}>
            No Objectives Applied By Employee
          </Typography>
        ) : (
          <Box
            sx={{
              my: 1,
            }}
          >
            {HTMLReactParser(Eval[0].objectives)}
          </Box>
        )}
      </Paper>
      <Paper elevation={3} sx={{ p: 2, my: 2 }}>
        <div className="d-none">
          <TextField value={Eval[0].eval_id} id="evid" name="evid" />
        </div>
        <Typography variant="h6" className="dateview">
          Employee Contract Duration
        </Typography>
        <Grid
          container
          spacing={1}
          sx={{
            my: 1,
          }}
        >
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <TextField
              size="small"
              fullWidth
              type="number"
              defaultValue={1}
              id="dur"
              name="dur"
              required
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              size="small"
              fullWidth
              select
              defaultValue="Month"
              id="dt"
              name="dt"
              required
            >
              <MenuItem value="Month">Month</MenuItem>
              <MenuItem value="Year">Year</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Typography variant="h6" className="dateview">
          A. Achievements against Terms of Reference
        </Typography>
        <Typography variant="body2" className="dateview" sx={{ mt: 2 }}>
          * To be filled by Supervisor
        </Typography>
        <Grid
          container
          spacing={1}
          sx={{
            my: 1,
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              size="small"
              fullWidth
              select
              defaultValue="Exceeds Expectation"
              id="ra"
              name="ra"
              required
              label="Rating"
            >
              <MenuItem value="Exceeds Expectation">
                Exceeds Expectation
              </MenuItem>
              <MenuItem value="Fully Satisfied">Fully Satisfied</MenuItem>
              <MenuItem value="Satisfied">Satisfied</MenuItem>
              <MenuItem value="Falls Below Expectation">
                Falls Below Expectation
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="body2" sx={{ mb: 1, mt: 2 }}>
              COMMENTS ON OVERALL PERFORMANCE :
            </Typography>
            <ReactQuill
              theme="snow"
              value={commentText}
              onChange={setcommentText}
            />
          </Grid>
        </Grid>
        <Divider />
        <Box sx={{ textAlign: "end", my: 2 }}>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            size="small"
            color="primary"
          >
            Apply Evaluation
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
