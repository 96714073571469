import { create } from "zustand";
const dashBoardStore = create((set) => ({
  widgets: [],
  setwidgets: (data) => set((state) => ({ widgets: data })),
  l10Leav: [],
  setl10Leav: (lv) => set((state) => ({ l10Leav: lv })),
  l10Eval: [],
  setl10Eval: (eva) => set((state) => ({ l10Eval: eva })),
}));

export default dashBoardStore;
