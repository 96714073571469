import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  Box,
  Typography,
  TextField,
} from "@mui/material";
import ReactQuill from "react-quill";
import { APIURL } from "../../../../Context/configs";
import { EmpDataStore, EmployeesStore } from "../../../../Store";

export default function ObjDialog() {
  const Objective = EmpDataStore((state) => state.Objective);
  const Objectivetkn = EmpDataStore((state) => state.Objectivetkn);
  const EmpData = EmployeesStore((state) => state.EmpData);
  const SetObjective = EmpDataStore((state) => state.SetObjective);
  const SetObjectiveApp = EmpDataStore((state) => state.SetObjectiveApp);
  const SetSnackBarShow = EmpDataStore((state) => state.SetSnackBarShow);
  const SetSnackMessage = EmpDataStore((state) => state.SetSnackMessage);
  const SetSnackBarType = EmpDataStore((state) => state.SetSnackBarType);
  const SetLoadingShow = EmpDataStore((state) => state.SetLoadingShow);
  const SetObjectiveDialog = EmpDataStore((state) => state.SetObjectiveDialog);
  const ObjectiveDialog = EmpDataStore((state) => state.ObjectiveDialog);

  const CloseDialog = () => {
    SetObjectiveDialog();
  };
  const sendRequest = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    fetch(APIURL + "employee/evaluation/updateobjectives.php", {
      method: "post",
      body: JSON.stringify({
        soc: data.get("soc"),
        fn: data.get("fullname"),
        sn: data.get("svn"),
        se: data.get("sve"),
        tkn: data.get("tkn"),
        obj: Objective,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetSnackMessage(item.content);
          if (item.id === 1) {
            SetSnackBarType("success");
            setTimeout(() => {
              SetSnackBarShow();
              fetch(APIURL + "employee/evaluation/employeeobjectives.php", {
                method: "post",
                body: JSON.stringify({
                  soc: EmpData[0].ref,
                }),
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Request-Headers":
                    "Content-Type, Authorization",
                  "Access-Control-Allow-Credentials": "true",
                },
              })
                .then((res) => res.json())
                .then((result) => {
                  result.forEach((item) => {
                    SetObjective(item.objectives);
                    SetObjectiveApp(item.approved);
                  });
                  SetLoadingShow();
                  CloseDialog();
                });
            }, 2000);
          } else {
            SetSnackBarType("error");
            setTimeout(() => {
              SetSnackBarShow();
              SetLoadingShow();
              CloseDialog();
            }, 2000);
          }
        });
      });
  };
  const QuillChange = (html) => {
    SetObjective(html);
  };
  return (
    <Dialog
      open={ObjectiveDialog}
      onClose={CloseDialog}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle color={"#2f3193"}>Edit My Objectives</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={sendRequest} sx={{ mt: 1 }}>
          {EmpData.map((e) => (
            <Grid key={e.lid} container spacing={3}>
              <div className="d-none">
                <TextField id="soc" name="soc" defaultValue={e.ref} />
                <TextField
                  id="fullname"
                  name="fullname"
                  defaultValue={e.fullname}
                />
                <TextField id="tkn" name="tkn" defaultValue={Objectivetkn} />
              </div>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  variant="standard"
                  margin="normal"
                  required
                  fullWidth
                  size="small"
                  id="svn"
                  label="Focal Point Name"
                  name="svn"
                  autoComplete="svn"
                  autoFocus
                  defaultValue={e.supervisor}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  variant="standard"
                  margin="normal"
                  required
                  fullWidth
                  size="small"
                  id="sve"
                  label="Focal Point Email"
                  name="sve"
                  autoComplete="sve"
                  defaultValue={e.svemail}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="body" sx={{ fontWeight: "bold" }}>
                  My Objectives
                </Typography>
                <ReactQuill
                  theme="snow"
                  value={Objective}
                  onChange={QuillChange}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: "right" }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Send Request
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  color="error"
                  sx={{ mt: 3, mb: 2, ml: 2 }}
                  onClick={CloseDialog}
                >
                  Close Form
                </Button>
              </Grid>
            </Grid>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
