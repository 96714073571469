import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { StaffStore } from "../../../../Store";
export default function StaffList() {
  const StaffList = StaffStore((state) => state.Staff);
  const SetStaff = StaffStore((state) => state.SetStaffToHandle);
  const EditDialogSTCH = StaffStore((state) => state.ChaneEditDialog);
  const DeleteDialogSTCH = StaffStore((state) => state.ChaneDeleteDialog);
  const openEditdialog = (param) => {
    EditDialogSTCH();
    SetStaff(param);
  };
  const openDeleteialog = (param) => {
    DeleteDialogSTCH();
    SetStaff(param);
  };
  return (
    <Paper elevation={3} sx={{ border: "1px #999 solid" }}>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ flex: 2 }}>Full Name</TableCell>
              <TableCell sx={{ flex: 2 }}>Username</TableCell>
              <TableCell sx={{ flex: 2 }}>Email</TableCell>
              <TableCell sx={{ flex: 2 }}>Position</TableCell>
              <TableCell sx={{ flex: 1, textAlign: "center" }}>
                Manage
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {StaffList.map((row) => (
              <TableRow key={row.adminid}>
                <TableCell>{row.adminfullname}</TableCell>
                <TableCell>{row.username}</TableCell>
                <TableCell>{row.adminemail}</TableCell>
                <TableCell>{row.adminposition}</TableCell>
                <TableCell sx={{ flex: 1, textAlign: "center" }}>
                  <IconButton
                    size="large"
                    edge="start"
                    color="primary"
                    aria-label="menu"
                    sx={{ m: 0, p: 1 }}
                    title="Edit Admin General Info"
                    onClick={(adid) => openEditdialog(row.adminid)}
                  >
                    <BorderColorIcon />
                  </IconButton>
                  <IconButton
                    size="large"
                    edge="start"
                    color="error"
                    aria-label="menu"
                    sx={{ m: 0, p: 1 }}
                    title="Delete Admin"
                    onClick={(adid) => openDeleteialog(row.adminid)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
