import {
  Paper,
  Grid,
  TextField,
  Typography,
  Box,
  Button,
  MenuItem,
  Divider,
  Select,
  InputLabel,
} from "@mui/material";
import { EmployeesStore } from "../../../../Store/";
import Cookies from "js-cookie";
import { APIURL } from "../../../../Context/configs";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
export default function GeneralInfo() {
  const EmpData = EmployeesStore((state) => state.EmpData);
  const SetEmpData = EmployeesStore((state) => state.SetEmpData);
  const ChaneSnackBarShow = EmployeesStore((state) => state.ChaneSnackBarShow);
  const SetLoadingShow = EmployeesStore((state) => state.SetLoadingShow);
  const ChangeSnackMessage = EmployeesStore(
    (state) => state.ChangeSnackMessage
  );
  const UpdateInfo = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    fetch(APIURL + "manager/employees/employeeinfochange.php", {
      method: "post",
      body: JSON.stringify({
        soc: data.get("ref"),
        fn: data.get("fullname"),
        em: data.get("email"),
        ph: data.get("phone"),
        dob: data.get("dob"),
        fod: data.get("fod"),
        hida: data.get("hiringdate"),
        svn: data.get("svn"),
        ge: data.get("gender"),
        nat: data.get("nationality"),
        el: data.get("emplink"),
        IDEN: Cookies.get("userID"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          ChangeSnackMessage(item.content);
          if (item.id == 1) {
            fetch(APIURL + "manager/employees/employeeinformation.php", {
              method: "post",
              body: JSON.stringify({
                empkey: data.get("eky"),
              }),
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Request-Headers": "Content-Type, Authorization",
                "Access-Control-Allow-Credentials": "true",
              },
            })
              .then((res) => res.json())
              .then((result) => {
                SetEmpData(result);
              });
            setTimeout(() => {
              SetLoadingShow();
              ChaneSnackBarShow();
            }, 3000);
          }
        });
      });
  };
  const UpdateLogin = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    fetch(APIURL + "manager/employees/employeeloginchange.php", {
      method: "post",
      body: JSON.stringify({
        soc: data.get("ref"),
        fn: data.get("fn"),
        un: data.get("username"),
        pw: data.get("password"),
        cpw: data.get("cpassword"),
        ldid: data.get("ldid"),
        IDEN: Cookies.get("userID"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          ChangeSnackMessage(item.content);
          if (item.id == 1) {
            fetch(APIURL + "manager/employees/employeeinformation.php", {
              method: "post",
              body: JSON.stringify({
                empkey: data.get("eky"),
              }),
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Request-Headers": "Content-Type, Authorization",
                "Access-Control-Allow-Credentials": "true",
              },
            })
              .then((res) => res.json())
              .then((result) => {
                SetEmpData(result);
              });
            setTimeout(() => {
              SetLoadingShow();
              ChaneSnackBarShow();
            }, 3000);
          }
        });
      });
  };
  return (
    <>
      <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
        <Typography variant="h6" color={"#2f3193"} sx={{ my: 2 }}>
          Edit General Information
        </Typography>
        {EmpData.map((emp) => (
          <Box
            key={emp.lid}
            component="form"
            onSubmit={UpdateInfo}
            sx={{ mt: 1 }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="d-none">
                  <TextField id="eky" name="eky" defaultValue={emp.empkey} />
                  <TextField id="ref" name="ref" defaultValue={emp.ref} />
                </div>
                <Typography sx={{ mt: 2 }}>
                  <Typography
                    variant="body"
                    color={"#850000"}
                    sx={{ fontWeight: "bold" }}
                  >
                    SOC/REF :{" "}
                  </Typography>
                  {emp.ref}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="fullname"
                  name="fullname"
                  label="Fullname"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.fullname}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.email}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="phone"
                  name="phone"
                  label="Phone"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.phone}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="dob"
                  name="dob"
                  label="Date Of Birth"
                  type="date"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.dob}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="fod"
                  name="fod"
                  type="date"
                  label="First EOD"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.fod}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="hiringdate"
                  name="hiringdate"
                  type="date"
                  label="Hiring Date"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.hiringdate}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="svn"
                  name="svn"
                  label="SVN"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.svn}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <InputLabel id="genderlabel" sx={{ fontSize: "small" }}>
                  Gender
                </InputLabel>
                <Select
                  labelId="genderlabel"
                  id="gender"
                  name="gender"
                  defaultValue={emp.gender}
                  label="Gender"
                  variant="standard"
                  fullWidth
                  size="small"
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="nationality"
                  name="nationality"
                  label="Nationality"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.nationality}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="emplink"
                  name="emplink"
                  label="Link"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.emplink}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: "right" }}
              >
                <Button variant="outlined" color="success" type="submit">
                  <SaveTwoToneIcon sx={{ mr: 2 }} />
                  Save Information
                </Button>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Paper>
      <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
        <Typography variant="h6" color={"#2f3193"} sx={{ my: 2 }}>
          Edit Login Information
        </Typography>
        {EmpData.map((emp) => (
          <Box
            key={emp.lid}
            component="form"
            onSubmit={UpdateLogin}
            sx={{ mt: 1 }}
          >
            <Grid container spacing={3}>
              <div className="d-none">
                <TextField id="fn" name="fn" defaultValue={emp.fullname} />
                <TextField id="ref" name="ref" defaultValue={emp.ref} />
                <TextField id="ldid" name="ldid" defaultValue={emp.lid} />
                <TextField id="eky" name="eky" defaultValue={emp.empkey} />
              </div>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="username"
                  name="username"
                  label="Username"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.username}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  variant="standard"
                  fullWidth
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="cpassword"
                  name="cpassword"
                  type="password"
                  label="Confirm Password"
                  variant="standard"
                  fullWidth
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: "right" }}
              >
                <Button variant="outlined" color="success" type="submit">
                  <SaveTwoToneIcon sx={{ mr: 2 }} />
                  Save Information
                </Button>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Paper>
    </>
  );
}
