import { Box, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { APIURL } from "../../../Context/configs";
import { EmpDataStore, EmployeesStore } from "../../../Store";
import { RingLoader } from "../../Common/Loaders";
import { BC, Loading, ActionMessage } from "../Elements/Common/";
import { ObjectiveEditor, ObjDialog, PerDialog } from "../Elements/Evaluation";

export default function Objective() {
  const EmpData = EmployeesStore((state) => state.EmpData);
  const SetObjective = EmpDataStore((state) => state.SetObjective);
  const SetObjectiveOpt = EmpDataStore((state) => state.SetObjectiveOpt);
  const SetObjectiveApp = EmpDataStore((state) => state.SetObjectiveApp);
  const SetObjectivetkn = EmpDataStore((state) => state.SetObjectivetkn);
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    document.title = "StarsOrbit HRM - Objectives";
    fetch(APIURL + "employee/evaluation/employeeobjectives.php", {
      method: "post",
      body: JSON.stringify({
        soc: EmpData[0].ref,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetObjective(item.objectives);
          SetObjectiveOpt(item.editable);
          SetObjectiveApp(item.approved);
          SetObjectivetkn(item.objtoken);
          setisLoading(false);
        });
      });
  }, []);
  return (
    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
      <BC data="Objectives" />
      {isLoading ? (
        <Box sx={{ py: 5, my: 5, textAlign: "center" }}>
          <RingLoader />
        </Box>
      ) : (
        <>
          <ObjectiveEditor />
          <ObjDialog />
          <PerDialog />
          <ActionMessage />
          <Loading />
        </>
      )}
    </Container>
  );
}
