import { ObjectiveProcessStore } from "../../../Store";
import { Box, Container, Grid } from "@mui/material";
import LOGO from "../../../Assets/IMG/logo.png";
import { Img } from "react-image";
import { CircleLoader, RollerLoader } from "../../Common/Loaders";
import { Routes, Route, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { APIURL } from "../../../Context/configs";
import LoginFooter from "../Elements/LoginPage/LoginFooter";
import {
  ActionMessage,
  Loading,
  PermissionPage,
  UpdatePage,
} from "../Elements/Objective";
export default function Objective() {
  const { obj } = useParams();
  const [isLoading, setisLoading] = useState(true);
  const SetObjective = ObjectiveProcessStore((state) => state.SetObjective);
  useEffect(() => {
    document.title = "StarsOrbit HRM - Objectives";
    fetch(APIURL + "portalhome/objective/getobjective.php", {
      method: "post",
      body: JSON.stringify({
        tkn: obj,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setisLoading(false);
        SetObjective(result);
      });
  }, []);
  return (
    <>
      {isLoading ? (
        <Box className="siteLoader">
          <RollerLoader />
        </Box>
      ) : (
        <Box sx={{ my: 2 }}>
          <Container maxWidth="lg">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Img
                  className="LoginImage"
                  src={LOGO}
                  loader={<CircleLoader />}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Routes>
                  <Route exact path="/" element={<UpdatePage />} />
                  <Route exact path="/update" element={<UpdatePage />} />
                  <Route
                    exact
                    path="/permission"
                    element={<PermissionPage />}
                  />
                </Routes>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
      <LoginFooter />
      <ActionMessage />
      <Loading />
    </>
  );
}
