import { Typography } from "@mui/material";
import { EmployeesStore } from "../../../../Store";
import { RollerLoader } from "../../../Common/Loaders";

export default function Loading() {
  const LoadingShow = EmployeesStore((state) => state.LoadingShow);
  return (
    <>
      {LoadingShow && (
        <div className="submitLoader2">
          <RollerLoader />
          <Typography color={"#ffffff"}>Inserting Data</Typography>
        </div>
      )}
    </>
  );
}
