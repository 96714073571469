import {
  Grid,
  Paper,
  Box,
  TextField,
  MenuItem,
  Alert,
  Button,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { SoftwareCols } from "../../../../Context/DataGrids";
import { useState } from "react";
import { SoftwareStore } from "../../../../Store";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import TableViewIcon from "@mui/icons-material/TableView";
export default function ViewPayroll() {
  const DataToShow = SoftwareStore((state) => state.DataToShow);
  const [ItemsPerPage, SetItemsPerPage] = useState(25);
  const HandleChange = (e) => {
    SetItemsPerPage(e.target.value);
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = (csvData, fileName) => {
    csvData = [
      {
        ref: "SOC/REF",
        fullname: "Full Name",
        cur_month: "Month",
        cur_year: "Year",
        hub: "Hub",
        dutystation: "Duty Station",
        havedanger: "Danger Pay",
        contract: "Type of Contract",
        jposition: "Position",
        divison: "Divison",
        staffgrouping: "Staff Grouping",
        programme: "Programme",
        grade: "Grade",
        basesalary: "Base Salary",
        communication: "Communincation",
        attendance: "Attendance",
        absence: "Absence",
        dpta: "DP/TA",
        retro_others: "Others/Retro",
        soc_fees: "SOC Fees",
        netsalary: "Net Salary",
        totalsalary: "Total Salary",
        generalliab: "General Liability",
        occu_ins: "Occupational Insurance",
        health_ins_iom: "Health Insurance By IOM",
        health_ins_staff: "Health Insurance By Staff",
        social_security_iom: "Social Security By IOM",
        social_security_staff: "Social Security By Staff",
        pit: "Income Tax",
        rnr: "R and R",
        extrafees: "Extra Fees",
        finalsalary: "Final Salary",
        salaryactions: "Actions",
        workinghours: "Working Hours",
        svn: "SVN Hired",
        wbs1: "1WBS For Salary",
        perc1: "Perc 1",
        alloc1: "Allocation 1",
        com1: "Commment WBS 1",
        wbs2: "2WBS For Salary",
        perc2: "Perc 2",
        alloc2: "Allocation 2",
        com2: "Commment WBS 2",
        wbs3: "3WBS For Salary",
        perc3: "Perc 3",
        alloc3: "Allocation 3",
        com3: "Commment WBS 3",
        wbs4: "4WBS For Salary",
        perc4: "Perc 4",
        alloc4: "Allocation 4",
        com4: "Commment WBS 4",
        wbs5: "5WBS For Salary",
        perc5: "Perc 5",
        alloc5: "Allocation 5",
        com5: "Commment WBS 5",
        wbs6: "6WBS For Salary",
        perc6: "Perc 6",
        alloc6: "Allocation 6",
        com6: "Commment WBS 6",
        wbs7: "7WBS For Salary",
        perc7: "Perc 7",
        alloc7: "Allocation 7",
        com7: "Commment WBS 7",
        wbs8: "8WBS For Salary",
        perc8: "Perc 8",
        alloc8: "Allocation 8",
        com8: "Commment WBS 8",
        fod: "First EOD",
        hiringdate: "SOC EOD",
        startdate: "Contract Start",
        enddate: "Contract Expiry",
        upgradeable: "Elibible For Upgrade",
        emplink: "Link",
        gender: "Gender",
        dob: "Date Of Birth",
        age: "Age",
        maritalstatus: "Marital Status",
        nationality: "Nationality",
        email: "Email",
        phone: "Phone Number",
        bankname: "Bank Name",
        accnt1: "Bank Accnt #1",
        accnt2: "Bank Accnt #2",
        accnt3: "Bank Accnt #3",
        remarks: "Remarks",
      },
      ...csvData,
    ];
    const ws = XLSX.utils.json_to_sheet(csvData, { skipHeader: true });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <Paper elevation={3} sx={{ mt: 3, p: 2 }}>
      {DataToShow === null ? (
        <Box sx={{ mt: 2 }}>
          <Alert
            severity="info"
            variant="outlined"
            sx={{
              "& .MuiAlert-message": {
                textAlign: "center",
                width: "inherit",
              },
            }}
          >
            No Data To Preview
          </Alert>
        </Box>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <TextField
              select
              size="small"
              fullWidth
              label="Rows Per Page"
              defaultValue={ItemsPerPage}
              onChange={HandleChange}
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={200}>200</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} sx={{ textAlign: "end" }}>
            <Button
              color="primary"
              variant="outlined"
              size="small"
              onClick={(e) =>
                exportToCSV(
                  DataToShow,
                  "Payroll Data For " +
                    DataToShow[0].cur_month +
                    "-" +
                    DataToShow[0].cur_year
                )
              }
            >
              <TableViewIcon sx={{ mx: 1 }} />
              Export As Excel
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box sx={{ width: "100%" }}>
              <DataGrid
                rows={DataToShow}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                  },
                }}
                columns={SoftwareCols}
                pageSize={ItemsPerPage}
                rowsPerPageOptions={[ItemsPerPage]}
                disableSelectionOnClick
                autoHeight
                width={"100%"}
                disableColumnMenu
                density="compact"
                getRowId={(row) => row.ref}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}
