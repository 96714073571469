import {
  Grid,
  Typography,
  Box,
  Button,
  Paper,
  TextField,
  MenuItem,
} from "@mui/material";
import Cookies from "js-cookie";
import { APIURL, FilterMonths } from "../../../../Context/configs";
import { SystemConfigStore } from "../../../../Store";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useEffect, useState } from "react";
import { RingLoader } from "../../../Common/Loaders";

export default function AccountsManager() {
  const [isLoading, setisLoading] = useState(true);
  const SetYear = new Date().getFullYear();
  const SetMonth = new Date().toLocaleString("en-US", { month: "short" });
  const [YearsLister] = useState([]);
  const ChaneSnackBarShow = SystemConfigStore(
    (state) => state.ChaneSnackBarShow
  );
  const ChangeSnackMessage = SystemConfigStore(
    (state) => state.ChangeSnackMessage
  );
  const SetLoadingShow = SystemConfigStore((state) => state.SetLoadingShow);
  useEffect(() => {
    YearsLister.splice(0, YearsLister.length);
    for (let i = parseInt(new Date().getFullYear()); i > 2018; i--) {
      YearsLister.push({ y: i });
    }
    setTimeout(() => {
      setisLoading(false);
    }, 3000);
  }, []);

  const ManageAccounts = (event) => {
    event.preventDefault();
    SetLoadingShow();
    const data = new FormData(event.currentTarget);
    fetch(APIURL + "manager/portaldata/bulkupdater.php", {
      method: "post",
      body: JSON.stringify({
        m: data.get("m"),
        y: data.get("y"),
        IDEN: Cookies.get("userID"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          ChangeSnackMessage(item.content);
          SetLoadingShow();
          ChaneSnackBarShow();
        });
      });
  };
  return (
    <>
      <Paper elevation={3} sx={{ mt: 2, p: 3 }}>
        <Typography variant="h5" color={"#2f3193"} sx={{ my: 2 }}>
          Terminate And Rehire Bulk By Payroll
        </Typography>
        {isLoading ? (
          <Box sx={{ width: "100%", textAlign: "center", p: 3 }}>
            <RingLoader />
          </Box>
        ) : (
          <Box
            component="form"
            onSubmit={ManageAccounts}
            noValidate
            sx={{ p: 2 }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  label="Month"
                  defaultValue={SetMonth}
                  name="m"
                >
                  {FilterMonths.map((FM) => (
                    <MenuItem key={FM.value} value={FM.value}>
                      {FM.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  label="Year"
                  name="y"
                  defaultValue={SetYear}
                >
                  {YearsLister.map((y) => (
                    <MenuItem key={y.y} value={y.y}>
                      {y.y}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>{" "}
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <Button
                  fullWidth
                  color="success"
                  variant="contained"
                  type="submit"
                >
                  <CheckCircleIcon fontSize="small" sx={{ mr: 2 }} />
                  Do Action
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Paper>
    </>
  );
}
