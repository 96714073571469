import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Grid,
  Button,
  Alert,
  TextField,
} from "@mui/material";
import { PayrollStore } from "../../../../Store";
import { APIURL } from "../../../../Context/configs";
import Cookies from "js-cookie";
export default function DeleteDialog() {
  const DataToShow = PayrollStore((state) => state.DataToShow);
  const InfoToHandleM = PayrollStore((state) => state.InfoToHandleM);
  const InfoToHandleY = PayrollStore((state) => state.InfoToHandleY);
  const ChaneDeleteDialog = PayrollStore((state) => state.ChaneDeleteDialog);
  const ShowDeleteDialog = PayrollStore((state) => state.ShowDeleteDialog);
  const ChaneSnackBarShow = PayrollStore((state) => state.ChaneSnackBarShow);
  const ChangeSnackMessage = PayrollStore((state) => state.ChangeSnackMessage);
  const SetDataToShow = PayrollStore((state) => state.SetDataToShow);
  const CloseDialog = () => {
    ChaneDeleteDialog();
  };
  const DeletePayroll = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    fetch(APIURL + "manager/payroll/payrolldelete.php", {
      method: "post",
      body: JSON.stringify({
        m: InfoToHandleM,
        y: InfoToHandleY,
        r: data.get("re"),
        IDEN: Cookies.get("userID"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          ChangeSnackMessage(item.content);
          if (item.id == 1) {
            ChaneSnackBarShow();
            ChaneDeleteDialog();
            fetch(APIURL + "manager/payroll/payrollshowall.php", {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Request-Headers": "Content-Type, Authorization",
                "Access-Control-Allow-Credentials": "true",
              },
            })
              .then((res) => res.json())
              .then((result) => {
                SetDataToShow(result);
              });
          }
        });
      });
  };
  return (
    <Dialog open={ShowDeleteDialog} onClose={CloseDialog} fullWidth>
      <DialogTitle color={"#2f3193"}>
        Delete Payroll For {InfoToHandleM}-{InfoToHandleY}
      </DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={DeletePayroll} sx={{ mt: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Alert severity="warning" variant="outlined">
                Are You Sure You Want To Delete Payroll For {InfoToHandleM}-
                {InfoToHandleY}
              </Alert>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                id="re"
                name="re"
                label="Reason For Delete"
                size="small"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ display: "none" }}></Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ textAlign: "right" }}>
              <Button
                type="submit"
                variant="contained"
                color="success"
                sx={{ mt: 3, mb: 2 }}
              >
                Delete Payroll
              </Button>
              <Button
                type="button"
                variant="contained"
                color="error"
                sx={{ mt: 3, mb: 2, ml: 2 }}
                onClick={CloseDialog}
              >
                Close Form
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
