import {
  Paper,
  Box,
  TextField,
  Typography,
  Grid,
  Button,
  Divider,
} from "@mui/material";
import { EmployeesStore, EmpDataStore } from "../../../../Store";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import { APIURL } from "../../../../Context/configs";
export default function EditSVFORM() {
  const EmpData = EmployeesStore((state) => state.EmpData);
  const SetEmpData = EmployeesStore((state) => state.SetEmpData);
  const SetLoadingShow = EmpDataStore((state) => state.SetLoadingShow);
  const SetSnackMessage = EmpDataStore((state) => state.SetSnackMessage);
  const SetSnackBarType = EmpDataStore((state) => state.SetSnackBarType);
  const SetSnackBarShow = EmpDataStore((state) => state.SetSnackBarShow);
  const UpdateSV = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    fetch(APIURL + "employee/general/updatesupervisors.php", {
      method: "post",
      body: JSON.stringify({
        soc: data.get("soc"),
        sv: data.get("svn"),
        sve: data.get("sve"),
        hos: data.get("hoson"),
        hose: data.get("hosoe"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetSnackMessage(item.content);
          if (item.id === 1) {
            SetSnackBarType("success");
            setTimeout(() => {
              fetch(APIURL + "employee/general/listinformation.php", {
                method: "post",
                body: JSON.stringify({
                  soc: data.get("soc"),
                }),
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Request-Headers":
                    "Content-Type, Authorization",
                  "Access-Control-Allow-Credentials": "true",
                },
              })
                .then((res) => res.json())
                .then((result) => {
                  SetEmpData(result);
                });
              SetLoadingShow();
              SetSnackBarShow();
            }, 2000);
          } else {
            SetSnackBarType("error");
            setTimeout(() => {
              SetLoadingShow();
              SetSnackBarShow();
            }, 2000);
          }
        });
      });
  };
  return (
    <Paper elevation={3} sx={{ mt: 2, p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6" sx={{ color: "#2f3193" }}>
            Edit Supervisors
          </Typography>
          <Divider sx={{ mt: 1 }} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {EmpData.map((e) => (
            <Box
              key={e.ref}
              component="form"
              onSubmit={UpdateSV}
              sx={{ mt: 1 }}
            >
              <Grid container spacing={3}>
                <div className="d-none">
                  <TextField
                    id="soc"
                    name="soc"
                    label="soc"
                    variant="standard"
                    defaultValue={e.ref}
                  />
                </div>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="svn"
                    name="svn"
                    label="Supervisor Name"
                    variant="standard"
                    fullWidth
                    required
                    defaultValue={e.supervisor}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="sve"
                    name="sve"
                    label="Supervisor Email"
                    variant="standard"
                    fullWidth
                    required
                    defaultValue={e.svemail}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="hoson"
                    name="hoson"
                    label="Second Level Supervisor Name"
                    variant="standard"
                    fullWidth
                    required
                    defaultValue={e.hos}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="hosoe"
                    name="hosoe"
                    label="Second Level Supervisor Email"
                    variant="standard"
                    fullWidth
                    required
                    defaultValue={e.hosemal}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ textAlign: "right" }}
                >
                  <Divider sx={{ mb: 1 }} />
                  <Button
                    variant="outlined"
                    size="small"
                    color="warning"
                    type="submit"
                  >
                    <SaveTwoToneIcon fontSize="small" sx={{ mr: 1 }} /> Update
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
}
