import { Box, Chip } from "@mui/material";
import { Fragment } from "react";
import { EmployeesStore } from "../../../../Store/";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
export default function EmpStatus() {
  const EmpData = EmployeesStore((state) => state.EmpData);
  return (
    <Box
      sx={{
        mt: 5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {EmpData.map((emp) => (
        <Fragment key={emp.lid}>
          {emp.status === 1 ? (
            <Chip
              color="success"
              label="Active"
              icon={<CheckCircleTwoToneIcon />}
            />
          ) : (
            <Chip
              color="error"
              label="Terminated"
              icon={<CancelTwoToneIcon />}
            />
          )}
        </Fragment>
      ))}
    </Box>
  );
}
