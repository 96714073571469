import {
  Paper,
  Box,
  TextField,
  Typography,
  Grid,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { useState } from "react";
import ReactQuill from "react-quill";
import { EmployeesStore } from "../../../../Store";
import CampaignSharpIcon from "@mui/icons-material/CampaignSharp";
import { APIURL } from "../../../../Context/configs";
import { RollerLoader } from "../../../Common/Loaders";

export default function ComplainForm() {
  const EmpData = EmployeesStore((state) => state.EmpData);
  const [convertedText, setConvertedText] = useState("");
  const [ShowToast, setShowToast] = useState(false);
  const [submitted, setsubmitted] = useState(false);
  const [ToastMessage, setToastMessage] = useState("");
  const [MessageAction, setMessageAction] = useState("success");
  const SendReport = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setsubmitted(true);
    fetch(APIURL + "employee/actions/complain.php", {
      method: "post",
      body: JSON.stringify({
        em: data.get("email"),
        fn: data.get("fullname"),
        sub: data.get("subject"),
        mes: convertedText,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          setToastMessage(item.content);
          if (item.id === 1) {
            setMessageAction("success");
          } else {
            setMessageAction("warning");
          }
        });
      });
    setTimeout(() => {
      setsubmitted(false);
      setShowToast(true);
      event.target.reset();
      setConvertedText("");
    }, 3000);
  };
  const handleClose = () => {
    setShowToast(false);
  };
  return (
    <>
      {submitted && (
        <div className="submitLoader">
          <RollerLoader />
        </div>
      )}
      <Paper elevation={3} sx={{ mt: 2, p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h6" sx={{ color: "#2f3193" }}>
              Issue Complain
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {EmpData.map((e) => (
              <Box
                key={e.ref}
                component="form"
                onSubmit={SendReport}
                sx={{ mt: 1 }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="fullname"
                      name="fullname"
                      label="Fullname"
                      variant="standard"
                      fullWidth
                      required
                      defaultValue={e.fullname}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="email"
                      name="email"
                      label="Email Address"
                      variant="standard"
                      fullWidth
                      required
                      defaultValue={e.email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="subject"
                      name="subject"
                      label="Subject"
                      variant="standard"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      Your Message
                    </Typography>
                    <ReactQuill
                      theme="snow"
                      value={convertedText}
                      onChange={setConvertedText}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ textAlign: "right" }}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      color="error"
                      type="submit"
                    >
                      <CampaignSharpIcon fontSize="small" sx={{ mr: 1 }} /> Send
                      Complain
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: "botton", horizontal: "center" }}
        autoHideDuration={3000}
        open={ShowToast}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          severity={MessageAction}
          sx={{ width: "100%" }}
        >
          {ToastMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
