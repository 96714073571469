import {
  Container,
  Grid,
  Breadcrumbs,
  Typography,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
} from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { APIURL } from "../../../Context/configs";
import SettingsIcon from "@mui/icons-material/Settings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ConstructionIcon from "@mui/icons-material/Construction";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import SchoolIcon from "@mui/icons-material/School";
import StorageIcon from '@mui/icons-material/Storage';
import {
  AccountsManager,
  ImportPayroll,
  SystemSetting,
  UnderMaintanance,
  MandatoryTraining,
  CacheData,
  SystemLogs,
  ActionMessage,
  Loading,
} from "../Elements/SystemConfig/";
import { RingLoader } from "../../Common/Loaders";
import { SystemConfigStore } from "../../../Store";
export default function SystemConfig() {
  const [isLoading, setisLoading] = useState(true);
  const [NavOption, setNavOption] = useState(0);
  const SetSysMainShow = SystemConfigStore((state) => state.SetSysMainShow);
  const SetSysConfShow = SystemConfigStore((state) => state.SetSysConfShow);
  const SetLogsShow = SystemConfigStore((state) => state.SetLogsShow);
  const SetTrainingList = SystemConfigStore((state) => state.SetTrainingList);
  useEffect(() => {
    document.title = "StarsOrbit HRM - System Configuraion";
    fetch(APIURL + "manager/portaldata/systemconfig.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetSysConfShow(result);
      });
    fetch(APIURL + "manager/portaldata/undermaintanance.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetSysMainShow(result);
      });
    fetch(APIURL + "manager/portaldata/mandatorytraining.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetTrainingList(result);
      });
    fetch(APIURL + "manager/portaldata/logsshow.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetLogsShow(result);
        setisLoading(false);
      });
  }, []);
  return (
    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink className="breabcrumblink" to="/admin">
              Admin Panel
            </NavLink>
            <Typography color="text.primary">System Configuraion</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <BottomNavigation
              showLabels
              value={NavOption}
              onChange={(event, newValue) => {
                setNavOption(newValue);
              }}
            >
              <BottomNavigationAction
                label="System Setting"
                icon={<SettingsIcon />}
              />
              <BottomNavigationAction
                label="Manage Accounts"
                icon={<ManageAccountsIcon />}
              />
              <BottomNavigationAction
                label="Import Payroll Sheet"
                icon={<ListAltIcon />}
              />
              <BottomNavigationAction
                label="System Logs"
                icon={<FormatListNumberedIcon />}
              />
              <BottomNavigationAction
                label="Mandatory Training"
                icon={<SchoolIcon />}
              />
              <BottomNavigationAction
                label="Under Maintenance"
                icon={<ConstructionIcon />}
              />
              <BottomNavigationAction
                label="Cache Data"
                icon={<StorageIcon />}
              />
            </BottomNavigation>
          </Paper>
        </Grid>
      </Grid>
      {isLoading ? (
        <Paper elevation={3} className="siteLoader" sx={{ mb: 3, mt: 3 }}>
          <RingLoader />
        </Paper>
      ) : (
        <>
          {NavOption === 0 && <SystemSetting />}
          {NavOption === 1 && <AccountsManager />}
          {NavOption === 2 && <ImportPayroll />}
          {NavOption === 3 && <SystemLogs />}
          {NavOption === 4 && <MandatoryTraining />}
          {NavOption === 5 && <UnderMaintanance />}
          {NavOption === 6 && <CacheData />}
        </>
      )}
      <Loading />
      <ActionMessage />
    </Container>
  );
}
