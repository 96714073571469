import { Box, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { APIURL } from "../../../Context/configs";
import { RingLoader } from "../../Common/Loaders";
import { BC } from "../Elements/Common/";
import ShowHistory from "../Elements/JobHistory/ShowHistory";
import { EmpDataStore, EmployeesStore } from "../../../Store";

export default function JobHistory() {
  const [isLoading, setisLoading] = useState(true);
  const EmpData = EmployeesStore((state) => state.EmpData);
  const SetHistoryList = EmpDataStore((state) => state.SetHistoryList);
  useEffect(() => {
    document.title = "StarsOrbit HRM - Job History";
    fetch(APIURL + "employee/history/history.php", {
      method: "post",
      body: JSON.stringify({
        soc: EmpData[0].ref,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetHistoryList(result);
        setTimeout(() => {
          setisLoading(false);
        }, 2000);
      });
  }, []);
  return (
    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
      <BC data="Job History" />
      {isLoading ? (
        <Box sx={{ py: 5, my: 5, textAlign: "center" }}>
          <RingLoader />
        </Box>
      ) : (
        <ShowHistory />
      )}
    </Container>
  );
}
