import {
  Paper,
  TextField,
  MenuItem,
  Typography,
  Grid,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { OjectivesReqCol } from "../../../../Context/DataGrids";
import { EvaluationStore } from "../../../../Store";
import { RingLoader } from "../../../Common/Loaders";
import { APIURL } from "../../../../Context/configs";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
export default function RequestedObjectives() {
  const [ItemsPerPage, SetItemsPerPage] = useState(10);
  const [isLoading, SetisLoading] = useState(true);
  const [PRLoad, SetPRLoad] = useState(false);
  const SetReqOBJ = EvaluationStore((state) => state.SetReqOBJ);
  const DataToView = EvaluationStore((state) => state.ReqOBJ);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  useEffect(() => {
    fetch(APIURL + "organization/evaluation/objectivesrequested.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetReqOBJ(result);
        SetisLoading(false);
      });
  }, []);
  const exportToCSV = (csvData, fileName) => {
    SetPRLoad(true);
    csvData = [
      {
        ref: "SOC/REF",
        fullname: "Full Name",
        ReqDate: "Request Date",
        objectives: "Objectives",
      },
      ...csvData,
    ];
    setTimeout(() => {
      SetPRLoad(false);
      const ws = XLSX.utils.json_to_sheet(csvData, { skipHeader: true });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }, 2000);
  };
  const HandleChange = (e) => {
    SetItemsPerPage(e.target.value);
  };
  return (
    <Paper elevation={3} sx={{ mt: 3, p: 2 }}>
      {isLoading ? (
        <Box className="siteLoader">
          <RingLoader />
        </Box>
      ) : (
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h6" className="text-soc">
              View Requested Objectives
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            {DataToView === null ? null : (
              <TextField
                select
                size="small"
                fullWidth
                label="Rows Per Page"
                defaultValue={ItemsPerPage}
                onChange={HandleChange}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </TextField>
            )}
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} sx={{ textAlign: "right" }}>
            {DataToView === null ? null : (
              <Button
                variant="contained"
                color="success"
                size="small"
                title="Export Payroll To Excel"
                onClick={() =>
                  exportToCSV(DataToView, "Requested Objectives List")
                }
              >
                {PRLoad ? (
                  <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                ) : (
                  <InsertDriveFileIcon sx={{ mr: 1 }} />
                )}
                Export To Excel
              </Button>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 3 }}>
            <Box sx={{ width: "100%" }}>
              {DataToView === null ? (
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  No Data To Preview
                </Typography>
              ) : (
                <DataGrid
                  rows={DataToView}
                  getRowId={(row) => row.ref}
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 250 },
                    },
                  }}
                  columns={OjectivesReqCol}
                  pageSize={ItemsPerPage}
                  rowsPerPageOptions={[ItemsPerPage]}
                  disableSelectionOnClick
                  autoHeight
                  disableColumnMenu
                  density="compact"
                />
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}
