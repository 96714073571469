import { Paper, Grid } from "@mui/material";
import { EmpDataStore } from "../../../../Store";
import { ListerItem, ListerEmpty } from "./ListerElements";

export default function FileLister() {
  const FileList = EmpDataStore((state) => state.FileList);
  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      {FileList === null ? (
        <ListerEmpty />
      ) : (
        <Grid container spacing={2}>
          {FileList.map((f) => (
            <Grid key={f.file_id} item xs={12} sm={12} md={6} lg={3}>
              <ListerItem data={f} />
            </Grid>
          ))}
        </Grid>
      )}
    </Paper>
  );
}
