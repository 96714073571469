import { Box, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { RingLoader } from "../../Common/Loaders";
import { FRHead, FRForm } from "../Elements/FirstRunData";
import BC from "../Elements/Common/BC";
export default function InsertEmailAndPhone() {
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    document.title = "StarsOrbit HRM - First Run Contact Details";
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  }, []);
  return (
    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
      <BC data="Set First Run Contact Details" />
      {isLoading ? (
        <Box sx={{ py: 5, my: 5, textAlign: "center" }}>
          <RingLoader />
        </Box>
      ) : (
        <>
          <FRHead />
          <FRForm />
        </>
      )}
    </Container>
  );
}
