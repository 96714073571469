import {
  Paper,
  Box,
  TextField,
  Divider,
  Button,
  Typography,
} from "@mui/material";
import { EmpDataStore, EmployeesStore } from "../../../../Store";
import { APIURL } from "../../../../Context/configs";
export default function AccountInfo() {
  const EmpData = EmployeesStore((state) => state.EmpData);
  const SetEmpData = EmployeesStore((state) => state.SetEmpData);
  const SetSnackMessage = EmpDataStore((state) => state.SetSnackMessage);
  const SetSnackBarType = EmpDataStore((state) => state.SetSnackBarType);
  const SetLoadingShow = EmpDataStore((state) => state.SetLoadingShow);
  const SetSnackBarShow = EmpDataStore((state) => state.SetSnackBarShow);
  const sendRequest = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    fetch(APIURL + "employee/general/updateaccount.php", {
      method: "post",
      body: JSON.stringify({
        soc: EmpData[0].ref,
        un: data.get("username"),
        pw: data.get("opw"),
        npw: data.get("npw"),
        cpw: data.get("cpw"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetSnackMessage(item.content);
          if (item.id === 1) {
            SetSnackBarType("success");
            setTimeout(() => {
              SetSnackBarShow();
              fetch(APIURL + "employee/general/listinformation.php", {
                method: "post",
                body: JSON.stringify({
                  soc: EmpData[0].ref,
                }),
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Request-Headers":
                    "Content-Type, Authorization",
                  "Access-Control-Allow-Credentials": "true",
                },
              })
                .then((res) => res.json())
                .then((result) => {
                  SetEmpData(result);
                });
              SetLoadingShow();
            }, 2000);
          } else {
            SetSnackBarType("error");
            setTimeout(() => {
              SetSnackBarShow();
              SetLoadingShow();
            }, 2000);
          }
        });
      });
  };
  return (
    <Paper elevation={3} sx={{ p: 2, my: 3 }}>
      <Box sx={{ my: 2 }}>
        <Typography variant="body" className="bolder">
          Account Information
        </Typography>
      </Box>
      <Divider />
      <Box component="form" onSubmit={sendRequest} sx={{ my: 3 }}>
        <Box>
          <TextField
            id="username"
            name="username"
            label="Username"
            variant="standard"
            fullWidth
            size="small"
            defaultValue={EmpData[0].username}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <TextField
            id="opw"
            name="opw"
            label="Old Password"
            variant="standard"
            fullWidth
            type="password"
            size="small"
            defaultValue=""
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <TextField
            id="npw"
            name="npw"
            label="New Password"
            variant="standard"
            fullWidth
            size="small"
            type="password"
            defaultValue=""
          />
        </Box>
        <Box sx={{ my: 2 }}>
          <TextField
            id="cpw"
            name="cpw"
            label="Confirm Password"
            variant="standard"
            fullWidth
            type="password"
            size="small"
            defaultValue=""
          />
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          <Button
            type="submit"
            size="small"
            fullWidth
            variant="contained"
            color="success"
          >
            Save Information
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}
