import AUDIOFILE from "../../../../../Assets/IMG/audio.png";
import WORDFILE from "../../../../../Assets/IMG/document.png";
import IMAGEFILE from "../../../../../Assets/IMG/image.png";
import PDFICON from "../../../../../Assets/IMG/pdf.png";
import PPTICON from "../../../../../Assets/IMG/presentation.png";
import XLSICON from "../../../../../Assets/IMG/spreadsheet.png";
import TEXTICON from "../../../../../Assets/IMG/text.png";
import VIDEOICON from "../../../../../Assets/IMG/video.png";
import COMPICON from "../../../../../Assets/IMG/zip.png";
import PageviewRoundedIcon from "@mui/icons-material/PageviewRounded";
import FolderDeleteRoundedIcon from "@mui/icons-material/FolderDeleteRounded";
import { IconButton } from "@mui/material";
import { EmpDataStore } from "../../../../../Store";

export default function ListerItem(props) {
  const SetCommentDialog = EmpDataStore((state) => state.SetCommentDialog);
  const SetDeleteDialog = EmpDataStore((state) => state.SetDeleteDialog);
  const SetFileTODO = EmpDataStore((state) => state.SetFileTODO);
  const openDeleteDia = (p) => {
    SetFileTODO(p);
    SetDeleteDialog();
  };
  const openViewDia = (p) => {
    SetFileTODO(p);
    SetCommentDialog();
  };
  const ShowICON = (param) => {
    if (param === "image") {
      return <img src={IMAGEFILE} className="fileItem" alt="" />;
    } else if (param === "document") {
      return <img src={WORDFILE} className="fileItem" alt="" />;
    } else if (param === "spreadsheet") {
      return <img src={XLSICON} className="fileItem" alt="" />;
    } else if (param === "presentation") {
      return <img src={PPTICON} className="fileItem" alt="" />;
    } else if (param === "pdf") {
      return <img src={PDFICON} className="fileItem" alt="" />;
    } else if (param === "text") {
      return <img src={TEXTICON} className="fileItem" alt="" />;
    } else if (param === "zip") {
      return <img src={COMPICON} className="fileItem" alt="" />;
    } else if (param === "video") {
      return <img src={VIDEOICON} className="fileItem" alt="" />;
    } else if (param === "audio") {
      return <img src={AUDIOFILE} className="fileItem" alt="" />;
    }
  };
  return (
    <div className="file">
      <div className="fileitem">{ShowICON(props.data.fileicon)}</div>
      <div className="filedata">
        <div className="filename">{props.data.filename}</div>
        <div className="fileInfo">{props.data.filetype}</div>
        <div className="fileActions">
          <IconButton
            color="success"
            size="small"
            title="Show File"
            onClick={() => openViewDia(props.data.file_id)}
          >
            <PageviewRoundedIcon fontSize="small" />
          </IconButton>
          <IconButton
            color="error"
            size="small"
            title="Delete File"
            onClick={() => openDeleteDia(props.data.file_id)}
          >
            <FolderDeleteRoundedIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
    </div>
  );
}
