import { Grid, Typography, Box, Button, Paper, TextField } from "@mui/material";
import Cookies from "js-cookie";
import { APIURL, AlternativeURI } from "../../../../Context/configs";
import { SystemConfigStore } from "../../../../Store";
import CheckIcon from "@mui/icons-material/Check";
import BuildIcon from "@mui/icons-material/Build";
export default function UnderMaintanance() {
  const SysMainShow = SystemConfigStore((state) => state.SysMainShow);
  const SetSysMainShow = SystemConfigStore((state) => state.SetSysMainShow);
  const ChaneSnackBarShow = SystemConfigStore(
    (state) => state.ChaneSnackBarShow
  );
  const ChangeSnackMessage = SystemConfigStore(
    (state) => state.ChangeSnackMessage
  );
  const UpdateSetting = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    fetch(APIURL + "manager/portaldata/updateundermain.php", {
      method: "post",
      body: JSON.stringify({
        stattomake: data.get("stattomake"),
        IDEN: Cookies.get("userID"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          ChangeSnackMessage(item.content);
          if (item.id == 1) {
            ChaneSnackBarShow();
            fetch(APIURL + "manager/portaldata/undermaintanance.php", {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Request-Headers": "Content-Type, Authorization",
                "Access-Control-Allow-Credentials": "true",
              },
            })
              .then((res) => res.json())
              .then((result) => {
                SetSysMainShow(result);
              });
          }
        });
      });
  };
  return (
    <Paper elevation={3} sx={{ mt: 2, p: 3 }}>
      <Typography variant="h5" color={"#2f3193"} sx={{ my: 2 }}>
        System Maintainance
      </Typography>
      {SysMainShow.map((c) => (
        <Box
          component="form"
          onSubmit={UpdateSetting}
          noValidate
          sx={{ p: 2 }}
          key={c.configid}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="d-none">
                {c.configvalue === 1 ? (
                  <TextField
                    type="hidden"
                    id="stattomake"
                    name="stattomake"
                    value={0}
                  />
                ) : (
                  <TextField
                    type="hidden"
                    id="stattomake"
                    name="stattomake"
                    value={1}
                  />
                )}
              </div>
              {c.configvalue === 1 ? (
                <Button
                  size="small"
                  fullWidth
                  color="success"
                  variant="contained"
                  type="submit"
                >
                  <CheckIcon fontSize="small" sx={{ mr: 2 }} />
                  Set To Working
                </Button>
              ) : (
                <Button
                  size="small"
                  fullWidth
                  color="error"
                  variant="contained"
                  type="submit"
                >
                  <BuildIcon fontSize="small" sx={{ mr: 2 }} />
                  Set To Under Maintainance
                </Button>
              )}
            </Grid>
            {c.configvalue === 1 && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: "center" }}
              >
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Alternative Admin Link
                </Typography>
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  defaultValue={AlternativeURI + "maintainance"}
                  disabled
                />
              </Grid>
            )}
          </Grid>
        </Box>
      ))}
    </Paper>
  );
}
