import {
  Paper,
  Grid,
  Typography,
  Box,
  TextField,
  MenuItem,
} from "@mui/material";
import { EmployeesStore } from "../../../../Store/";
import { APIURL } from "../../../../Context/configs";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { EmpPayrollCol } from "../../../../Context/DataGrids";
export default function Payroll() {
  const [PL, SetPL] = useState([]);
  const EmpData = EmployeesStore((state) => state.EmpData);
  const [ItemsPerPage, SetItemsPerPage] = useState(10);
  useEffect(() => {
    fetch(APIURL + "organization/employees/employeepayroll.php", {
      method: "post",
      body: JSON.stringify({
        soc: EmpData[0].ref,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetPL(result);
      });
  }, []);
  const HandleChange = (e) => {
    SetItemsPerPage(e.target.value);
  };
  return (
    <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" color={"#2f3193"} sx={{ my: 2 }}>
        Payroll Hisory
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: "right" }}>
          <TextField
            select
            size="small"
            label="Rows Per Page"
            defaultValue={ItemsPerPage}
            onChange={HandleChange}
            sx={{ width: "150px" }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {PL != null ? (
            <Box sx={{ width: "100%" }}>
              <DataGrid
                rows={PL}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                  },
                }}
                columns={EmpPayrollCol}
                pageSize={ItemsPerPage}
                rowsPerPageOptions={[ItemsPerPage]}
                disableSelectionOnClick
                autoHeight
                width={"100%"}
                disableColumnMenu
                density="compact"
              />
            </Box>
          ) : (
            <Box sx={{ p: 5, textAlign: "center" }}>
              <Typography variant="h6">No Data To Preview</Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
