import { create } from "zustand";
const PayrollStore = create((set) => ({
  DataToShow: [],
  ShowDeleteDialog: false,
  InfoToHandleM: 0,
  InfoToHandleY: 0,
  SnackBarShow: false,
  SnackBarMessage: "",
  SetDataToShow: (data) => set((state) => ({ DataToShow: data })),
  EditInfoToHandleM: (inf) => set((state) => ({ InfoToHandleM: inf })),
  EditInfoToHandleY: (inf) => set((state) => ({ InfoToHandleY: inf })),
  ChaneDeleteDialog: () =>
    set((state) => ({ ShowDeleteDialog: !state.ShowDeleteDialog })),
  ChaneSnackBarShow: () =>
    set((state) => ({ SnackBarShow: !state.SnackBarShow })),
  ChangeSnackMessage: (mes) => set((state) => ({ SnackBarMessage: mes })),
}));

export default PayrollStore;
