import { Container, Grid, Breadcrumbs, Typography, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { APIURL } from "../../../Context/configs";
import { dashBoardStore } from "../../../Store";
import {
  DashWidgets,
  DashCharts,
  Last10Evals,
  Last10Leaves,
} from "../Elements/DashBoard";
import { RingLoader } from "../../Common/Loaders";
export default function DashBoard() {
  const [isLoading, setisLoading] = useState(true);
  const WidgetsLoader = dashBoardStore((state) => state.setwidgets);
  const l10LeavLoader = dashBoardStore((state) => state.setl10Leav);
  const l10EvalLoader = dashBoardStore((state) => state.setl10Eval);
  useEffect(() => {
    document.title = "StarsOrbit HRM - Dashboard";
    fetch(APIURL + "organization/dashboard/dashlast10leave.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result == null || result == "") {
        } else {
          l10LeavLoader(result);
        }
      });
    fetch(APIURL + "organization/dashboard/dashlast10eval.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result == null || result == "") {
        } else {
          l10EvalLoader(result);
        }
      });
    fetch(APIURL + "organization/dashboard/dashwidgets.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setisLoading(false);
        WidgetsLoader(result);
      });
  }, []);
  return (
    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink className="breabcrumblink" to="/iom">
              IOM HR Panel
            </NavLink>
            <Typography color="text.primary">Dashboard</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Typography variant="h5" color={"#2f3193"} sx={{ mb: 3, mt: 3 }}>
        Statistics
      </Typography>
      {isLoading ? (
        <Paper elevation={3} className="siteLoader">
          <RingLoader />
        </Paper>
      ) : (
        <DashWidgets />
      )}
      <DashCharts />
      <Grid container spacing={1} sx={{ my: 3 }}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ p: 1, border: "1px #bbb solid" }}>
            <Typography variant="h5" color={"#2f3193"} sx={{ my: 1 }}>
              Last 10 Evaluation Requests
            </Typography>
            {isLoading ? (
              <Paper elevation={3} className="siteLoader">
                <RingLoader />
              </Paper>
            ) : (
              <Last10Evals />
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ p: 1, border: "1px #bbb solid" }}>
            <Typography variant="h5" color={"#2f3193"} sx={{ my: 1 }}>
              Last 10 Leave Requests
            </Typography>
            <Last10Leaves />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
