import { create } from "zustand";
const EmpDataStore = create((set) => ({
  EvalList: [],
  Objective: "",
  ObjectiveOpt: 0,
  ObjectiveApp: 0,
  Objectivetkn: "",
  ObjectiveDialog: false,
  RequestDialog: false,
  SecondDialog: false,
  SkipDialog: false,
  CommentDialog: false,
  DeleteDialog: false,
  EvalToDo: "",
  SnackBarShow: false,
  SnackBarType: "success",
  SnackBarMessage: "",
  LoadingShow: false,
  LeaveList: [],
  LeaveBalances: [],
  LeaveTaken: [],
  LeaveToDo: "",
  CancelDialog: false,
  HistoryList: [],
  PayrollList: [],
  PPDialog: false,
  COMPLOAD: true,
  FileList: [],
  FileTODO: "",
  SetFileList: (data) => set((state) => ({ FileList: data })),
  SetFileTODO: (evid) => set((state) => ({ FileTODO: evid })),
  SetPPDialog: () => set((state) => ({ PPDialog: !state.PPDialog })),
  SetCOMPLOAD: () => set((state) => ({ COMPLOAD: !state.COMPLOAD })),
  SetPayrollList: (data) => set((state) => ({ PayrollList: data })),
  SetHistoryList: (data) => set((state) => ({ HistoryList: data })),
  SetLeaveList: (data) => set((state) => ({ LeaveList: data })),
  SetLeaveBalances: (data) => set((state) => ({ LeaveBalances: data })),
  SetLeaveTaken: (data) => set((state) => ({ LeaveTaken: data })),
  SetLeaveToDo: (evid) => set((state) => ({ LeaveToDo: evid })),
  SetCancelDialog: () =>
    set((state) => ({ CancelDialog: !state.CancelDialog })),
  SetEvalList: (data) => set((state) => ({ EvalList: data })),
  SetObjective: (data) => set((state) => ({ Objective: data })),
  SetObjectiveOpt: (data) => set((state) => ({ ObjectiveOpt: data })),
  SetObjectiveApp: (data) => set((state) => ({ ObjectiveApp: data })),
  SetObjectivetkn: (data) => set((state) => ({ Objectivetkn: data })),
  SetDeleteDialog: () =>
    set((state) => ({ DeleteDialog: !state.DeleteDialog })),
  SetObjectiveDialog: () =>
    set((state) => ({ ObjectiveDialog: !state.ObjectiveDialog })),
  SetRequestDialog: () =>
    set((state) => ({ RequestDialog: !state.RequestDialog })),
  SetSecondDialog: () =>
    set((state) => ({ SecondDialog: !state.SecondDialog })),
  SetSkipDialog: () => set((state) => ({ SkipDialog: !state.SkipDialog })),
  SetCommentDialog: () =>
    set((state) => ({ CommentDialog: !state.CommentDialog })),
  SetEvalToDo: (evid) => set((state) => ({ EvalToDo: evid })),
  SetSnackBarShow: () =>
    set((state) => ({ SnackBarShow: !state.SnackBarShow })),
  SetLoadingShow: () => set((state) => ({ LoadingShow: !state.LoadingShow })),
  SetSnackMessage: (mes) => set((state) => ({ SnackBarMessage: mes })),
  SetSnackBarType: (t) => set((state) => ({ SnackBarType: t })),
}));

export default EmpDataStore;
