import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Grid,
  Button,
  Alert,
  TextField,
} from "@mui/material";
import Cookies from "js-cookie";
import { StaffStore } from "../../../../Store";
import { APIURL } from "../../../../Context/configs";
import { Fragment } from "react";

export default function DeleteDialog() {
  const UpdateStaff = StaffStore((state) => state.UpdateStaff);
  const ChaneDeleteDialog = StaffStore((state) => state.ChaneDeleteDialog);
  const ChaneSnackBarShow = StaffStore((state) => state.ChaneSnackBarShow);
  const ChangeSnackMessage = StaffStore((state) => state.ChangeSnackMessage);
  const StaffDeleteDialog = StaffStore((state) => state.StaffDeleteDialog);
  const StaffToHandle = StaffStore((state) => state.StaffToHandle);
  const Staff = StaffStore((state) => state.Staff);
  const DeleteAdmin = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    fetch(APIURL + "manager/staff/deletestaff.php", {
      method: "post",
      body: JSON.stringify({
        fn: data.get("fullname"),
        ref: data.get("ref"),
        adid: data.get("adid"),
        IDEN: Cookies.get("userID"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          ChangeSnackMessage(item.content);
          if (item.id == 1) {
            ChaneSnackBarShow();
            ChaneDeleteDialog();
            fetch(APIURL + "manager/staff/liststaff.php", {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Request-Headers": "Content-Type, Authorization",
                "Access-Control-Allow-Credentials": "true",
              },
            })
              .then((res) => res.json())
              .then((result) => {
                UpdateStaff(result);
              });
          }
        });
      });
  };
  const CloseDialog = () => {
    ChaneDeleteDialog();
  };
  return (
    <Dialog open={StaffDeleteDialog} onClose={CloseDialog} fullWidth>
      {Staff.filter((st) => st.adminid === StaffToHandle).map((row) => (
        <Fragment key={row.adminid}>
          <DialogTitle color={"#2f3193"}>
            Delete SOC Staff {row.adminfullname}
          </DialogTitle>
          <DialogContent>
            <Box
              component="form"
              onSubmit={DeleteAdmin}
              noValidate
              sx={{ mt: 1 }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12} sx={{ padding: 3, mt: 5 }}>
                  <Alert severity="warning" variant="outlined">
                    Are You Sure You Want To Delete SOC Staff (
                    {row.adminfullname})
                  </Alert>
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ display: "none" }}>
                  <TextField
                    type="hidden"
                    id="ref"
                    name="ref"
                    value={row.userref}
                  />
                  <TextField
                    type="hidden"
                    id="adid"
                    name="adid"
                    value={row.adminid}
                  />
                  <TextField
                    type="hidden"
                    id="fullname"
                    name="fullname"
                    value={row.adminfullname}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ textAlign: "right" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Delete SOC Staff
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="error"
                    sx={{ mt: 3, mb: 2, ml: 2 }}
                    onClick={CloseDialog}
                  >
                    Close Form
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </Fragment>
      ))}
    </Dialog>
  );
}
