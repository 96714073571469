import { Box, Container } from "@mui/material";
import { TopBar, BottomBar } from "../Common/Pagebars";
import Navbar from "./Navbar";
import { Routes, Route } from "react-router-dom";
import { EmployeesStore, userLoginStore } from "../../Store";
import { useEffect, useState } from "react";
import { APIURL, BASEURI } from "../../Context/configs";
import {
  BugsReport,
  Complains,
  Evaluations,
  FileManager,
  GeneralInfo,
  HealthInsurance,
  JobHistory,
  Leaves,
  PayrollInfo,
  Supervisors,
  Toturial,
  WhatsNew,
  Objective,
  InsertEmailAndPhone,
  Training,
} from "./Pages";
import Cookies from "js-cookie";
import { GridLoader } from "../Common/Loaders";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
export default function EmployeeContainer() {
  const LoggedIn = userLoginStore((state) => state.isLoggedIn);
  const userRole = userLoginStore((state) => state.userRole);
  const SetEmpData = EmployeesStore((state) => state.SetEmpData);
  const EmpData = EmployeesStore((state) => state.EmpData);
  const [isLoading, SetisLoading] = useState(true);
  useEffect(() => {
    if (!LoggedIn) {
      window.location.href = BASEURI;
    } else {
      if (userRole != "employee") {
        window.location.href = BASEURI + userRole;
      } else {
        fetch(APIURL + "employee/general/listinformation.php", {
          method: "post",
          body: JSON.stringify({
            soc: Cookies.get("userRef"),
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Request-Headers": "Content-Type, Authorization",
            "Access-Control-Allow-Credentials": "true",
          },
        })
          .then((res) => res.json())
          .then((result) => {
            SetEmpData(result);
            setTimeout(() => {
              SetisLoading(false);
            }, 3000);
          });
      }
    }
  }, [LoggedIn, userRole]);
  return (
    <Box sx={{ display: "flex", flexGrow: 0 }}>
      <Navbar />
      <Container maxWidth="auto" disableGutters>
        <TopBar title="Employee Control Panel" />
        {isLoading ? (
          <Box
            sx={{
              height: "70vh",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <GridLoader />
          </Box>
        ) : (
          <div className="sitContent">
            {EmpData[0].email != null ? (
              <Routes>
                <Route path="/" element={<GeneralInfo />} />
                <Route path="/generalinfo" element={<GeneralInfo />} />
                <Route path="/payrollinfo" element={<PayrollInfo />} />
                <Route path="/historyinfo" element={<JobHistory />} />
                <Route path="/filesmanager" element={<FileManager />} />
                <Route path="/supervisors" element={<Supervisors />} />
                <Route path="/leaves" element={<Leaves />} />
                <Route path="/objective" element={<Objective />} />
                <Route path="/evaluation" element={<Evaluations />} />
                <Route path="/training" element={<Training />} />
                <Route path="/healtinsurance" element={<HealthInsurance />} />
                <Route path="/complains" element={<Complains />} />
                <Route path="/reports" element={<BugsReport />} />
                <Route path="/tutorial" element={<Toturial />} />
                <Route path="/new" element={<WhatsNew />} />
              </Routes>
            ) : (
              <InsertEmailAndPhone />
            )}
          </div>
        )}
        <TawkMessengerReact
          propertyId="6469e3ef74285f0ec46cb664"
          widgetId="1h0uq4oaa"
        />
        <BottomBar />
      </Container>
    </Box>
  );
}
