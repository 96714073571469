import { EmployeesStore } from "../../../../Store";
import { Snackbar, Alert } from "@mui/material";
export default function ActionMessage() {
  const ChaneSnackBarShow = EmployeesStore((state) => state.ChaneSnackBarShow);
  const SnackBarShow = EmployeesStore((state) => state.SnackBarShow);
  const SnackBarMessage = EmployeesStore((state) => state.SnackBarMessage);
  return (
    <Snackbar
      open={SnackBarShow}
      autoHideDuration={3000}
      onClose={ChaneSnackBarShow}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        onClose={ChaneSnackBarShow}
        severity="info"
        variant="outlined"
        sx={{
          "& .MuiAlert-message": { textAlign: "center", width: "inherit" },
        }}
      >
        {SnackBarMessage}
      </Alert>
    </Snackbar>
  );
}
