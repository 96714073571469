import { Container, CssBaseline } from "@mui/material";
import {
  LoginHeader,
  ResetForm,
  LoginFooter,
  Logos,
} from "../Elements/LoginPage";

export default function ResetPage() {
  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <LoginHeader />
        <ResetForm />
      </Container>
      <Container component="main" maxWidth="lg">
        <Logos />
        <LoginFooter />
      </Container>
    </>
  );
}
