import {
  Paper,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Chip,
  LinearProgress,
  IconButton,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { EmpDataStore } from "../../../../Store";
import { useEffect, useState } from "react";
import { DualRingLoader } from "../../../Common/Loaders";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import HourglassTopTwoToneIcon from "@mui/icons-material/HourglassTopTwoTone";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import FastForwardTwoToneIcon from "@mui/icons-material/FastForwardTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import ChatBubbleTwoToneIcon from "@mui/icons-material/ChatBubbleTwoTone";
import ReplayCircleFilledTwoToneIcon from "@mui/icons-material/ReplayCircleFilledTwoTone";
import { BASEURI } from "../../../../Context/configs";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
export default function ListEvaluations() {
  const EvalList = EmpDataStore((state) => state.EvalList);
  const SetDeleteDialog = EmpDataStore((state) => state.SetDeleteDialog);
  const SetSecondDialog = EmpDataStore((state) => state.SetSecondDialog);
  const SetCommentDialog = EmpDataStore((state) => state.SetCommentDialog);
  const SetSkipDialog = EmpDataStore((state) => state.SetSkipDialog);
  const SetEvalToDo = EmpDataStore((state) => state.SetEvalToDo);
  const [ShowDiaCont, SetShowDiaCont] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      SetShowDiaCont(true);
    }, 3000);
  }, []);
  const StatusShow = (param, param2) => {
    if (param === 1) {
      return (
        <Chip
          size="small"
          label={param2}
          color="success"
          icon={<CheckCircleTwoToneIcon />}
          sx={{ fontSize: 10 }}
        ></Chip>
      );
    } else {
      return (
        <Chip
          size="small"
          label="Awaiting For Response"
          color="warning"
          icon={<HourglassTopTwoToneIcon />}
          sx={{ fontSize: 10 }}
        ></Chip>
      );
    }
  };
  const SendToHOSO = (param) => {
    SetEvalToDo(param);
    SetSecondDialog();
  };
  const SkipHOSO = (param) => {
    SetEvalToDo(param);
    SetSkipDialog();
  };
  const DeleteEval = (param) => {
    SetEvalToDo(param);
    SetDeleteDialog();
  };
  const CommentEval = (param) => {
    SetEvalToDo(param);
    SetCommentDialog();
  };
  const ShowActions = (p1, p2, p3, p4) => {
    if (p1 === 1 && p2 === 1 && p3 === 1) {
      return null;
    } else if (p1 === 1 && p2 === 1 && p3 === 0) {
      return (
        <>
          <IconButton
            size="small"
            color="info"
            title="Re Send To Head Of Sub Office"
            onClick={() => SendToHOSO(p4)}
          >
            <ReplayCircleFilledTwoToneIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            color="secondary"
            title="Comment Evaluation"
            onClick={() => CommentEval(p4)}
          >
            <ChatBubbleTwoToneIcon fontSize="small" />
          </IconButton>
        </>
      );
    } else if (p1 === 1 && p2 === 0 && p3 === 0) {
      return (
        <>
          <IconButton
            size="small"
            color="warning"
            title="Skip Head Of Sub Office"
            onClick={() => SkipHOSO(p4)}
          >
            <FastForwardTwoToneIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            color="success"
            title="Send To Head Of Sub Office"
            onClick={() => SendToHOSO(p4)}
          >
            <SendTwoToneIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            color="error"
            title="Delete Evaluation"
            onClick={() => DeleteEval(p4)}
          >
            <DeleteTwoToneIcon fontSize="small" />
          </IconButton>
        </>
      );
    } else {
      return (
        <IconButton
          size="small"
          color="error"
          title="Delete Evaluation"
          onClick={() => DeleteEval(p4)}
        >
          <DeleteTwoToneIcon fontSize="small" />
        </IconButton>
      );
    }
  };
  const EvaluatedCol = [
    {
      field: "req_foryear",
      headerName: "Year",
      sortable: true,
      width: 100,
    },
    {
      field: "req_formonth",
      headerName: "Month",
      sortable: true,
      width: 100,
    },
    {
      field: "req_date",
      headerName: "Date",
      sortable: false,
      width: 175,
    },
    {
      field: "fp",
      headerName: "Focal Point",
      sortable: false,
      width: 175,
      renderCell: (params) => (
        <>{StatusShow(params.row.ds_done, params.row.ds_name)}</>
      ),
    },
    {
      field: "hoso",
      headerName: "Head Of Sub Office",
      sortable: false,
      width: 175,
      renderCell: (params) => (
        <>{StatusShow(params.row.sl_done, params.row.sl_name)}</>
      ),
    },
    {
      field: "staff",
      headerName: "Staff",
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <>{StatusShow(params.row.staff_done, params.row.fullname)}</>
      ),
    },
    {
      field: "progress",
      headerName: "Progress",
      sortable: false,
      width: 200,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Box sx={{ width: "60%" }}>
            <LinearProgress
              variant="determinate"
              value={params.row.progress}
              color="success"
            />
          </Box>
          <Box sx={{ width: "40%", textAlign: "center" }}>
            <Typography variant="body2" color="text.secondary">
              {params.row.progress}%
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton
            size="small"
            color="primary"
            title="View Evaluation"
            href={BASEURI + "evaluation/" + params.row.eval_id + "/view/"}
            target="_blank"
          >
            <VisibilityTwoToneIcon fontSize="small" />
          </IconButton>
          {ShowActions(
            params.row.ds_done,
            params.row.sl_done,
            params.row.staff_done,
            params.row.eval_id
          )}
        </>
      ),
    },
  ];
  return (
    <>
      {ShowDiaCont ? (
        <Grid container sx={{ mt: 3 }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h6" color={"#2f3193"}>
              Evaluation List
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
            {EvalList === null ? (
              <Typography variant="h6" sx={{ textAlign: "center", my: 3 }}>
                No Data To Preview
              </Typography>
            ) : (
              <DataGrid
                getRowId={(row) => row.eval_id}
                rows={EvalList}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                  },
                }}
                columns={EvaluatedCol}
                pageSize={25}
                rowsPerPageOptions={[25]}
                disableSelectionOnClick
                autoHeight
                width={"100%"}
                disableColumnMenu
                density="compact"
              />
            )}
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ py: 5, textAlign: "center" }}>
          <DualRingLoader />
        </Box>
      )}
    </>
  );
}
