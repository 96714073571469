import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import Html from "react-pdf-html";
import LOGO from "../../../../Assets/IMG/logo.png";
export default function PdfViewer(props) {
  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
    },
    row: {
      flexDirection: "row",
      fontSize: "10pt",
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 5,
    },
    section: {
      flexDirection: "row",
      fontSize: "10pt",
      paddingTop: 5,
      paddingBottom: 5,
      minWidth: "50%",
    },
    section3: {
      flexDirection: "column",
      fontSize: "10pt",
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 10,
      paddingRight: 10,
      minWidth: "50%",
    },
    section2: {
      flexDirection: "column",
      fontSize: "10pt",
      paddingTop: 5,
      paddingBottom: 5,
    },
    text: {
      paddingRight: 10,
    },
    text2: {
      color: "#850000",
    },
    text3: {
      paddingRight: 10,
      marginLeft: 10,
      marginRight: 10,
      paddingTop: 5,
      paddingBottom: 5,
    },
    text4: {
      marginLeft: 10,
      marginRight: 10,
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 10,
      paddingRight: 10,
      borderRadius: 3,
    },
    text5: {
      color: "#dc3545",
      paddingTop: 5,
      paddingBottom: 5,
      fontSize: "14pt",
      fontWeight: "extrabold",
    },
    text6: {
      color: "#dc3545",
      paddingTop: 5,
      paddingBottom: 5,
      fontSize: "8pt",
    },
    image: {
      width: "15%",
      marginBottom: 10,
    },
    TitleBar: {
      width: "100%",
      backgroundColor: "#000000",
    },
    TitleText: {
      padding: 10,
      color: "#FFFFFF",
      fontSize: "12pt",
    },
  });
  return (
    <Document key={props.evData[0].eval_id}>
      <Page size="A4" style={styles.page}>
        <View style={styles.row}>
          <Image src={LOGO} style={styles.image} />
        </View>
        <View style={styles.TitleBar}>
          <Text style={styles.TitleText}>
            Evaluation of ({props.evData[0].staff_no}){" "}
            {props.evData[0].fullname} For {props.evData[0].req_formonth} -{" "}
            {props.evData[0].req_foryear}
          </Text>
        </View>
        <View style={styles.row}>
          <View style={styles.section}>
            <Text style={styles.text}>Staff Name:</Text>
            <Text style={styles.text2}>{props.evData[0].fullname}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}>Staff #:</Text>
            <Text style={styles.text2}>{props.evData[0].staff_no}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.section}>
            <Text style={styles.text}>Position:</Text>
            <Text style={styles.text2}>{props.evData[0].jposition}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}>Divison:</Text>
            <Text style={styles.text2}>{props.evData[0].department}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.section}>
            <Text style={styles.text}>Duty Station:</Text>
            <Text style={styles.text2}>{props.evData[0].dutystation}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}>Conrtact Duration:</Text>
            <Text style={styles.text2}>
              {props.evData[0].contract_duration}
            </Text>
          </View>
        </View>
        <View>
          <View style={styles.section2}>
            <Text style={styles.text3}>Objectives:</Text>
            <Text style={styles.text4}>
              <Html style={{ fontSize: 10 }}>{props.evData[0].objectives}</Html>
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.section2}>
            <Text style={styles.text5}>
              A. Achievements against Terms of Reference
            </Text>
            <Text style={styles.text6}>* To be filled by Supervisor</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.section}>
            <Text style={styles.text}>Rating:</Text>
            <Text style={styles.text2}>{props.evData[0].rating}</Text>
          </View>
        </View>
        <View>
          <View style={styles.section2}>
            <Text style={styles.text3}>COMMENTS ON OVERALL PERFORMANCE :</Text>
            <Text style={styles.text4}>
              <Html style={{ fontSize: 10 }}>{props.evData[0].ds_comment}</Html>
            </Text>
          </View>
        </View>
        <View style={styles.section3}>
          <View style={styles.section}>
            <Text style={styles.text}>Supervisor:</Text>
            <Text style={styles.text2}>{props.evData[0].ds_name}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}>Supervisor Evaluation Date:</Text>
            <Text style={styles.text2}>{props.evData[0].ds_comment_date}</Text>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.row}>
          <Image src={LOGO} style={styles.image} />
        </View>
        <View style={styles.TitleBar}>
          <Text style={styles.TitleText}>
            Evaluation of ({props.evData[0].staff_no}){" "}
            {props.evData[0].fullname} For {props.evData[0].req_formonth} -{" "}
            {props.evData[0].req_foryear}
          </Text>
        </View>
        <View style={styles.row}>
          <View style={styles.section2}>
            <Text style={styles.text5}>
              B. COMMENTS BY Second Level Supervisor (if any)
            </Text>
            <Text style={styles.text6}>
              * To be filled by second level Supervisor
            </Text>
          </View>
        </View>
        <View>
          <View style={styles.section2}>
            <Text style={styles.text3}>COMMENTS ON OVERALL PERFORMANCE :</Text>
            <Text style={styles.text4}>
              <Html style={{ fontSize: 10 }}>{props.evData[0].sl_comment}</Html>
            </Text>
          </View>
        </View>
        <View style={styles.section3}>
          <View style={styles.section}>
            <Text style={styles.text}>Second Level Supervisor:</Text>
            <Text style={styles.text2}>{props.evData[0].sl_name}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}>
              Second Level Supervisor Evaluation Date:
            </Text>
            <Text style={styles.text2}>{props.evData[0].sl_comment_date}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.section2}>
            <Text style={styles.text5}>
              C. Comments by Staff Members on his/her own performance
            </Text>
            <Text style={styles.text6}>* To be filled by staff</Text>
          </View>
        </View>
        <View style={styles.row}>
          <Text style={styles.text2}>
            <Html style={{ fontSize: 10 }}>
              {props.evData[0].staff_agreement}
            </Html>
          </Text>
        </View>
        <View>
          <View style={styles.section2}>
            <Text style={styles.text3}>COMMENTS :</Text>
            <Text style={styles.text4}>
              <Html style={{ fontSize: 10 }}>
                {props.evData[0].staff_comment}
              </Html>
            </Text>
          </View>
        </View>
        <View style={styles.section3}>
          <View style={styles.section}>
            <Text style={styles.text}>Staff Name:</Text>
            <Text style={styles.text2}>{props.evData[0].fullname}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}>Staff Comment Date:</Text>
            <Text style={styles.text2}>
              {props.evData[0].staff_comment_date}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
