import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
  Grid,
  Button,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Alert,
} from "@mui/material";
import Cookies from "js-cookie";
import { useState } from "react";
import { StaffStore } from "../../../../Store";
import md5 from "md5";
import { APIURL } from "../../../../Context/configs";
export default function AddDialog() {
  const UpdateStaff = StaffStore((state) => state.UpdateStaff);
  const ChaneAddDialog = StaffStore((state) => state.ChaneAddDialog);
  const ChaneSnackBarShow = StaffStore((state) => state.ChaneSnackBarShow);
  const ChangeSnackMessage = StaffStore((state) => state.ChangeSnackMessage);
  const StaffAddDialog = StaffStore((state) => state.StaffAddDialog);
  const [stfpriv, setstfpriv] = useState(0);
  const [iompriv, setiompriv] = useState(0);
  const [emppriv, setemppriv] = useState(0);
  const [paypriv, setpaypriv] = useState(0);
  const [leapriv, setleapriv] = useState(0);
  const [evapriv, setevapriv] = useState(0);
  const [syspriv, setsyspriv] = useState(0);
  const [showError, SetshowError] = useState(false);
  const [showWarning, SetshowWarning] = useState(false);
  const AddNewAdmin = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (
      data.get("fullname") == "" ||
      data.get("position") == "" ||
      data.get("email") == "" ||
      data.get("username") == "" ||
      data.get("password") == "" ||
      data.get("cpassword") == ""
    ) {
      SetshowError(true);
      SetshowWarning(false);
    } else if (md5(data.get("password")) != md5(data.get("cpassword"))) {
      SetshowWarning(true);
      SetshowError(false);
    } else {
      SetshowError(false);
      SetshowWarning(false);
      fetch(APIURL + "manager/staff/newstaff.php", {
        method: "post",
        body: JSON.stringify({
          FN: data.get("fullname"),
          PO: data.get("position"),
          EM: data.get("email"),
          UN: data.get("username"),
          PW: md5(data.get("password")),
          CPW: md5(data.get("cpassword")),
          p1: stfpriv,
          p2: iompriv,
          p3: emppriv,
          p4: paypriv,
          p5: leapriv,
          p6: evapriv,
          p7: syspriv,
          IDEN: Cookies.get("userID"),
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Request-Headers": "Content-Type, Authorization",
          "Access-Control-Allow-Credentials": "true",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          result.forEach((item) => {
            ChangeSnackMessage(item.content);
            if (item.id == 1) {
              ChaneSnackBarShow();
              ChaneAddDialog();
              fetch(APIURL + "manager/staff/liststaff.php", {
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Request-Headers":
                    "Content-Type, Authorization",
                  "Access-Control-Allow-Credentials": "true",
                },
              })
                .then((res) => res.json())
                .then((result) => {
                  UpdateStaff(result);
                });
            }
          });
        });
    }
  };
  const CloseDialog = () => {
    ChaneAddDialog();
    SetshowError(false);
    SetshowWarning(false);
  };
  return (
    <Dialog open={StaffAddDialog} onClose={CloseDialog} maxWidth="lg" fullWidth>
      <DialogTitle color={"#2f3193"}>Add New SOC Staff</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={AddNewAdmin} noValidate sx={{ mt: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={4}>
              <Typography sx={{ fontWeight: "bold" }} color={"#2f3193"}>
                Personal info
              </Typography>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  variant="standard"
                  margin="normal"
                  required={true}
                  fullWidth
                  size="small"
                  id="fullname"
                  label="Fullname"
                  name="fullname"
                  autoComplete="fullname"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  variant="standard"
                  margin="normal"
                  required={true}
                  fullWidth
                  size="small"
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  variant="standard"
                  margin="normal"
                  required={true}
                  fullWidth
                  size="small"
                  id="position"
                  label="Position"
                  name="position"
                  autoComplete="position"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
              <Typography sx={{ fontWeight: "bold" }} color={"#2f3193"}>
                Account info
              </Typography>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  variant="standard"
                  margin="normal"
                  required={true}
                  fullWidth
                  size="small"
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  variant="standard"
                  margin="normal"
                  type="password"
                  required={true}
                  fullWidth
                  size="small"
                  id="password"
                  label="Password"
                  name="password"
                  autoComplete="password"
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  variant="standard"
                  margin="normal"
                  type="password"
                  required={true}
                  fullWidth
                  size="small"
                  id="cpassword"
                  label="Confirm Password"
                  name="cpassword"
                  autoComplete="cpassword"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
              <Typography sx={{ fontWeight: "bold", mb: 2 }} color={"#2f3193"}>
                Privileges
              </Typography>
              <Grid container spacing={2}>
                <Grid item lg={6} md={12} sm={12}>
                  <FormControl variant="standard" fullWidth size="small">
                    <InputLabel htmlFor="staff">SOC Staff</InputLabel>
                    <Select
                      labelId="staff"
                      id="staff"
                      label="staff"
                      onChange={(event) => setstfpriv(event.target.value)}
                      value={stfpriv}
                    >
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={12} sm={12}>
                  <FormControl variant="standard" fullWidth size="small">
                    <InputLabel htmlFor="iom">IOM HR</InputLabel>
                    <Select
                      labelId="iom"
                      id="iom"
                      label="iom"
                      onChange={(event) => setiompriv(event.target.value)}
                      value={iompriv}
                    >
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={12} sm={12}>
                  <FormControl variant="standard" fullWidth size="small">
                    <InputLabel htmlFor="employees">Employees</InputLabel>
                    <Select
                      labelId="employees"
                      id="employees"
                      label="employees"
                      onChange={(event) => setemppriv(event.target.value)}
                      value={emppriv}
                    >
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={12} sm={12}>
                  <FormControl variant="standard" fullWidth size="small">
                    <InputLabel htmlFor="payroll">Payroll</InputLabel>
                    <Select
                      labelId="payroll"
                      id="payroll"
                      label="payroll"
                      onChange={(event) => setpaypriv(event.target.value)}
                      value={paypriv}
                    >
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={12} sm={12}>
                  <FormControl variant="standard" fullWidth size="small">
                    <InputLabel htmlFor="leaves">Leaves & Vacations</InputLabel>
                    <Select
                      labelId="leaves"
                      id="leaves"
                      label="leaves"
                      onChange={(event) => setleapriv(event.target.value)}
                      value={leapriv}
                    >
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={12} sm={12}>
                  <FormControl variant="standard" fullWidth size="small">
                    <InputLabel htmlFor="evaluations">Evaluations</InputLabel>
                    <Select
                      labelId="evaluations"
                      id="evaluations"
                      label="evaluations"
                      onChange={(event) => setevapriv(event.target.value)}
                      value={evapriv}
                    >
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={12} sm={12}>
                  <FormControl variant="standard" fullWidth size="small">
                    <InputLabel htmlFor="systemlogs">
                      System Configuration
                    </InputLabel>
                    <Select
                      labelId="systemlogs"
                      id="systemlogs"
                      label="systemlogs"
                      onChange={(event) => setsyspriv(event.target.value)}
                      value={syspriv}
                    >
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ padding: 3, mt: 5 }}>
              {showError && (
                <Alert variant="outlined" severity="error">
                  Please fill the required fields marked with * to complete the
                  form submittion.
                </Alert>
              )}
              {showWarning && (
                <Alert variant="outlined" severity="warning">
                  Password and Password confirmation is not matched
                </Alert>
              )}
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ textAlign: "right" }}>
              <Button
                type="submit"
                variant="contained"
                color="success"
                sx={{ mt: 3, mb: 2 }}
              >
                Add SOC Staff
              </Button>
              <Button
                type="button"
                variant="contained"
                color="error"
                sx={{ mt: 3, mb: 2, ml: 2 }}
                onClick={CloseDialog}
              >
                Close Form
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
