import { Box, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { APIURL } from "../../../Context/configs";
import { RingLoader } from "../../Common/Loaders";
import { BC } from "../Elements/Common/";
import { EmpDataStore, EmployeesStore } from "../../../Store";
import { ShowPayroll, SalaryInfromation } from "../Elements/Payroll/";

export default function PayrollInfo() {
  const [isLoading, setisLoading] = useState(true);
  const EmpData = EmployeesStore((state) => state.EmpData);
  const SetPayrollList = EmpDataStore((state) => state.SetPayrollList);
  useEffect(() => {
    document.title = "StarsOrbit HRM - Payroll Information";
    fetch(APIURL + "employee/payroll/payroll.php", {
      method: "post",
      body: JSON.stringify({
        soc: EmpData[0].ref,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetPayrollList(result);
        setTimeout(() => {
          setisLoading(false);
        }, 2000);
      });
  }, []);
  return (
    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
      <BC data="Payroll Information" />
      {isLoading ? (
        <Box sx={{ py: 5, my: 5, textAlign: "center" }}>
          <RingLoader />
        </Box>
      ) : (
        <>
          <SalaryInfromation />
          <ShowPayroll />
        </>
      )}
    </Container>
  );
}
