import { useState, useEffect } from "react";
import { APIURL } from "../../../../Context/configs";
import { RingLoader } from "../../../Common/Loaders";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Grid, Typography, Paper, Box } from "@mui/material";

ChartJS.register(ArcElement, Tooltip, Legend);
export default function DashCharts() {
  const [isLoading, setisLoading] = useState(true);
  const [TotActive, SetTotActive] = useState(0);
  const [TotInActive, SetTotInActive] = useState(0);
  const [TOTFTE, SetTOTFTE] = useState(0);
  const [TOTPTE, SetTOTPTE] = useState(0);
  const [TOTDCE, SetTOTDCE] = useState(0);
  const [TOTHCE, SetTOTHCE] = useState(0);
  const [TOTOTPE, SetTOTONTE] = useState(0);
  const [TOTFTINTE, SetTOTFTINTE] = useState(0);
  const [TOTCASH, SetTOTCASH] = useState(0);
  const [TOTBANK, SetTOTBANK] = useState(0);
  useEffect(() => {
    fetch(APIURL + "organization/dashboard/dashwidgets.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetTotActive(item.TOTACT);
          SetTotInActive(item.TOTIAC);
          SetTOTFTE(item.TOTFT);
          SetTOTPTE(item.TOTPT);
          SetTOTDCE(item.TOTDC);
          SetTOTHCE(item.TOTHC);
          SetTOTONTE(item.TOTOTP);
          SetTOTFTINTE(item.TOTFTINT);
          SetTOTCASH(item.TOTCASH);
          SetTOTBANK(item.TOTBANK);
        });
        setisLoading(false);
      });
  }, []);
  const EmpByCt = {
    labels: [
      "Full Time",
      "Full Time - Int",
      "Part Time",
      "Daily Contract",
      "Hourly Contract",
      "Onetime Payment",
    ],
    datasets: [
      {
        label: "Employees By Contract",
        data: [TOTFTE, TOTFTINTE, TOTPTE, TOTDCE, TOTHCE, TOTOTPE],
        backgroundColor: [
          "rgba(54, 162, 235, 0.5)",
          "rgba(153, 102, 255, 0.5)",
          "rgba(75, 192, 15, 0.5)",
          "rgba(255, 99, 132, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(0, 0, 0, 0.5)",
        ],
        hoverBackgroundColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(75, 192, 15, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(0, 0, 0, 1)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(75, 192, 15, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(0, 0, 0, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const EmpByST = {
    labels: ["Active Emplyees", "Inactive Employees"],
    datasets: [
      {
        label: "Employees By Status",
        data: [TotActive, TotInActive],
        backgroundColor: ["rgba(75, 192, 192, 0.5)", "rgba(255, 99, 132, 0.5)"],
        hoverBackgroundColor: [
          "rgba(75, 192, 192, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
        borderWidth: 1,
      },
    ],
  };
  const EmpByPay = {
    labels: ["Bank Payment", "Cash Payment"],
    datasets: [
      {
        label: "Employees By Payment Type",
        data: [TOTBANK, TOTCASH],
        backgroundColor: ["rgba(75, 192, 15, 0.5)", "rgba(255, 99, 132, 0.5)"],
        hoverBackgroundColor: ["rgba(75, 192, 15, 1)", "rgba(255, 99, 132, 1)"],
        borderColor: ["rgba(75, 192, 15, 1)", "rgba(255, 99, 132, 1)"],
        borderWidth: 1,
      },
    ],
  };
  return (
    <>
      {isLoading ? (
        <div className="siteLoader">
          <RingLoader />
        </div>
      ) : (
        <Grid key="widlister" container spacing={1} sx={{ my: 3 }}>
          <Grid item xs={12} md={12} lg={4}>
            <Paper elevation={3} sx={{ p: 1, border: "1px #bbb solid" }}>
              <Typography variant="h5" color={"#2f3193"} sx={{ mb: 3 }}>
                Employees By Contract
              </Typography>
              <Pie data={EmpByCt} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <Paper elevation={3} sx={{ p: 1, border: "1px #bbb solid" }}>
              <Typography variant="h5" color={"#2f3193"} sx={{ mb: 3 }}>
                Employees By Payment Type
              </Typography>
              <Pie data={EmpByPay} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <Paper elevation={3} sx={{ p: 1, border: "1px #bbb solid" }}>
              <Typography variant="h5" color={"#2f3193"} sx={{ mb: 3 }}>
                Employees By Status
              </Typography>
              <Pie data={EmpByST} />
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
}
