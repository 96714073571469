import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Grid,
  Button,
  Alert,
  TextField,
} from "@mui/material";
import Cookies from "js-cookie";
import { IOMStore } from "../../../../Store";
import { APIURL } from "../../../../Context/configs";
import { Fragment } from "react";

export default function DeleteDialog() {
  const UpdateIOM = IOMStore((state) => state.UpdateIOM);
  const ChaneDeleteDialog = IOMStore((state) => state.ChaneDeleteDialog);
  const ChaneSnackBarShow = IOMStore((state) => state.ChaneSnackBarShow);
  const ChangeSnackMessage = IOMStore((state) => state.ChangeSnackMessage);
  const IOMDeleteDialog = IOMStore((state) => state.IOMDeleteDialog);
  const StaffToHandle = IOMStore((state) => state.IOMToHandle);
  const Staff = IOMStore((state) => state.IOMlist);
  const DeleteHR = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    fetch(APIURL + "manager/iom/iomdelete.php", {
      method: "post",
      body: JSON.stringify({
        fn: data.get("fullname"),
        ref: data.get("ref"),
        IDEN: Cookies.get("userID"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          ChangeSnackMessage(item.content);
          if (item.id == 1) {
            ChaneSnackBarShow();
            ChaneDeleteDialog();
            fetch(APIURL + "manager/iom/iomlist.php", {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Request-Headers": "Content-Type, Authorization",
                "Access-Control-Allow-Credentials": "true",
              },
            })
              .then((res) => res.json())
              .then((result) => {
                UpdateIOM(result);
              });
          }
        });
      });
  };
  const CloseDialog = () => {
    ChaneDeleteDialog();
  };
  return (
    <Dialog open={IOMDeleteDialog} onClose={CloseDialog} fullWidth>
      {Staff.filter((st) => st.staff_id === StaffToHandle).map((row) => (
        <Fragment key={row.staff_id}>
          <DialogTitle color={"#2f3193"}>
            Delete IOM HR Member {row.fullname}
          </DialogTitle>
          <DialogContent>
            <Box component="form" onSubmit={DeleteHR} noValidate sx={{ mt: 1 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12} sx={{ padding: 3, mt: 5 }}>
                  <Alert severity="warning" variant="outlined">
                    Are You Sure You Want To Delete IOM HR Member (
                    {row.fullname})
                  </Alert>
                </Grid>
                <Grid item lg={12} md={12} sm={12} sx={{ display: "none" }}>
                  <TextField
                    type="hidden"
                    id="fullname"
                    label="fullname"
                    name="fullname"
                    autoComplete="fullname"
                    value={row.fullname}
                  />
                  <TextField
                    type="hidden"
                    id="ref"
                    name="ref"
                    value={row.ref}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ textAlign: "right" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Delete IOM HR Member
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="error"
                    sx={{ mt: 3, mb: 2, ml: 2 }}
                    onClick={CloseDialog}
                  >
                    Close Form
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </Fragment>
      ))}
    </Dialog>
  );
}
