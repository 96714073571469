import { Box, Container } from "@mui/material";
import { APIURL } from "../../../Context/configs";
import BC from "../Elements/Common/BC";
import HiPage from "../Elements/HealthInsurance/HiPage";
import { HealthInsStore } from "../../../Store";
import { RingLoader } from "../../Common/Loaders";
import { useEffect, useState } from "react";
export default function HealthInsurance() {
  const [isLoading, setisLoading] = useState(true);
  const SetInfo = HealthInsStore((state) => state.SetDataToShow);
  useEffect(() => {
    document.title = "StarsOrbit HRM - Health Insurance Info";
    fetch(APIURL + "portalhome/healthinsurance.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetInfo(result);
      });
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  }, []);
  return (
    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
      <BC data="Health Insurance" />
      {isLoading ? (
        <Box sx={{ py: 5, my: 5, textAlign: "center" }}>
          <RingLoader />
        </Box>
      ) : (
        <HiPage />
      )}
    </Container>
  );
}
