import { create } from "zustand";
const IOMStore = create((set) => ({
  IOMlist: [],
  IOMEditDialog: false,
  IOMAddDialog: false,
  IOMDeleteDialog: false,
  SnackBarShow: false,
  SnackBarMessage: "",
  IOMToHandle: 0,
  UpdateIOM: (stf) => set((state) => ({ IOMlist: stf })),
  SetIOMToHandle: (stf) => set((state) => ({ IOMToHandle: stf })),
  ChaneEditDialog: () =>
    set((state) => ({ IOMEditDialog: !state.IOMEditDialog })),
  ChaneAddDialog: () => set((state) => ({ IOMAddDialog: !state.IOMAddDialog })),
  ChaneDeleteDialog: () =>
    set((state) => ({ IOMDeleteDialog: !state.IOMDeleteDialog })),
  ChaneSnackBarShow: () =>
    set((state) => ({ SnackBarShow: !state.SnackBarShow })),
  ChangeSnackMessage: (mes) => set((state) => ({ SnackBarMessage: mes })),
}));

export default IOMStore;
