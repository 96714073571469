import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  Box,
  Alert,
} from "@mui/material";
import { EmpDataStore, EmployeesStore } from "../../../../Store";
import { APIURL } from "../../../../Context/configs";
import { useEffect, useState } from "react";
import { GridLoader } from "../../../Common/Loaders";
export default function CancelLeave() {
  const EmpData = EmployeesStore((state) => state.EmpData);
  const LeaveList = EmpDataStore((state) => state.LeaveList);
  const CancelDialog = EmpDataStore((state) => state.CancelDialog);
  const SetCancelDialog = EmpDataStore((state) => state.SetCancelDialog);
  const SetSnackMessage = EmpDataStore((state) => state.SetSnackMessage);
  const SetSnackBarType = EmpDataStore((state) => state.SetSnackBarType);
  const SetLoadingShow = EmpDataStore((state) => state.SetLoadingShow);
  const SetLeaveList = EmpDataStore((state) => state.SetLeaveList);
  const SetLeaveTaken = EmpDataStore((state) => state.SetLeaveTaken);
  const SetSnackBarShow = EmpDataStore((state) => state.SetSnackBarShow);
  const LeaveToDo = EmpDataStore((state) => state.LeaveToDo);
  const [ShowDiaCont, SetShowDiaCont] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      SetShowDiaCont(true);
    }, 3000);
  }, []);
  const CloseDialog = () => {
    SetCancelDialog();
  };
  const DeleteLeave = () => {
    SetLoadingShow();
    fetch(APIURL + "employee/leave/cancelleave.php", {
      method: "post",
      body: JSON.stringify({
        req: LeaveToDo,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetSnackMessage(item.content);
          if (item.id === 1) {
            SetSnackBarType("success");
            setTimeout(() => {
              fetch(APIURL + "employee/leave/takenleaves.php", {
                method: "post",
                body: JSON.stringify({
                  soc: EmpData[0].ref,
                }),
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Request-Headers":
                    "Content-Type, Authorization",
                  "Access-Control-Allow-Credentials": "true",
                },
              })
                .then((res) => res.json())
                .then((result) => {
                  SetLeaveTaken(result);
                });
              fetch(APIURL + "employee/leave/listleaves.php", {
                method: "post",
                body: JSON.stringify({
                  soc: EmpData[0].ref,
                }),
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Request-Headers":
                    "Content-Type, Authorization",
                  "Access-Control-Allow-Credentials": "true",
                },
              })
                .then((res) => res.json())
                .then((result) => {
                  SetLeaveList(result);
                });
              SetLoadingShow();
              SetSnackBarShow();
              CloseDialog();
            }, 2000);
          } else {
            SetSnackBarType("error");
            setTimeout(() => {
              SetLoadingShow();
              SetSnackBarShow();
              CloseDialog();
            }, 2000);
          }
        });
      });
  };
  return (
    <Dialog open={CancelDialog} onClose={CloseDialog} maxWidth="md" fullWidth>
      {LeaveList != null && (
        <>
          {LeaveList.filter((evl) => evl.leave_id === LeaveToDo).map((ev) => (
            <DialogTitle key={ev.eval_id} color={"#2f3193"}>
              Cancel {ev.leave_type} From{" "}
              <span className="dateview">{ev.fd}</span> to{" "}
              <span className="dateview">{ev.ed}</span>
            </DialogTitle>
          ))}
        </>
      )}
      <DialogContent>
        {ShowDiaCont ? (
          <Box sx={{ mt: 1 }}>
            <Grid container spacing={3}>
              {LeaveList != null && (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    {LeaveList.filter((evl) => evl.leave_id === LeaveToDo).map(
                      (ev) => (
                        <Alert
                          key={ev.eval_id}
                          severity="warning"
                          variant="outlined"
                        >
                          Are you sure you want to Cancel the {ev.leave_type}{" "}
                          from <span className="bolder">{ev.fd}</span> to{" "}
                          <span className="bolder">{ev.ed}</span> for{" "}
                          <span className="bolder">{ev.reason}</span> ?
                        </Alert>
                      )
                    )}
                  </Grid>
                </>
              )}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: "right" }}
              >
                <Button
                  variant="contained"
                  color="success"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={DeleteLeave}
                >
                  Yes
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  color="error"
                  sx={{ mt: 3, mb: 2, ml: 2 }}
                  onClick={CloseDialog}
                >
                  No
                </Button>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box sx={{ py: 5, textAlign: "center" }}>
            <GridLoader />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
