import { AppBar, Paper } from "@mui/material";
export default function TopBar(props) {
  const SetYear = new Date().getFullYear();
  return (
    <Paper>
      <AppBar position="fixed" color="inherit" sx={{ top: "auto", bottom: 0 }}>
        <p className="text-soc text-small footercontianer">
          © Copyright Stars Orbit {SetYear}, All rights reserved.
          <br />
          Powered By{" "}
          <a href="https://minassa.tech" target="_blanck" className="text-soc">
            Minassa Solutions.
          </a>
        </p>
      </AppBar>
    </Paper>
  );
}
