import { Paper, Grid, TextField, Typography, MenuItem } from "@mui/material";
import { EmployeesStore } from "../../../../Store/";
import { APIURL } from "../../../../Context/configs";
import { useState, useEffect } from "react";

export default function SalaryInfo() {
  const [DangerPay, SetDangerPay] = useState([]);
  const [PaymentType, SetPaymentType] = useState([]);
  const EmpData = EmployeesStore((state) => state.EmpData);
  useEffect(() => {
    fetch(APIURL + "organization/employees/employeedangerpay.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetDangerPay(result);
      });
    fetch(APIURL + "organization/employees/employeepaymenttype.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetPaymentType(result);
      });
  }, []);
  return (
    <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" color={"#2f3193"} sx={{ my: 2 }}>
        Salary Information
      </Typography>
      {EmpData.map((emp) => (
        <Grid key={emp.lid} container spacing={3}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <TextField
              id="bs"
              name="bs"
              type="nubmer"
              label="Base Salary"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.basesalary}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <TextField
              id="hd"
              name="hd"
              select
              label="Danger Pay"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.havedanger}
            >
              {DangerPay.map((c) => (
                <MenuItem key={c.dpt_id} value={c.dpt_id}>
                  {c.dpt}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <TextField
              id="dpta"
              name="dpta"
              type="nubmer"
              label="DP/TA"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.dpta}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <TextField
              id="communication"
              name="communication"
              type="nubmer"
              label="Communication"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.communication}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              id="hh"
              name="hh"
              select
              label="Health Insurance"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.havehealth}
            >
              <MenuItem value="1">Yes</MenuItem>
              <MenuItem value="0">No</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              id="hi"
              name="hi"
              type="nubmer"
              label="Health Insurance By Staff"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.hi}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              id="hiorg"
              name="hiorg"
              type="nubmer"
              label="Health Insurance By IOM"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.hiorg}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              id="havess"
              name="havess"
              label="Social Security"
              variant="standard"
              select
              fullWidth
              disabled
              defaultValue={emp.havess}
            >
              <MenuItem value="1">Yes</MenuItem>
              <MenuItem value="0">No</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              id="ssemp"
              name="ssemp"
              type="nubmer"
              label="Social Security By Staff"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.ssemp}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              id="ssiom"
              name="ssiom"
              type="nubmer"
              label="Social Security By IOM"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.ssiom}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <TextField
              id="pit"
              name="pit"
              type="nubmer"
              label="Personal Income Taxes"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.pit}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <TextField
              id="extrafees"
              name="extrafees"
              type="nubmer"
              label="Extra Fees"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.extrafees}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <TextField
              id="otherpays"
              name="otherpays"
              type="nubmer"
              label="Other Payments"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.otherpays}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <TextField
              id="oc"
              name="oc"
              disabled
              type="nubmer"
              label="Occupational Insurance"
              variant="standard"
              fullWidth
              defaultValue={emp.occupotional}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="pt"
              name="pt"
              select
              label="Payment Type"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.payment}
            >
              {PaymentType.map((c) => (
                <MenuItem key={c.pt_id} value={c.pt_id}>
                  {c.pt}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="bankname"
              name="bankname"
              label="Bank Name"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.bankname}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              id="accnt1"
              name="accnt1"
              label="Account # 1"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.accnt1}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              id="accnt2"
              name="accnt2"
              label="Account # 2"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.accnt2}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              id="accnt3"
              name="accnt3"
              label="Account # 3"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.accnt3}
            />
          </Grid>
        </Grid>
      ))}
    </Paper>
  );
}
