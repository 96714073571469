import { Grid, Box, Typography, Chip, IconButton, Button } from "@mui/material";
import { EmpDataStore } from "../../../../Store";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CheckCircleOutlineTwoToneIcon from "@mui/icons-material/CheckCircleOutlineTwoTone";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import HourglassTopTwoToneIcon from "@mui/icons-material/HourglassTopTwoTone";
import FilePresentTwoToneIcon from "@mui/icons-material/FilePresentTwoTone";
import DoDisturbOnTwoToneIcon from "@mui/icons-material/DoDisturbOnTwoTone";
import DeleteForeverTwoToneIcon from "@mui/icons-material/DeleteForeverTwoTone";
export default function LeaveList() {
  const LeaveList = EmpDataStore((state) => state.LeaveList);
  const SetLeaveToDo = EmpDataStore((state) => state.SetLeaveToDo);
  const SetCancelDialog = EmpDataStore((state) => state.SetCancelDialog);
  const SetDeleteDialog = EmpDataStore((state) => state.SetDeleteDialog);
  const CancelAction = (p) => {
    SetLeaveToDo(p);
    SetCancelDialog();
  };
  const DeleteAction = (p) => {
    SetLeaveToDo(p);
    SetDeleteDialog();
  };
  const StatusShow = (param, param2) => {
    if (param === 1) {
      return (
        <Chip
          size="small"
          label={param2}
          color="success"
          icon={<CheckCircleOutlineTwoToneIcon fontSize="small" />}
        ></Chip>
      );
    } else if (param === 2) {
      return (
        <Chip
          size="small"
          label={param2}
          color="error"
          icon={<HighlightOffTwoToneIcon fontSize="small" />}
        ></Chip>
      );
    } else {
      return (
        <Chip
          size="small"
          label={param2}
          color="warning"
          icon={<HourglassTopTwoToneIcon fontSize="small" />}
        ></Chip>
      );
    }
  };
  const LeaveStat = (param) => {
    if (param === 1) {
      return (
        <Chip
          size="small"
          label="Requested"
          color="info"
          icon={<HourglassTopTwoToneIcon fontSize="small" />}
        ></Chip>
      );
    } else if (param === 2) {
      return (
        <Chip
          size="small"
          label="Approved"
          color="success"
          icon={<CheckCircleOutlineTwoToneIcon fontSize="small" />}
        ></Chip>
      );
    } else if (param === 3) {
      return (
        <Chip
          size="small"
          label="Canceling"
          color="warning"
          icon={<HourglassTopTwoToneIcon fontSize="small" />}
        ></Chip>
      );
    } else if (param === 4) {
      return (
        <Chip
          size="small"
          label="Canceled"
          color="error"
          icon={<CheckCircleOutlineTwoToneIcon fontSize="small" />}
        ></Chip>
      );
    } else {
      return (
        <Chip
          size="small"
          label="Declined"
          color="secondary"
          icon={<HighlightOffTwoToneIcon fontSize="small" />}
        ></Chip>
      );
    }
  };
  const ShowAction = (fps, hosos, lid) => {
    if (fps === 0 && hosos === 0) {
      return (
        <>
          <IconButton
            variant="contained"
            size="small"
            color="error"
            title="Delete Leave"
            onClick={() => DeleteAction(lid)}
          >
            <DeleteForeverTwoToneIcon fontSize="small" />
          </IconButton>
          <IconButton
            variant="contained"
            size="small"
            color="warning"
            title="Cancel Leave"
            onClick={() => CancelAction(lid)}
          >
            <DoDisturbOnTwoToneIcon fontSize="small" />
          </IconButton>
        </>
      );
    } else {
      return (
        <IconButton
          variant="contained"
          size="small"
          color="warning"
          title="Cancel Leave"
          onClick={() => CancelAction(lid)}
        >
          <DoDisturbOnTwoToneIcon fontSize="small" />
        </IconButton>
      );
    }
  };
  const EmpLeaveCol2 = [
    {
      field: "leave_type",
      headerName: "Leave Type",
      sortable: true,
      width: 150,
    },
    {
      field: "fd",
      headerName: "From",
      sortable: true,
      width: 100,
    },
    {
      field: "ed",
      headerName: "To",
      sortable: false,
      width: 100,
    },
    {
      field: "nodays",
      headerName: "Days",
      sortable: false,
      width: 60,
    },
    {
      field: "supervisor",
      headerName: "Supervisor",
      sortable: false,
      width: 175,
      renderCell: (params) => (
        <>{StatusShow(params.row.sv_status, params.row.sv_name)}</>
      ),
    },
    {
      field: "hoso",
      headerName: "Second Level Supervisor",
      sortable: false,
      width: 175,
      renderCell: (params) => (
        <>
          {params.row.with_hoso === 1 ? (
            <>{StatusShow(params.row.hoso_status, params.row.hoso_name)}</>
          ) : (
            <>{StatusShow(params.row.sv_status, params.row.sv_name)}</>
          )}
        </>
      ),
    },
    {
      field: "rpt",
      headerName: "Report",
      sortable: false,
      width: 90,
      renderCell: (params) => (
        <>
          {params.row.has_report == 1 && (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              href={params.row.report}
              target="_blank"
              title="View Report"
            >
              <FilePresentTwoToneIcon fontSize="small" />
            </Button>
          )}
        </>
      ),
    },
    {
      field: "status",
      headerName: "Stage",
      sortable: false,
      width: 120,
      renderCell: (params) => <>{LeaveStat(params.row.status)}</>,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 150,
      renderCell: (params) => (
        <>
          {ShowAction(
            params.row.sv_status,
            params.row.hoso_status,
            params.row.leave_id
          )}
        </>
      ),
    },
  ];
  return (
    <Grid container spacing={3} sx={{ mt: 2 }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h6" color={"#2f3193"}>
          Leaves List
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box>
          {LeaveList != null ? (
            <DataGrid
              rows={LeaveList}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              columns={EmpLeaveCol2}
              pageSize={25}
              rowsPerPageOptions={[25]}
              disableSelectionOnClick
              autoHeight
              width={"100%"}
              disableColumnMenu
              density="compact"
              getRowId={(row) => row.leave_id}
            />
          ) : (
            <Box sx={{ p: 5, textAlign: "center" }}>
              <Typography variant="h6">No Data To Preview</Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
