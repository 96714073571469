import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Divider, Box, IconButton, Typography } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MenuSharpIcon from "@mui/icons-material/MenuSharp";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import DashboardSharpIcon from "@mui/icons-material/DashboardSharp";
import PeopleOutlineSharpIcon from "@mui/icons-material/PeopleOutlineSharp";
import PeopleAltSharpIcon from "@mui/icons-material/PeopleAltSharp";
import SupervisedUserCircleSharpIcon from "@mui/icons-material/SupervisedUserCircleSharp";
import MonetizationOnSharpIcon from "@mui/icons-material/MonetizationOnSharp";
import ForumSharpIcon from "@mui/icons-material/ForumSharp";
import StarHalfSharpIcon from "@mui/icons-material/StarHalfSharp";
import MedicationLiquidSharpIcon from "@mui/icons-material/MedicationLiquidSharp";
import SettingsSuggestSharpIcon from "@mui/icons-material/SettingsSuggestSharp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import InfoIcon from "@mui/icons-material/Info";
import RssFeedSharpIcon from "@mui/icons-material/RssFeedSharp";
import { NavLink } from "react-router-dom";
import { APIURL } from "../../Context/configs";
import Cookies from "js-cookie";
const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));
const DrawerBody = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
export default function Navbar(props) {
  const [NavOpen, SetNavOpen] = useState(false);
  const [adminData, SetadminData] = useState([]);
  useEffect(() => {
    fetch(APIURL + "manager/staff/viewstaff.php", {
      method: "post",
      body: JSON.stringify({
        id: Cookies.get("userRef"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetadminData(result);
      });
  }, []);

  const handleDrawer = () => {
    SetNavOpen(!NavOpen);
  };
  const p = props.priv;
  return (
    <DrawerBody variant="permanent" open={NavOpen}>
      <DrawerHeader>
        <IconButton onClick={handleDrawer} sx={{ mr: 1 }}>
          {NavOpen ? <CloseSharpIcon /> : <MenuSharpIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      {adminData.map((row) => (
        <Box key={row.adminid}>
          <NavLink className="navlink" to="/admin/dashboard" title="Dashboard">
            <DashboardSharpIcon fontSize="small" sx={{ mx: 1 }} />
            <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
              Dashboard
            </Typography>
          </NavLink>
          {row.staff == 1 && (
            <NavLink className="navlink" to="/admin/staff" title="SOC Staff">
              <PeopleOutlineSharpIcon fontSize="small" sx={{ mx: 1 }} />
              <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
                SOC Staff
              </Typography>
            </NavLink>
          )}
          {row.iom == 1 && (
            <NavLink
              className="navlink"
              to="/admin/iom"
              title="IOM HR Memebers"
            >
              <PeopleAltSharpIcon fontSize="small" sx={{ mx: 1 }} />
              <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
                IOM HR Memebers
              </Typography>
            </NavLink>
          )}
          {row.employees == 1 && (
            <NavLink
              className="navlink"
              to="/admin/employees"
              title="IOM Employees"
            >
              <SupervisedUserCircleSharpIcon fontSize="small" sx={{ mx: 1 }} />
              <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
                IOM Employees
              </Typography>
            </NavLink>
          )}
          {row.payroll == 1 && (
            <NavLink className="navlink" to="/admin/payroll" title="Payroll">
              <MonetizationOnSharpIcon fontSize="small" sx={{ mx: 1 }} />
              <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
                Payroll
              </Typography>
            </NavLink>
          )}
          {row.leaves == 1 && (
            <NavLink
              className="navlink"
              to="/admin/leaves"
              title="Leaves & Vacations"
            >
              <ForumSharpIcon fontSize="small" sx={{ mx: 1 }} />
              <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
                Leaves & Vacations
              </Typography>
            </NavLink>
          )}
          {row.evaluations == 1 && (
            <NavLink
              className="navlink"
              to="/admin/evaluation"
              title="Evaluations"
            >
              <StarHalfSharpIcon fontSize="small" sx={{ mx: 1 }} />
              <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
                Evaluations
              </Typography>
            </NavLink>
          )}
          <NavLink
            className="navlink"
            to="/admin/healtinsurance"
            title=" Health Insurance"
          >
            <MedicationLiquidSharpIcon fontSize="small" sx={{ mx: 1 }} />
            <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
              Health Insurance
            </Typography>
          </NavLink>
          {row.systemlogs == 1 && (
            <>
              <NavLink
                className="navlink"
                to="/admin/software"
                title="Software Data"
              >
                <RequestQuoteIcon fontSize="small" sx={{ mx: 1 }} />
                <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
                  Software Data
                </Typography>
              </NavLink>
              <NavLink
                className="navlink"
                to="/admin/systemconfig"
                title="System Configuration"
              >
                <SettingsSuggestSharpIcon fontSize="small" sx={{ mx: 1 }} />
                <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
                  System Configuration
                </Typography>
              </NavLink>
            </>
          )}
          <NavLink className="navlink" to="/admin/account" title="My Profile">
            <AccountCircleIcon fontSize="small" sx={{ mx: 1 }} />
            <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
              My Profile
            </Typography>
          </NavLink>
          <NavLink className="navlink" to="/admin/new" title=" What's New">
            <RssFeedSharpIcon fontSize="small" sx={{ mx: 1 }} />
            <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
              What's New
            </Typography>
          </NavLink>
        </Box>
      ))}
    </DrawerBody>
  );
}
