import {
  Paper,
  Box,
  Typography,
  Grid,
  TextField,
  MenuItem,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useState } from "react";
import { EmpHistCol2 } from "../../../../Context/DataGrids";
import { EmpDataStore } from "../../../../Store";

export default function ShowHistory() {
  const HistoryList = EmpDataStore((state) => state.HistoryList);
  const [ItemsPerPage, SetItemsPerPage] = useState(10);
  const HandleChange = (e) => {
    SetItemsPerPage(e.target.value);
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6" color={"#2f3193"}>
        Job History
      </Typography>
      <Paper elevation={3} sx={{ p: 2, my: 2 }}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ textAlign: "right" }}
          >
            <TextField
              select
              size="small"
              label="Rows Per Page"
              defaultValue={ItemsPerPage}
              onChange={HandleChange}
              sx={{ width: "150px" }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box sx={{ width: "100%" }}>
              <DataGrid
                rows={HistoryList}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                  },
                }}
                columns={EmpHistCol2}
                pageSize={ItemsPerPage}
                rowsPerPageOptions={[ItemsPerPage]}
                disableSelectionOnClick
                autoHeight
                width={"100%"}
                disableColumnMenu
                density="compact"
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
