import { Container, Grid, Breadcrumbs, Typography, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { RingLoader } from "../../Common/Loaders";
import { LeaveImporter, LeaveUpdater } from "../Elements/Leaves";
export default function Leaves() {
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    document.title = "StarsOrbit HRM - Leaves & Vacations";
    setisLoading(true);
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  }, []);
  return (
    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink className="breabcrumblink" to="/admin">
              Admin Panel
            </NavLink>
            <Typography color="text.primary">Leaves & Vacations</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      {isLoading ? (
        <Paper elevation={3} className="siteLoader" sx={{ mb: 3, mt: 3 }}>
          <RingLoader />
        </Paper>
      ) : (
        <LeaveImporter />
      )}
      {isLoading ? (
        <Paper elevation={3} className="siteLoader" sx={{ mb: 3, mt: 3 }}>
          <RingLoader />
        </Paper>
      ) : (
        <LeaveUpdater />
      )}
    </Container>
  );
}
