import { create } from "zustand";
const StaffStore = create((set) => ({
  Staff: [],
  StaffEditDialog: false,
  StaffAddDialog: false,
  StaffDeleteDialog: false,
  SnackBarShow: false,
  SnackBarMessage: "",
  StaffToHandle: 0,
  UpdateStaff: (stf) => set((state) => ({ Staff: stf })),
  SetStaffToHandle: (stf) => set((state) => ({ StaffToHandle: stf })),
  ChaneEditDialog: () =>
    set((state) => ({ StaffEditDialog: !state.StaffEditDialog })),
  ChaneAddDialog: () =>
    set((state) => ({ StaffAddDialog: !state.StaffAddDialog })),
  ChaneDeleteDialog: () =>
    set((state) => ({ StaffDeleteDialog: !state.StaffDeleteDialog })),
  ChaneSnackBarShow: () =>
    set((state) => ({ SnackBarShow: !state.SnackBarShow })),
  ChangeSnackMessage: (mes) => set((state) => ({ SnackBarMessage: mes })),
}));

export default StaffStore;
