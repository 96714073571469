import { Paper, Box, Typography, Grid } from "@mui/material";
import { EmployeesStore } from "../../../../Store";
export default function SalaryInfromation() {
  const EmpData = EmployeesStore((state) => state.EmpData);
  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6" color={"#2f3193"}>
        Salary Information
      </Typography>
      <Paper elevation={3} sx={{ p: 2, my: 2 }}>
        {EmpData.map((emp) => (
          <Grid container spacing={1.5} key={emp.lid}>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <Typography variant="body">
                Base Salary: <span className="bolder2">{emp.basesalary}</span> $
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <Typography variant="body">
                DP/TA: <span className="bolder2">{emp.dpta}</span> $
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <Typography variant="body">
                Communication:{" "}
                <span className="bolder2">{emp.communication}</span> $
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <Typography variant="body">
                Extra Fees : <span className="bolder2">{emp.extrafees}</span> $
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <Typography variant="body">
                Health Insurance: <span className="bolder2">{emp.hi}</span> $
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <Typography variant="body">
                Social Security: <span className="bolder2">{emp.ssemp}</span> $
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <Typography variant="body">
                Personal Income Taxes:{" "}
                <span className="bolder2">{emp.pit}</span> $
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <Typography variant="body">
                Occupational Insurance:{" "}
                <span className="bolder2">{emp.occupotional}</span> $
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <Typography variant="body">
                Other Payments :{" "}
                <span className="bolder2">{emp.otherpays}</span> $
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <Typography variant="body">
                Payment: <span className="bolder">{emp.bankname}</span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6}>
              <Typography variant="body">
                Account # : <span className="bolder">{emp.accnt1}</span>{" "}
                {emp.accnt2 != "" && (
                  <span className="bolder2">- {emp.accnt2}</span>
                )}{" "}
                {emp.accnt3 != "" && (
                  <span className="bolder2">- {emp.accnt3}</span>
                )}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Paper>
    </Box>
  );
}
