import { Container, Grid, Breadcrumbs, Typography, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { APIURL } from "../../../Context/configs";
import { HealthInsStore } from "../../../Store";
import { RingLoader } from "../../Common/Loaders";
import HiPage from "../Elements/HealthIns/HiPage";

export default function HealthIns() {
  const [isLoading, setisLoading] = useState(true);
  const SetInfo = HealthInsStore((state) => state.SetDataToShow);
  useEffect(() => {
    document.title = "StarsOrbit HRM - Health Insurance Info";
    fetch(APIURL + "portalhome/healthinsurance.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setisLoading(false);
        SetInfo(result);
      });
  }, []);
  return (
    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink className="breabcrumblink" to="/admin">
              Admin Panel
            </NavLink>
            <Typography color="text.primary">Health Insurance Info</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      {isLoading ? (
        <Paper elevation={3} className="siteLoader">
          <RingLoader />
        </Paper>
      ) : (
        <HiPage />
      )}
    </Container>
  );
}
