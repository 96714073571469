import { Grid, Typography, Paper, Box } from "@mui/material";
import EMPIMG from "../../../../Assets/IMG/Employees.png";
import { dashBoardStore } from "../../../../Store";
export default function DashWidgets() {
  const WID = dashBoardStore((state) => state.widgets);
  return (
    <>
      {WID.map((w) => (
        <Grid key="widlister" container spacing={1}>
          <Grid item xs={12} md={6} lg={3}>
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "#0d47a1",
                color: "#fff",
                px: 1,
                py: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <img src={EMPIMG} height="50" width="50" />
                </Box>
                <Box sx={{ flexGrow: 2, textAlign: "right" }}>
                  <Typography variant="h6">Total Employees</Typography>
                  <Typography>{w.TOTEMP}</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "#0d47a1",
                color: "#fff",
                px: 1,
                py: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <img src={EMPIMG} height="50" width="50" />
                </Box>
                <Box sx={{ flexGrow: 2, textAlign: "right" }}>
                  <Typography variant="h6">Active Employees</Typography>
                  <Typography>{w.TOTACT}</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "#0d47a1",
                color: "#fff",
                px: 1,
                py: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <img src={EMPIMG} height="50" width="50" />
                </Box>
                <Box sx={{ flexGrow: 2, textAlign: "right" }}>
                  <Typography variant="h6">Inactive Employees</Typography>
                  <Typography>{w.TOTIAC}</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "#0d47a1",
                color: "#fff",
                px: 1,
                py: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <img src={EMPIMG} height="50" width="50" />
                </Box>
                <Box sx={{ flexGrow: 2, textAlign: "right" }}>
                  <Typography variant="h6">Full Time</Typography>
                  <Typography>{w.TOTFT}</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "#0d47a1",
                color: "#fff",
                px: 1,
                py: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <img src={EMPIMG} height="50" width="50" />
                </Box>
                <Box sx={{ flexGrow: 2, textAlign: "right" }}>
                  <Typography variant="h6">Full Time - Int</Typography>
                  <Typography>{w.TOTFTINT}</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "#0d47a1",
                color: "#fff",
                px: 1,
                py: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <img src={EMPIMG} height="50" width="50" />
                </Box>
                <Box sx={{ flexGrow: 2, textAlign: "right" }}>
                  <Typography variant="h6">Part Time</Typography>
                  <Typography>{w.TOTPT}</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "#0d47a1",
                color: "#fff",
                px: 1,
                py: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <img src={EMPIMG} height="50" width="50" />
                </Box>
                <Box sx={{ flexGrow: 2, textAlign: "right" }}>
                  <Typography variant="h6">Daily Contract</Typography>
                  <Typography>{w.TOTDC}</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "#0d47a1",
                color: "#fff",
                px: 1,
                py: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <img src={EMPIMG} height="50" width="50" />
                </Box>
                <Box sx={{ flexGrow: 2, textAlign: "right" }}>
                  <Typography variant="h6">Hourly Contract</Typography>
                  <Typography>{w.TOTHC}</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "#0d47a1",
                color: "#fff",
                px: 1,
                py: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <img src={EMPIMG} height="50" width="50" />
                </Box>
                <Box sx={{ flexGrow: 2, textAlign: "right" }}>
                  <Typography variant="h6">Onetime Payment</Typography>
                  <Typography>{w.TOTOTP}</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "#0d47a1",
                color: "#fff",
                px: 1,
                py: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <img src={EMPIMG} height="50" width="50" />
                </Box>
                <Box sx={{ flexGrow: 2, textAlign: "right" }}>
                  <Typography variant="h6">Home Based</Typography>
                  <Typography>{w.TOTHB}</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "#0d47a1",
                color: "#fff",
                px: 1,
                py: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <img src={EMPIMG} height="50" width="50" />
                </Box>
                <Box sx={{ flexGrow: 2, textAlign: "right" }}>
                  <Typography variant="h6">Bank Payment</Typography>
                  <Typography>{w.TOTBANK}</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "#0d47a1",
                color: "#fff",
                px: 1,
                py: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <img src={EMPIMG} height="50" width="50" />
                </Box>
                <Box sx={{ flexGrow: 2, textAlign: "right" }}>
                  <Typography variant="h6">Cash Payment</Typography>
                  <Typography>{w.TOTCASH}</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      ))}
    </>
  );
}
