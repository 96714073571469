import {
  Paper,
  Grid,
  Typography,
  Box,
  TextField,
  MenuItem,
} from "@mui/material";
import { EmployeesStore } from "../../../../Store/";
import { APIURL } from "../../../../Context/configs";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { EvaluatedCol } from "../../../../Context/DataGrids";
import HTMLReactParser from "html-react-parser";
export default function Evaluations() {
  const [EV, SetEV] = useState([]);
  const [Obj, SetObj] = useState([]);
  const EmpData = EmployeesStore((state) => state.EmpData);
  const [ItemsPerPage, SetItemsPerPage] = useState(10);
  useEffect(() => {
    fetch(APIURL + "manager/employees/employeeobjectives.php", {
      method: "post",
      body: JSON.stringify({
        soc: EmpData[0].ref,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetObj(result);
      });
    fetch(APIURL + "manager/employees/employeeevaluation.php", {
      method: "post",
      body: JSON.stringify({
        soc: EmpData[0].ref,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetEV(result);
      });
  }, []);
  const HandleChange = (e) => {
    SetItemsPerPage(e.target.value);
  };
  return (
    <>
      <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
        <Typography variant="h6" color={"#2f3193"} sx={{ my: 2 }}>
          Employee Ojectives
        </Typography>
        <Grid container spacing={3}>
          {Obj.map((o) => (
            <Grid key={o.soc_ref} item xs={12} sm={12} md={12} lg={12}>
              {o.objectives === null ? (
                <Typography variant="h6" sx={{ textAlign: "center", my: 3 }}>
                  No Objectives Applied By Employee
                </Typography>
              ) : (
                <Box
                  sx={{
                    my: 2,
                    p: 2,
                    border: "1px #333 solid",
                    borderRadius: "3px",
                  }}
                >
                  {HTMLReactParser(o.objectives)}
                </Box>
              )}
            </Grid>
          ))}
        </Grid>
      </Paper>
      <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
        <Typography variant="h6" color={"#2f3193"} sx={{ my: 2 }}>
          Evaluation History
        </Typography>
        {EV != null ? (
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ textAlign: "right" }}
            >
              <TextField
                select
                size="small"
                label="Rows Per Page"
                defaultValue={ItemsPerPage}
                onChange={HandleChange}
                sx={{ width: "150px" }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box sx={{ width: "100%" }}>
                <DataGrid
                  getRowId={(row) => row.eval_id}
                  rows={EV}
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 250 },
                    },
                  }}
                  columns={EvaluatedCol}
                  pageSize={ItemsPerPage}
                  rowsPerPageOptions={[ItemsPerPage]}
                  disableSelectionOnClick
                  autoHeight
                  width={"100%"}
                  disableColumnMenu
                  density="compact"
                />
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Box sx={{ p: 5, textAlign: "center" }}>
            <Typography variant="h6">No Data To Preview</Typography>
          </Box>
        )}
      </Paper>
    </>
  );
}
