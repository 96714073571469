import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
} from "@mui/material";
import { EmpDataStore, EmployeesStore } from "../../../../Store";
import { APIURL } from "../../../../Context/configs";
import { useEffect, useState } from "react";
import { GridLoader } from "../../../Common/Loaders";
import axios from "axios";
export default function ProfilePicDialog() {
  const EmpData = EmployeesStore((state) => state.EmpData);
  const SetEmpData = EmployeesStore((state) => state.SetEmpData);
  const PPDialog = EmpDataStore((state) => state.PPDialog);
  const SetSnackBarShow = EmpDataStore((state) => state.SetSnackBarShow);
  const SetPPDialog = EmpDataStore((state) => state.SetPPDialog);
  const SetSnackMessage = EmpDataStore((state) => state.SetSnackMessage);
  const SetSnackBarType = EmpDataStore((state) => state.SetSnackBarType);
  const SetLoadingShow = EmpDataStore((state) => state.SetLoadingShow);
  const SetCOMPLOAD = EmpDataStore((state) => state.SetCOMPLOAD);
  const [ShowDiaCont, SetShowDiaCont] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      SetShowDiaCont(true);
    }, 3000);
  }, []);
  const CloseDialog = () => {
    SetPPDialog();
  };
  const startUpload = (event) => {
    SetLoadingShow();
    const formData = new FormData();
    formData.append("f", event.target.files[0]);
    formData.append("soc", EmpData[0].ref);
    formData.append("dir", EmpData[0].empkey);
    axios
      .post(APIURL + "employee/documents/profilepicture.php", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(function (response) {
        response.data.forEach((item) => {
          SetSnackMessage(item.data);
          if (item.id === 1) {
            SetCOMPLOAD();
            SetSnackBarType("success");
            fetch(APIURL + "employee/general/listinformation.php", {
              method: "post",
              body: JSON.stringify({
                soc: EmpData[0].ref,
              }),
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Request-Headers": "Content-Type, Authorization",
                "Access-Control-Allow-Credentials": "true",
              },
            })
              .then((res) => res.json())
              .then((result) => {
                SetEmpData(result);
                SetCOMPLOAD();
              });
          } else {
            SetSnackBarType("error");
          }
          SetSnackBarShow();
          SetLoadingShow();
          CloseDialog();
        });
      });
  };
  return (
    <Dialog open={PPDialog} onClose={CloseDialog} maxWidth="md" fullWidth>
      <DialogTitle color={"#2f3193"}>Change Profile Picture</DialogTitle>
      <DialogContent>
        {ShowDiaCont ? (
          <Box sx={{ p: 3, textAlign: "center" }}>
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              type="file"
              size="small"
              id="rpt"
              onChange={startUpload}
              name="rpt"
            />
          </Box>
        ) : (
          <Box sx={{ py: 5, textAlign: "center" }}>
            <GridLoader />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
