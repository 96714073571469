import { Box, Container } from "@mui/material";
import { TopBar, BottomBar } from "../Common/Pagebars";
import Navbar from "./Navbar";
import { Routes, Route } from "react-router-dom";
import { userLoginStore } from "../../Store";
import { useEffect } from "react";
import { BASEURI } from "../../Context/configs";
import {
  Employees,
  Evaluation,
  EmployeeView,
  HealthIns,
  Payroll,
  WhatsNew,
  DashBoard,
  Account,
  SoftwareData,
  Tutorial,
} from "./Pages";
export default function OrganizationContainer() {
  const LoggedIn = userLoginStore((state) => state.isLoggedIn);
  const userRole = userLoginStore((state) => state.userRole);
  useEffect(() => {
    if (!LoggedIn) {
      window.location.href = BASEURI;
    } else {
      if (userRole != "iom") {
        window.location.href = BASEURI + userRole;
      }
    }
  }, [LoggedIn, userRole]);
  return (
    <Box sx={{ display: "flex", flexGrow: 0 }}>
      <Navbar />
      <Container maxWidth="auto" disableGutters>
        <TopBar title="Organization Control Panel" />
        <div className="sitContent">
          <Routes>
            <Route path="/" element={<DashBoard />} />
            <Route path="/dashboard" element={<DashBoard />} />
            <Route path="/employees" element={<Employees />} />
            <Route path="/employee/:eid/" element={<EmployeeView />} />
            <Route path="/payroll" element={<Payroll />} />
            <Route path="/evaluation" element={<Evaluation />} />
            <Route path="/healtinsurance" element={<HealthIns />} />
            <Route path="/software/" element={<SoftwareData />} />
            <Route path="/account" element={<Account />} />
            <Route path="/tutorial" element={<Tutorial />} />
            <Route path="/new" element={<WhatsNew />} />
          </Routes>
        </div>
        <BottomBar />
      </Container>
    </Box>
  );
}
