import { Grid, Paper, Box, TextField, MenuItem, Alert } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { SoftwareCols } from "../../../../Context/DataGrids";
import { useState } from "react";
import { SoftwareStore } from "../../../../Store";
export default function ViewPayroll() {
  const DataToShow = SoftwareStore((state) => state.DataToShow);
  const [ItemsPerPage, SetItemsPerPage] = useState(25);
  const HandleChange = (e) => {
    SetItemsPerPage(e.target.value);
  };
  return (
    <Paper elevation={3} sx={{ mt: 3, p: 2 }}>
      {DataToShow === null ? (
        <Box sx={{ mt: 2 }}>
          <Alert
            severity="info"
            variant="outlined"
            sx={{
              "& .MuiAlert-message": {
                textAlign: "center",
                width: "inherit",
              },
            }}
          >
            No Data To Preview
          </Alert>
        </Box>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <TextField
              select
              size="small"
              fullWidth
              label="Rows Per Page"
              defaultValue={ItemsPerPage}
              onChange={HandleChange}
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={200}>200</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}></Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box sx={{ width: "100%" }}>
              <DataGrid
                rows={DataToShow}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                  },
                }}
                columns={SoftwareCols}
                pageSize={ItemsPerPage}
                rowsPerPageOptions={[ItemsPerPage]}
                disableSelectionOnClick
                autoHeight
                width={"100%"}
                disableColumnMenu
                density="compact"
                getRowId={(row) => row.ref}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}
