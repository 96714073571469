import { Breadcrumbs, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { EmployeesStore } from "../../../../Store";
export default function BCForEmp() {
  const EmpData = EmployeesStore((state) => state.EmpData);
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <NavLink className="breabcrumblink" to="/admin">
        Admin Panel
      </NavLink>
      <NavLink className="breabcrumblink" to="/admin/employees">
        IOM Employees
      </NavLink>
      {EmpData.map((emp) => (
        <Typography key={emp.empkey} color="text.primary">
          {emp.fullname} (<span className="text-soc">{emp.ref}</span>)
        </Typography>
      ))}
    </Breadcrumbs>
  );
}
