import StarHalfSharpIcon from "@mui/icons-material/StarHalfSharp";
import { Paper, Typography } from "@mui/material";
import { EvaluationProceessStore } from "../../../../Store";
export default function EvaluatoinHeader() {
  const Eval = EvaluationProceessStore((state) => state.Eval);
  return (
    <Paper
      elevation={2}
      sx={{
        backgroundColor: "#333",
        px: 1,
        py: 3,
        display: "flex",
        alignItems: "center",
      }}
    >
      <StarHalfSharpIcon sx={{ mx: 3, color: "#fff" }} />
      <Typography variant="h6" color={"#fff"}>
        Evaluation Of ({Eval[0].staff_no}) {Eval[0].fullname}
      </Typography>
    </Paper>
  );
}
