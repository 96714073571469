import {
  Paper,
  Box,
  Grid,
  TextField,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import { LeaveProceessStore } from "../../../../Store";
import { APIURL } from "../../../../Context/configs";
import FilePresentTwoToneIcon from "@mui/icons-material/FilePresentTwoTone";
export default function FocalPoint() {
  const Leave = LeaveProceessStore((state) => state.Leave);
  const SetSnackBarShow = LeaveProceessStore((state) => state.SetSnackBarShow);
  const SetLoadingShow = LeaveProceessStore((state) => state.SetLoadingShow);
  const SetSnackMessage = LeaveProceessStore((state) => state.SetSnackMessage);
  const SetSnackBarType = LeaveProceessStore((state) => state.SetSnackBarType);
  const doapprove = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    fetch(APIURL + "portalhome/leave/cancelleave.php", {
      method: "post",
      body: JSON.stringify({
        lv: data.get("lv"),
        rema: data.get("rem"),
        acttype: 4,
        act: "focalpoint",
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetSnackMessage(item.content);
          if (item.id == 1) {
            SetSnackBarType("success");
          } else {
            SetSnackBarType("error");
          }
        });
        setTimeout(() => {
          SetSnackBarShow();
          SetLoadingShow();
        }, 2000);
      });
  };
  const doreject = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    fetch(APIURL + "portalhome/leave/cancelleave.php", {
      method: "post",
      body: JSON.stringify({
        lv: data.get("lv"),
        rema: data.get("rem"),
        acttype: 5,
        act: "focalpoint",
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetSnackMessage(item.content);
          if (item.id == 1) {
            SetSnackBarType("success");
          } else {
            SetSnackBarType("error");
          }
        });
        setTimeout(() => {
          SetSnackBarShow();
          SetLoadingShow();
        }, 2000);
      });
  };
  return (
    <Box>
      {Leave[0].has_report === 1 && (
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Leave Report
          </Typography>
          <Divider />
          <Button
            variant="contained"
            color="primary"
            size="small"
            fullWidth
            href={Leave[0].report}
            target="_blank"
            title="View Report"
          >
            <FilePresentTwoToneIcon />
          </Button>
        </Paper>
      )}
      <Paper elevation={3} sx={{ p: 2 }}>
        <Box component="form" onSubmit={doapprove}>
          <div className="d-none">
            <TextField value={Leave[0].leave_id} id="lv" name="lv" />
          </div>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Leave Cancellation Approval
          </Typography>
          <Divider />
          <Grid
            container
            spacing={1}
            sx={{
              mt: 1,
              mb: 2,
            }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                size="small"
                fullWidth
                id="rem"
                name="rem"
                required
                autoFocus
                label="Supervisor Remarks"
              />
            </Grid>
          </Grid>
          <Divider />
          <Box sx={{ textAlign: "end", my: 2 }}>
            <Button
              variant="contained"
              type="submit"
              fullWidth
              size="small"
              color="primary"
            >
              Approve Leave Cancellation
            </Button>
          </Box>
        </Box>
      </Paper>
      <Paper elevation={3} sx={{ p: 2, my: 2 }}>
        <Box component="form" onSubmit={doreject}>
          <div className="d-none">
            <TextField value={Leave[0].leave_id} id="lv" name="lv" />
          </div>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Leave Cancellation Decline
          </Typography>
          <Divider />
          <Grid
            container
            spacing={1}
            sx={{
              mt: 1,
              mb: 2,
            }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                size="small"
                fullWidth
                id="rem"
                name="rem"
                required
                autoFocus
                label="Focal Point Remarks"
              />
            </Grid>
          </Grid>
          <Divider />
          <Box sx={{ textAlign: "end", my: 2 }}>
            <Button
              variant="contained"
              type="submit"
              fullWidth
              size="small"
              color="error"
            >
              Decline Leave Cancellation
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
