import { Dialog, DialogContent, DialogTitle, Box } from "@mui/material";
import { EmpDataStore } from "../../../../Store";
import { useEffect, useState, Fragment } from "react";
import { GridLoader } from "../../../Common/Loaders";
import HTMLReactParser from "html-react-parser";
export default function FileViewer() {
  const FileList = EmpDataStore((state) => state.FileList);
  const FileTODO = EmpDataStore((state) => state.FileTODO);
  const CommentDialog = EmpDataStore((state) => state.CommentDialog);
  const SetCommentDialog = EmpDataStore((state) => state.SetCommentDialog);
  const [ShowDiaCont, SetShowDiaCont] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      SetShowDiaCont(true);
    }, 3000);
  }, []);
  const CloseDialog = () => {
    SetCommentDialog();
  };
  return (
    <Dialog open={CommentDialog} onClose={CloseDialog} maxWidth="md" fullWidth>
      {FileList != null && (
        <>
          {FileList.filter((fl) => fl.file_id === FileTODO).map((f) => (
            <DialogTitle key={f.file_id} color={"#2f3193"}>
              View File {f.filename}
            </DialogTitle>
          ))}
        </>
      )}
      <DialogContent>
        {ShowDiaCont ? (
          <Box sx={{ mt: 1, p: 2 }}>
            {FileList != null && (
              <>
                {FileList.filter((fl) => fl.file_id === FileTODO).map((f) => (
                  <Fragment key={f.file_id}>
                    {HTMLReactParser(f.fileviewer)}
                  </Fragment>
                ))}
              </>
            )}
          </Box>
        ) : (
          <Box sx={{ py: 5, textAlign: "center" }}>
            <GridLoader />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
