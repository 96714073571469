import {
  Paper,
  Grid,
  TextField,
  Typography,
  Box,
  Button,
  MenuItem,
  Divider,
} from "@mui/material";
import { EmployeesStore } from "../../../../Store/";
import Cookies from "js-cookie";
import { APIURL } from "../../../../Context/configs";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import { useState, useEffect } from "react";

export default function SalaryInfo() {
  const [DangerPay, SetDangerPay] = useState([]);
  const [PaymentType, SetPaymentType] = useState([]);
  const [ptfield, Setptfield] = useState(false);
  const EmpData = EmployeesStore((state) => state.EmpData);
  const SetEmpData = EmployeesStore((state) => state.SetEmpData);
  const ChaneSnackBarShow = EmployeesStore((state) => state.ChaneSnackBarShow);
  const SetLoadingShow = EmployeesStore((state) => state.SetLoadingShow);
  const ChangeSnackMessage = EmployeesStore(
    (state) => state.ChangeSnackMessage
  );
  useEffect(() => {
    fetch(APIURL + "manager/employees/employeedangerpay.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetDangerPay(result);
      });
    fetch(APIURL + "manager/employees/employeepaymenttype.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetPaymentType(result);
      });
  }, []);
  const UpdateInfo = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    fetch(APIURL + "manager/employees/employeesalarychange.php", {
      method: "post",
      body: JSON.stringify({
        soc: data.get("ref"),
        fn: data.get("fn"),
        bs: data.get("bs"),
        hd: data.get("hd"),
        dpta: data.get("dpta"),
        co: data.get("communication"),
        hh: data.get("hh"),
        hiemp: data.get("hi"),
        hiorg: data.get("hiorg"),
        hss: data.get("havess"),
        ssemp: data.get("ssemp"),
        ssorg: data.get("ssiom"),
        pit: data.get("pit"),
        oc: data.get("oc"),
        ef: data.get("extrafees"),
        op: data.get("otherpays"),
        pt: data.get("pt"),
        bn: data.get("bankname"),
        ac1: data.get("accnt1"),
        ac2: data.get("accnt2"),
        ac3: data.get("accnt3"),
        IDEN: Cookies.get("userID"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          ChangeSnackMessage(item.content);
          if (item.id == 1) {
            fetch(APIURL + "manager/employees/employeeinformation.php", {
              method: "post",
              body: JSON.stringify({
                empkey: data.get("eky"),
              }),
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Request-Headers": "Content-Type, Authorization",
                "Access-Control-Allow-Credentials": "true",
              },
            })
              .then((res) => res.json())
              .then((result) => {
                SetEmpData(result);
              });
            setTimeout(() => {
              SetLoadingShow();
              ChaneSnackBarShow();
            }, 3000);
          }
        });
      });
  };
  const HandlePaymentChange = (e) => {
    if (e.target.value === 1) {
      Setptfield(false);
    } else {
      Setptfield(true);
    }
  };
  return (
    <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" color={"#2f3193"} sx={{ my: 2 }}>
        Edit Salary Information
      </Typography>
      {EmpData.map((emp) => (
        <Box
          key={emp.lid}
          component="form"
          onSubmit={UpdateInfo}
          sx={{ mt: 1 }}
        >
          <Grid container spacing={3}>
            <div className="d-none">
              <TextField id="eky" name="eky" defaultValue={emp.empkey} />
              <TextField id="ref" name="ref" defaultValue={emp.ref} />
              <TextField id="fn" name="fn" defaultValue={emp.fullname} />
            </div>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="bs"
                name="bs"
                type="nubmer"
                label="Base Salary"
                variant="standard"
                fullWidth
                defaultValue={emp.basesalary}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="hd"
                name="hd"
                select
                label="Danger Pay"
                variant="standard"
                fullWidth
                defaultValue={emp.havedanger}
              >
                {DangerPay.map((c) => (
                  <MenuItem key={c.dpt_id} value={c.dpt_id}>
                    {c.dpt}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="dpta"
                name="dpta"
                type="nubmer"
                label="DP/TA"
                variant="standard"
                fullWidth
                defaultValue={emp.dpta}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="communication"
                name="communication"
                type="nubmer"
                label="Communication"
                variant="standard"
                fullWidth
                defaultValue={emp.communication}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                id="hh"
                name="hh"
                select
                label="Health Insurance"
                variant="standard"
                fullWidth
                defaultValue={emp.havehealth}
              >
                <MenuItem value="1">Yes</MenuItem>
                <MenuItem value="0">No</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                id="hi"
                name="hi"
                type="nubmer"
                label="Health Insurance By Staff"
                variant="standard"
                fullWidth
                defaultValue={emp.hi}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                id="hiorg"
                name="hiorg"
                type="nubmer"
                label="Health Insurance By IOM"
                variant="standard"
                fullWidth
                defaultValue={emp.hiorg}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                id="havess"
                name="havess"
                label="Social Security"
                variant="standard"
                select
                fullWidth
                defaultValue={emp.havess}
              >
                <MenuItem value="1">Yes</MenuItem>
                <MenuItem value="0">No</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                id="ssemp"
                name="ssemp"
                type="nubmer"
                label="Social Security By Staff"
                variant="standard"
                fullWidth
                defaultValue={emp.ssemp}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                id="ssiom"
                name="ssiom"
                type="nubmer"
                label="Social Security By IOM"
                variant="standard"
                fullWidth
                defaultValue={emp.ssiom}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="pit"
                name="pit"
                type="nubmer"
                label="Personal Income Taxes"
                variant="standard"
                fullWidth
                defaultValue={emp.pit}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="extrafees"
                name="extrafees"
                type="nubmer"
                label="Extra Fees"
                variant="standard"
                fullWidth
                defaultValue={emp.extrafees}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="otherpays"
                name="otherpays"
                type="nubmer"
                label="Other Payments"
                variant="standard"
                fullWidth
                defaultValue={emp.otherpays}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="oc"
                name="oc"
                type="nubmer"
                label="Occupational Insurance"
                variant="standard"
                fullWidth
                defaultValue={emp.occupotional}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                id="pt"
                name="pt"
                select
                label="Payment Type"
                variant="standard"
                fullWidth
                defaultValue={emp.payment}
                onChange={HandlePaymentChange}
              >
                {PaymentType.map((c) => (
                  <MenuItem key={c.pt_id} value={c.pt_id}>
                    {c.pt}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                id="bankname"
                name="bankname"
                label="Bank Name"
                variant="standard"
                fullWidth
                disabled={ptfield}
                defaultValue={emp.bankname}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                id="accnt1"
                name="accnt1"
                label="Account # 1"
                variant="standard"
                fullWidth
                disabled={ptfield}
                defaultValue={emp.accnt1}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                id="accnt2"
                name="accnt2"
                label="Account # 2"
                variant="standard"
                fullWidth
                disabled={ptfield}
                defaultValue={emp.accnt2}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                id="accnt3"
                name="accnt3"
                label="Account # 3"
                variant="standard"
                fullWidth
                disabled={ptfield}
                defaultValue={emp.accnt3}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ textAlign: "right" }}
            >
              <Button variant="outlined" color="success" type="submit">
                <SaveTwoToneIcon sx={{ mr: 2 }} />
                Save Information
              </Button>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Paper>
  );
}
