import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  Box,
  Typography,
  TextField,
  MenuItem,
} from "@mui/material";
import ReactQuill from "react-quill";
import { EmpDataStore, EmployeesStore } from "../../../../Store";
import { APIURL } from "../../../../Context/configs";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import { useEffect, useState } from "react";
import { GridLoader } from "../../../Common/Loaders";

export default function CommentEvaluation() {
  const [QuillText, SetQuillText] = useState("");
  const EmpData = EmployeesStore((state) => state.EmpData);
  const CommentDialog = EmpDataStore((state) => state.CommentDialog);
  const SetCommentDialog = EmpDataStore((state) => state.SetCommentDialog);
  const SetSnackMessage = EmpDataStore((state) => state.SetSnackMessage);
  const SetSnackBarType = EmpDataStore((state) => state.SetSnackBarType);
  const SetLoadingShow = EmpDataStore((state) => state.SetLoadingShow);
  const SetSnackBarShow = EmpDataStore((state) => state.SetSnackBarShow);
  const SetEvalList = EmpDataStore((state) => state.SetEvalList);
  const EvalToDo = EmpDataStore((state) => state.EvalToDo);
  const [ShowDiaCont, SetShowDiaCont] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      SetShowDiaCont(true);
    }, 3000);
  }, []);

  const CloseDialog = () => {
    SetCommentDialog();
  };
  const sendRequest = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    fetch(APIURL + "employee/evaluation/commentevaluation.php", {
      method: "post",
      body: JSON.stringify({
        ev: EvalToDo,
        ag: data.get("ag"),
        com: QuillText,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetSnackMessage(item.content);
          if (item.id === 1) {
            SetSnackBarType("success");
            setTimeout(() => {
              fetch(APIURL + "employee/evaluation/listevaluations.php", {
                method: "post",
                body: JSON.stringify({
                  soc: EmpData[0].ref,
                }),
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Request-Headers":
                    "Content-Type, Authorization",
                  "Access-Control-Allow-Credentials": "true",
                },
              })
                .then((res) => res.json())
                .then((result) => {
                  SetEvalList(result);
                  SetSnackBarShow();
                  SetLoadingShow();
                  CloseDialog();
                });
            }, 2000);
          } else {
            SetSnackBarType("error");
            setTimeout(() => {
              SetSnackBarShow();
              SetLoadingShow();
              CloseDialog();
            }, 2000);
          }
        });
      });
    SetQuillText("");
  };
  return (
    <Dialog open={CommentDialog} onClose={CloseDialog} maxWidth="md" fullWidth>
      <DialogTitle color={"#2f3193"}>
        Add My Comment <span className="dateview">(Staff)</span>
      </DialogTitle>
      <DialogContent>
        {ShowDiaCont ? (
          <Box
            component="form"
            onSubmit={sendRequest}
            noValidate
            sx={{ mt: 1 }}
          >
            <Grid container spacing={3}>
              <div className="d-none">
                <TextField id="ev" name="ev" defaultValue={EvalToDo} />
              </div>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  select
                  id="ag"
                  name="ag"
                  size="small"
                  fullWidth
                  label="Agreement"
                  defaultValue="I <b>AGREE</b> with the comments of my 1<sup>st</sup> and 2<sup>nd</sup> level supervisor"
                >
                  <MenuItem value="I <b>AGREE</b> with the comments of my 1<sup>st</sup> and 2<sup>nd</sup> level supervisor">
                    AGREE with the comments of my 1st and 2nd level supervisor
                  </MenuItem>
                  <MenuItem value="I <b>DO NOT AGREE</b> with the comments of my 1<sup>st</sup> and 2<sup>nd</sup> level supervisor">
                    I DO NOT AGREE with the comments of my 1st and 2nd level
                    supervisor
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="body" sx={{ fontWeight: "bold" }}>
                  My Comment
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <ReactQuill
                  theme="snow"
                  value={QuillText}
                  onChange={SetQuillText}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: "right" }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  sx={{ mt: 3, mb: 2 }}
                >
                  <SendTwoToneIcon fontSize="small" sx={{ mr: 1 }} />
                  Add Comment
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  color="error"
                  sx={{ mt: 3, mb: 2, ml: 2 }}
                  onClick={CloseDialog}
                >
                  Close Form
                </Button>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box sx={{ py: 5, textAlign: "center" }}>
            <GridLoader />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
