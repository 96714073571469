import { create } from "zustand";
const EmployeesStore = create((set) => ({
  EmpList: [],
  EmpData: [],
  EmpSOC: "",
  SnackBarShow: false,
  LoadingShow: false,
  SnackBarMessage: "",
  ChaneSnackBarShow: () =>
    set((state) => ({ SnackBarShow: !state.SnackBarShow })),
  ChangeSnackMessage: (mes) => set((state) => ({ SnackBarMessage: mes })),
  SetEmpList: (data) => set((state) => ({ EmpList: data })),
  SetEmpData: (data) => set((state) => ({ EmpData: data })),
  SetEmpSOC: (data) => set((state) => ({ EmpSOC: data })),
  SetLoadingShow: () => set((state) => ({ LoadingShow: !state.LoadingShow })),
}));

export default EmployeesStore;
