import { Paper, Box, Typography } from "@mui/material";
import { EvaluationProceessStore } from "../../../../Store";
import { APIURL } from "../../../../Context/configs";
import { useState, useEffect } from "react";
import { InEvLister } from "../../../../Context/DataGrids";
import { DataGrid } from "@mui/x-data-grid";

export default function OtherEvaluation() {
  const [evl, SetEvl] = useState([]);
  const Eval = EvaluationProceessStore((state) => state.Eval);
  useEffect(() => {
    fetch(APIURL + "employee/evaluation/listevaluations.php", {
      method: "post",
      body: JSON.stringify({
        soc: Eval[0].ref,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetEvl(result);
      });
  }, []);
  return (
    <Paper elevation={3} sx={{ p: 2, mt: 3 }}>
      <Typography variant="h6" className="dateview">
        Other Evaluations
      </Typography>
      <Box>
        <DataGrid
          getRowId={(row) => row.eval_id}
          rows={evl}
          columns={InEvLister}
          pageSize={25}
          rowsPerPageOptions={[25]}
          disableSelectionOnClick
          autoHeight
          width={"100%"}
          disableColumnMenu
          density="compact"
        />
      </Box>
    </Paper>
  );
}
