import { Paper, Button, Grid, Typography } from "@mui/material";
import MouseIcon from "@mui/icons-material/Mouse";
export default function TrainingHead() {
  return (
    <Paper elevation={3} sx={{ border: "1px #999 solid", mt: 4, mb: 4, p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h5" className="text-soc">
            Enrolment Keys
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Button
            href="/"
            target="_blank"
            color="error"
            variant="contained"
            fullWidth
          >
            <MouseIcon sx={{ mx: 2 }} />
            Click Here
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
