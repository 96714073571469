import {
  Paper,
  Grid,
  Button,
  Typography,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import { APIURL } from "../../../../Context/configs";
import Cookies from "js-cookie";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SyncIcon from "@mui/icons-material/Sync";
import { useState } from "react";
export default function LeaveUpdater() {
  const [SnackBarShow, SetSnackBarShow] = useState(false);
  const [SnackBarMessage, ChangeSnackMessage] = useState("");
  const ChaneSnackBarShow = () => {
    SetSnackBarShow(false);
  };
  const Add1Annual = () => {
    fetch(APIURL + "manager/leaves/leavedayannualadd.php", {
      method: "post",
      body: JSON.stringify({
        IDEN: Cookies.get("userID"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          ChangeSnackMessage(item.content);
          SetSnackBarShow(true);
        });
      });
  };
  const Add1Sick = () => {
    fetch(APIURL + "manager/leaves/leavedaysickadd.php", {
      method: "post",
      body: JSON.stringify({
        IDEN: Cookies.get("userID"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          ChangeSnackMessage(item.content);
          SetSnackBarShow(true);
        });
      });
  };
  const AddHalfAnnual = () => {
    fetch(APIURL + "manager/leaves/leavehalfdayannualadd.php", {
      method: "post",
      body: JSON.stringify({
        IDEN: Cookies.get("userID"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          ChangeSnackMessage(item.content);
          SetSnackBarShow(true);
        });
      });
  };
  const YearCycle = () => {
    fetch(APIURL + "manager/leaves/leavenewyear.php", {
      method: "post",
      body: JSON.stringify({
        IDEN: Cookies.get("userID"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          ChangeSnackMessage(item.content);
          SetSnackBarShow(true);
        });
      });
  };
  return (
    <>
      <Box sx={{ mt: 3, mb: 3 }}>
        <Typography variant="h5" color={"#2f3193"}>
          Update Balances Manually
        </Typography>
        <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Button
                variant="outlined"
                color="error"
                fullWidth
                size="small"
                onClick={AddHalfAnnual}
              >
                <AddBoxIcon sx={{ mr: 1 }} /> 0.5 day (Annual Leave)
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Button
                variant="outlined"
                color="error"
                fullWidth
                size="small"
                onClick={Add1Annual}
              >
                <AddBoxIcon sx={{ mr: 1 }} /> 1 day (Annual Leave)
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Button
                variant="outlined"
                color="error"
                fullWidth
                size="small"
                onClick={Add1Sick}
              >
                <AddBoxIcon sx={{ mr: 1 }} /> 1 day (Sick Leave)
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Button
                variant="outlined"
                color="error"
                fullWidth
                size="small"
                onClick={YearCycle}
              >
                <SyncIcon sx={{ mr: 1 }} /> Balance Cycle
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Snackbar
        open={SnackBarShow}
        autoHideDuration={3000}
        onClose={ChaneSnackBarShow}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity="info"
          variant="outlined"
          sx={{
            "& .MuiAlert-message": { textAlign: "center", width: "inherit" },
          }}
        >
          {SnackBarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
