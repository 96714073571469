import { BASEURI } from "../../../../Context/configs";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { dashBoardStore } from "../../../../Store";

export default function Last10Evals() {
  const L10EV = dashBoardStore((state) => state.l10Eval);
  return (
    <>
      {L10EV.length == 0 ? (
        <Typography variant="h6" sx={{ textAlign: "center", py: 3 }}>
          No Data To Preview
        </Typography>
      ) : (
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ flex: 2 }}>SOC/REF</TableCell>
                <TableCell sx={{ flex: 2 }}>Fullname</TableCell>
                <TableCell sx={{ flex: 1 }}>Request Date</TableCell>
                <TableCell sx={{ flex: 2 }}>Duty Station</TableCell>
                <TableCell sx={{ flex: 2 }}>Department</TableCell>
                <TableCell sx={{ flex: 1, textAlign: "center" }}>
                  View
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {L10EV.map((row) => (
                <TableRow key={row.eval_id}>
                  <TableCell>{row.ref}</TableCell>
                  <TableCell>{row.fullname}</TableCell>
                  <TableCell>{row.req_date}</TableCell>
                  <TableCell>{row.dutystation}</TableCell>
                  <TableCell>{row.department}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <a
                      href={BASEURI + "evaluation/" + row.eval_id + "/view"}
                      target="_blanck"
                      className="navlink"
                    >
                      <RemoveRedEyeIcon />
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
