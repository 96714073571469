import {
  Paper,
  Grid,
  TextField,
  MenuItem,
  Button,
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { APIURL, STORAGEAPI, FilterMonths } from "../../../../Context/configs";
import Cookies from "js-cookie";
import { read, utils } from "xlsx";
import { useState, useEffect } from "react";
import { FacebookLoader } from "../../../Common/Loaders";
import { SystemConfigStore } from "../../../../Store";
import { ImportCols } from "../../../../Context/DataGrids";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
export default function ImportPayroll() {
  const [ItemsPerPage, SetItemsPerPage] = useState(10);
  const ChaneSnackBarShow = SystemConfigStore(
    (state) => state.ChaneSnackBarShow
  );
  const ChangeSnackMessage = SystemConfigStore(
    (state) => state.ChangeSnackMessage
  );
  const SetLoadingShow = SystemConfigStore((state) => state.SetLoadingShow);
  const [DataToView, SetDataToView] = useState([]);
  const [isOpen, SetisOpen] = useState(false);
  const [ImportingLoader, SetImportingLoader] = useState(false);
  const SetYear = new Date().getFullYear();
  const SetMonth = new Date().toLocaleString("en-US", { month: "short" });
  const [YearsLister] = useState([]);
  useEffect(() => {
    YearsLister.splice(0, YearsLister.length);
    for (let i = parseInt(new Date().getFullYear()); i > 2019; i--) {
      YearsLister.push({ y: i });
    }
  }, [YearsLister]);
  const StartUpload = (event) => {
    if (event.target.value != "") {
      SetImportingLoader(true);
      const files = event.target.files;
      const f = files[0];
      const reader = new FileReader();
      reader.onload = function (event) {
        const data = event.target.result;
        let readedData = read(data, { type: "binary", cellDates: true });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];
        const dataParse = utils.sheet_to_json(ws, { header: 0 });
        SetDataToView(dataParse);
      };
      setTimeout(() => {
        reader.readAsBinaryString(f);
        SetImportingLoader(false);
        SetisOpen(true);
        event.target.value = "";
      }, 25000);
    }
  };
  const handleClose = () => {
    SetisOpen(false);
  };
  const ConfirmData = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    fetch(APIURL + "manager/payroll/payrollimport.php", {
      method: "post",
      body: JSON.stringify({
        iData: DataToView,
        iMonth: data.get("mf"),
        iYear: data.get("yf"),
        IDEN: Cookies.get("userID"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          ChangeSnackMessage(
            "TOTAL IMPORTED : " +
              item.TP +
              " , TOTAL NEW EMPLOYEES : " +
              item.TN +
              " , TOTAL UPDATED EMPLOYEES : " +
              item.TU
          );
          SetLoadingShow();
          handleClose();
          ChaneSnackBarShow();
        });
      });
  };
  const HandleChange = (e) => {
    SetItemsPerPage(e.target.value);
  };
  return (
    <>
      <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h5" color={"#2f3193"} sx={{ my: 2 }}>
              Import Bulk Data Sheet
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ textAlign: "right", pt: 2 }}
          >
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: "right" }}
              >
                <Button
                  href={STORAGEAPI + "templates/bulk.xlsx"}
                  target="_blank"
                  variant="outlined"
                  color="info"
                >
                  <CloudDownloadIcon sx={{ mr: 2 }} />
                  Download Template
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  size="small"
                  name="file"
                  type="file"
                  accept=".xlsx"
                  onChange={StartUpload}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {ImportingLoader && (
                  <Box sx={{ textAlign: "center", pt: 2, pb: 2 }}>
                    <Typography variant="h5" color={"#2f3193"} sx={{ m: 2 }}>
                      Reading Data ...
                    </Typography>
                    <FacebookLoader />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="xl" fullWidth>
        <Box component="form" onSubmit={ConfirmData} sx={{ mt: 1 }}>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} md={4} lg={4} sx={{ my: 1 }}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  label="Year"
                  name="yf"
                  id="yf"
                  defaultValue={SetYear}
                >
                  {YearsLister.map((y) => (
                    <MenuItem key={y.y} value={y.y}>
                      {y.y}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4} sx={{ my: 1 }}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  label="Month"
                  defaultValue={SetMonth}
                  name="mf"
                  id="mf"
                >
                  {FilterMonths.map((FM) => (
                    <MenuItem key={FM.value} value={FM.value}>
                      {FM.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item sm={12} xs={12} md={4} lg={4} sx={{ my: 1 }}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  label="Rows Per Page"
                  defaultValue={ItemsPerPage}
                  onChange={HandleChange}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <DataGrid
                  getRowId={(row) => row.ref}
                  rows={DataToView}
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 250 },
                    },
                  }}
                  columns={ImportCols}
                  pageSize={ItemsPerPage}
                  rowsPerPageOptions={[ItemsPerPage]}
                  disableSelectionOnClick
                  autoHeight
                  width={"100%"}
                  disableColumnMenu
                  density="compact"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="success" type="submit">
              Confirm Data
            </Button>
            <Button variant="contained" color="error" onClick={handleClose}>
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
