import { Paper, Box, Typography } from "@mui/material";
import { EvaluationProceessStore } from "../../../../Store";

export default function EvaluationData() {
  const Eval = EvaluationProceessStore((state) => state.Eval);
  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Box sx={{ my: 1 }}>
        <Typography variant="body2">
          Staff Name: <span className="dateview">{Eval[0].fullname}</span>
        </Typography>
      </Box>
      <Box sx={{ my: 1 }}>
        <Typography variant="body2">
          Staff #: <span className="dateview">{Eval[0].staff_no}</span>
        </Typography>
      </Box>
      <Box sx={{ my: 1 }}>
        <Typography variant="body2">
          Position: <span className="dateview">{Eval[0].jposition}</span>
        </Typography>
      </Box>
      <Box sx={{ my: 1 }}>
        <Typography variant="body2">
          Divison: <span className="dateview">{Eval[0].department}</span>
        </Typography>
      </Box>
      <Box sx={{ my: 1 }}>
        <Typography variant="body2">
          Duty Station: <span className="dateview">{Eval[0].dutystation}</span>
        </Typography>
      </Box>
      <Box sx={{ my: 1 }}>
        <Typography variant="body2">
          Contract Duration:{" "}
          <span className="dateview">{Eval[0].contract_duration}</span>
        </Typography>
      </Box>
    </Paper>
  );
}
