import { Box, Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { RingLoader } from "../../Common/Loaders";
import { BC, Loading, ActionMessage } from "../Elements/Common/";
import {
  ProfilePic,
  AccountInfo,
  EmpInfo,
  ProfilePicDialog,
} from "../Elements/GeneralInfo";

export default function GeneralInfo() {
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    document.title = "StarsOrbit HRM - General Information";
    setTimeout(() => {
      setisLoading(false);
    }, 2000);
  }, []);
  return (
    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
      <BC data="General Information" />
      {isLoading ? (
        <Box sx={{ py: 5, my: 5, textAlign: "center" }}>
          <RingLoader />
        </Box>
      ) : (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <ProfilePic />
              <AccountInfo />
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <EmpInfo />
            </Grid>
          </Grid>
          <ProfilePicDialog />
          <ActionMessage />
          <Loading />
        </>
      )}
    </Container>
  );
}
