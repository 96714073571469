import {
  Paper,
  Grid,
  TextField,
  Typography,
  Box,
  Button,
  MenuItem,
  Divider,
} from "@mui/material";
import { EmployeesStore } from "../../../../Store/";
import Cookies from "js-cookie";
import { APIURL } from "../../../../Context/configs";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import { useState, useEffect } from "react";

export default function JobInfo() {
  const [Contracts, SetContracts] = useState([]);
  const [Departments, SetDepartments] = useState([]);
  const [Hubs, SetHubs] = useState([]);
  const [DutyStations, SetDutyStations] = useState([]);
  const [StaffGroupiong, SetStaffGroupiong] = useState([]);
  const [Types, SetTypes] = useState([]);
  const [Programme, SetProgramme] = useState([]);
  const EmpData = EmployeesStore((state) => state.EmpData);
  const SetEmpData = EmployeesStore((state) => state.SetEmpData);
  const ChaneSnackBarShow = EmployeesStore((state) => state.ChaneSnackBarShow);
  const SetLoadingShow = EmployeesStore((state) => state.SetLoadingShow);
  const ChangeSnackMessage = EmployeesStore(
    (state) => state.ChangeSnackMessage
  );
  useEffect(() => {
    fetch(APIURL + "manager/employees/employeecontracts.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetContracts(result);
      });
    fetch(APIURL + "manager/employees/employeesprogramme.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetProgramme(result);
      });
    fetch(APIURL + "manager/employees/employeedepartments.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetDepartments(result);
      });
    fetch(APIURL + "manager/employees/employeedutystations.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetDutyStations(result);
      });
    fetch(APIURL + "manager/employees/employeehub.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetHubs(result);
      });
    fetch(APIURL + "manager/employees/employeestaffgrouping.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetStaffGroupiong(result);
      });
    fetch(APIURL + "manager/employees/employeetypes.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetTypes(result);
      });
  }, []);
  const UpdateInfo = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    fetch(APIURL + "manager/employees/employeejobchange.php", {
      method: "post",
      body: JSON.stringify({
        soc: data.get("ref"),
        fn: data.get("fn"),
        sd: data.get("sd"),
        ed: data.get("ed"),
        wh: data.get("wh"),
        hub: data.get("hub"),
        ds: data.get("ds"),
        dp: data.get("dep"),
        po: data.get("po"),
        gr: data.get("gr"),
        sg: data.get("sg"),
        ty: data.get("et"),
        pr: data.get("pr"),
        gl: data.get("gl"),
        act: data.get("act"),
        ct: data.get("ct"),
        IDEN: Cookies.get("userID"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          ChangeSnackMessage(item.content);
          if (item.id == 1) {
            fetch(APIURL + "manager/employees/employeeinformation.php", {
              method: "post",
              body: JSON.stringify({
                empkey: data.get("eky"),
              }),
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Request-Headers": "Content-Type, Authorization",
                "Access-Control-Allow-Credentials": "true",
              },
            })
              .then((res) => res.json())
              .then((result) => {
                SetEmpData(result);
              });
            setTimeout(() => {
              SetLoadingShow();
              ChaneSnackBarShow();
            }, 3000);
          }
        });
      });
  };
  const UpdateSuperVisor = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    fetch(APIURL + "manager/employees/employeesupervisorchange.php", {
      method: "post",
      body: JSON.stringify({
        soc: data.get("ref"),
        fn: data.get("fn"),
        sv: data.get("svn"),
        sve: data.get("sve"),
        hos: data.get("hosn"),
        hose: data.get("hose"),
        IDEN: Cookies.get("userID"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          ChangeSnackMessage(item.content);
          if (item.id == 1) {
            ChaneSnackBarShow();
            fetch(APIURL + "manager/employees/employeeinformation.php", {
              method: "post",
              body: JSON.stringify({
                empkey: data.get("eky"),
              }),
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Request-Headers": "Content-Type, Authorization",
                "Access-Control-Allow-Credentials": "true",
              },
            })
              .then((res) => res.json())
              .then((result) => {
                SetEmpData(result);
              });
          }
        });
      });
  };
  return (
    <>
      <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
        <Typography variant="h6" color={"#2f3193"} sx={{ my: 2 }}>
          Edit Job Information
        </Typography>
        {EmpData.map((emp) => (
          <Box
            key={emp.lid}
            component="form"
            onSubmit={UpdateInfo}
            sx={{ mt: 1 }}
          >
            <Grid container spacing={3}>
              <div className="d-none">
                <TextField id="eky" name="eky" defaultValue={emp.empkey} />
                <TextField id="ref" name="ref" defaultValue={emp.ref} />
                <TextField id="fn" name="fn" defaultValue={emp.fullname} />
              </div>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  id="ct"
                  name="ct"
                  select
                  label="Contract Type"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.contract}
                >
                  {Contracts.map((c) => (
                    <MenuItem key={c.con_id} value={c.con_id}>
                      {c.ct}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  id="sd"
                  name="sd"
                  type="date"
                  label="Start Date"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.startdate}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  id="ed"
                  name="ed"
                  type="date"
                  label="End Date"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.enddate}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  id="wh"
                  name="wh"
                  label="Working Hours"
                  type="number"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.workinghours}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  id="hub"
                  name="hub"
                  select
                  label="Hub"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.hub}
                >
                  {Hubs.map((c, i) => (
                    <MenuItem key={i} value={c.entry_name}>
                      {c.entry_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  id="dep"
                  name="dep"
                  select
                  label="Divison"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.department}
                >
                  {Departments.map((c, i) => (
                    <MenuItem key={i} value={c.entry_name}>
                      {c.entry_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  id="ds"
                  name="ds"
                  select
                  label="Duty Station"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.dutystation}
                >
                  {DutyStations.map((c, i) => (
                    <MenuItem key={i} value={c.entry_name}>
                      {c.entry_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  id="po"
                  name="po"
                  label="Position"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.jposition}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  id="gl"
                  name="gl"
                  label="General Liability"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.generalliab}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  id="gr"
                  name="gr"
                  label="Grade"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.grade}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  id="sg"
                  name="sg"
                  select
                  label="Staff Grouping"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.staffgrouping}
                >
                  {StaffGroupiong.map((c, i) => (
                    <MenuItem key={i} value={c.entry_name}>
                      {c.entry_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  id="et"
                  name="et"
                  select
                  label="Type"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.type}
                >
                  {Types.map((c, i) => (
                    <MenuItem key={i} value={c.entry_name}>
                      {c.entry_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  id="pr"
                  name="pr"
                  select
                  label="Programme"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.programme}
                >
                  {Programme.map((c, i) => (
                    <MenuItem key={i} value={c.entry_name}>
                      {c.entry_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="act"
                  name="act"
                  label="Action"
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: "right" }}
              >
                <Button variant="outlined" color="success" type="submit">
                  <SaveTwoToneIcon sx={{ mr: 2 }} />
                  Save Information
                </Button>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Paper>
      <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
        <Typography variant="h6" color={"#2f3193"} sx={{ my: 2 }}>
          Edit Supervisor Information
        </Typography>
        {EmpData.map((emp) => (
          <Box
            key={emp.lid}
            component="form"
            onSubmit={UpdateSuperVisor}
            sx={{ mt: 1 }}
          >
            <Grid container spacing={3}>
              <div className="d-none">
                <TextField id="eky1" name="eky" defaultValue={emp.empkey} />
                <TextField id="ref1" name="ref" defaultValue={emp.ref} />
                <TextField id="fn1" name="fn" defaultValue={emp.fullname} />
              </div>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="svn"
                  name="svn"
                  label="Supervisor Name"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.supervisor}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="sve"
                  name="sve"
                  label="Supervisor Email"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.svemail}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="hosn"
                  name="hosn"
                  label="Second Level Supervisor Name"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.hos}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="hose"
                  name="hose"
                  label="Second Level Supervisor Email"
                  variant="standard"
                  fullWidth
                  defaultValue={emp.hosemal}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: "right" }}
              >
                <Button variant="outlined" color="success" type="submit">
                  <SaveTwoToneIcon sx={{ mr: 2 }} />
                  Save Information
                </Button>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Paper>
    </>
  );
}
