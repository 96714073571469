import {
  Box,
  TextField,
  Typography,
  Button,
  Alert,
  Paper,
  Divider,
} from "@mui/material";
import { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { APIURL } from "../../../../Context/configs";
import { EllipsisLoader } from "../../../Common/Loaders";
export default function ResetForm() {
  const [isLoading, setisLoading] = useState(false);
  const [LoginResponse, setLoginResponse] = useState(false);
  const [LoginResponseText, setLoginResponseText] = useState("");
  const [DidSubmit, SetDidSubmit] = useState(false);
  const RedLink = useRef();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetDidSubmit(true);
    setisLoading(true);
    fetch(APIURL + "portalhome/userlogin/passwordreset.php", {
      method: "post",
      body: JSON.stringify({
        un: data.get("username"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          setLoginResponseText(item.content);
          if (item.ID === 1) {
            setLoginResponse(true);
          } else {
            setLoginResponse(false);
          }
          setisLoading(false);
        });
      });
  };

  return (
    <Box
      sx={{
        marginTop: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography variant="h5" sx={{ textAlign: "center", mb: 2 }}>
          Reset Password
        </Typography>
        <Divider />
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            size="small"
            label="User Name"
            name="username"
            autoComplete="username"
            autoFocus
          />
          <Box sx={{ textAlign: "center" }}>
            {DidSubmit ? (
              isLoading ? (
                <EllipsisLoader />
              ) : (
                <>
                  {LoginResponse ? (
                    <Alert severity="success">{LoginResponseText}</Alert>
                  ) : (
                    <Alert severity="error">{LoginResponseText}</Alert>
                  )}
                </>
              )
            ) : null}
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Reset Password
          </Button>
          <NavLink className="d-none" to="/" ref={RedLink}></NavLink>
          <Box sx={{ textAlign: "center" }}>
            <NavLink className="navlink3" to="/">
              Back To Login Page
            </NavLink>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
