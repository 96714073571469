import { create } from "zustand";
import Cookies from "js-cookie";
const userLoginStore = create((set) => ({
  isFirstRun: Cookies.get("NewRun"),
  setisFirstRun: () => set((state) => ({ isFirstRun: !state.isFirstRun })),
  isLoggedIn: Cookies.get("loggedIn"),
  setIsLoggedIn: () => set((state) => ({ isLoggedIn: !state.isLoggedIn })),
  userRole: Cookies.get("userArea"),
  setuserRole: (role) => set((state) => ({ userRole: role })),
  userRef: Cookies.get("userRef"),
  setuserRef: (ref) => set((state) => ({ userRef: ref })),
  userID: Cookies.get("userID"),
  setuserID: (id) => set((state) => ({ userID: id })),
}));

export default userLoginStore;
