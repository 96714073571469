import {
  Container,
  Grid,
  Breadcrumbs,
  Typography,
  Paper,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { APIURL } from "../../../Context/configs";
import { RingLoader } from "../../Common/Loaders";
import AddIcon from "@mui/icons-material/Add";
import { IOMStore } from "../../../Store";
import {
  IOMList,
  AddDialog,
  EditDialog,
  DeleteDialog,
  ActionMessage,
} from "../Elements/Iom";
export default function IOM() {
  const [isLoading, setisLoading] = useState(true);
  const SetIOM = IOMStore((state) => state.UpdateIOM);
  const AddDialogIOMCH = IOMStore((state) => state.ChaneAddDialog);
  useEffect(() => {
    document.title = "StarsOrbit HRM - IOM HR Memebers";
    fetch(APIURL + "manager/iom/iomlist.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setisLoading(false);
        SetIOM(result);
      });
  }, []);
  return (
    <>
      <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <NavLink className="breabcrumblink" to="/admin">
                Admin Panel
              </NavLink>
              <Typography color="text.primary">IOM HR Memebers</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ mb: 3, mt: 1 }}>
          <Grid item>
            <Typography variant="h5" color={"#2f3193"} sx={{ flex: 2 }}>
              IOM HR Memebers List
            </Typography>
          </Grid>
          <Grid item sx={{ textAlign: "right", flex: 1 }}>
            <Button variant="contained" onClick={AddDialogIOMCH}>
              <AddIcon /> Add New IOM HR
            </Button>
          </Grid>
        </Grid>
        {isLoading ? (
          <Paper elevation={3} className="siteLoader">
            <RingLoader />
          </Paper>
        ) : (
          <IOMList />
        )}
      </Container>
      <AddDialog />
      <EditDialog />
      <DeleteDialog />
      <ActionMessage />
    </>
  );
}
