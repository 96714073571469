import { Box, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { APIURL } from "../../../Context/configs";
import { EmpDataStore, EmployeesStore } from "../../../Store";
import { RingLoader } from "../../Common/Loaders";
import { BC, Loading, ActionMessage } from "../Elements/Common/";
import {
  RequestEvaluation,
  ListEvaluations,
  SecondLevel,
  CommentEvaluation,
  DeleteEvaluation,
  EvalNav,
  SkipHoso,
} from "../Elements/Evaluation";
export default function Evaluations() {
  const EmpData = EmployeesStore((state) => state.EmpData);
  const SetEvalList = EmpDataStore((state) => state.SetEvalList);
  const SetObjective = EmpDataStore((state) => state.SetObjective);
  const SetObjectiveApp = EmpDataStore((state) => state.SetObjectiveApp);
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    document.title = "StarsOrbit HRM - Evaluations";
    fetch(APIURL + "employee/evaluation/employeeobjectives.php", {
      method: "post",
      body: JSON.stringify({
        soc: EmpData[0].ref,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetObjective(item.objectives);
          SetObjectiveApp(item.approved);
        });
      });
    fetch(APIURL + "employee/evaluation/listevaluations.php", {
      method: "post",
      body: JSON.stringify({
        soc: EmpData[0].ref,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetEvalList(result);
      });
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  }, []);
  return (
    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
      <BC data="Evaluations" />
      {isLoading ? (
        <Box sx={{ py: 5, my: 5, textAlign: "center" }}>
          <RingLoader />
        </Box>
      ) : (
        <>
          <EvalNav />
          <RequestEvaluation />
          <ListEvaluations />
          <SecondLevel />
          <CommentEvaluation />
          <SkipHoso />
          <DeleteEvaluation />
          <ActionMessage />
          <Loading />
        </>
      )}
    </Container>
  );
}
