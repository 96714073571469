import { Paper } from "@mui/material";

export default function NewFeatures() {
  return (
    <Paper elevation={3} sx={{ mt: 3, p: 2 }}>
      <ul>
        <li className="newfeat">
          Updated the portal User Interface And User Experience components with
          google standards using Material Design Concepts and UI.
        </li>
        <li className="newfeat">
          Updated Leaves System with new leave types and new features.
        </li>
        <li className="newfeat">
          Employees is now notified by email if leave request is approved or
          cancelled.
        </li>
        <li className="newfeat">
          Updated Evaluation System with new features and procedure.
        </li>
        <li className="newfeat">
          Employees is now notified by email for each evaluation progress.
        </li>
        <li className="newfeat">
          Updated Admin Accessbility for some extra features and information.
        </li>
        <li className="newfeat">
          Redesigned the database and the dataflow for more speed and
          flexiblity.
        </li>
        <li className="newfeat">Fixing data request and response bugs.</li>
        <li className="newfeat">
          Version Control has been updated to 4.0.1 Stable.
        </li>
      </ul>
    </Paper>
  );
}
