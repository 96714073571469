import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  Box,
  Typography,
  TextField,
  Chip,
} from "@mui/material";
import { EmpDataStore, EmployeesStore } from "../../../../Store";
import { APIURL } from "../../../../Context/configs";
import HTMLReactParser from "html-react-parser";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import { useEffect, useState } from "react";
import { GridLoader } from "../../../Common/Loaders";
import CheckIcon from "@mui/icons-material/Check";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CloseIcon from "@mui/icons-material/Close";
export default function RequestEvaluation() {
  const EmpData = EmployeesStore((state) => state.EmpData);
  const Objective = EmpDataStore((state) => state.Objective);
  const ObjectiveApp = EmpDataStore((state) => state.ObjectiveApp);
  const RequestDialog = EmpDataStore((state) => state.RequestDialog);
  const SetRequestDialog = EmpDataStore((state) => state.SetRequestDialog);
  const SetSnackMessage = EmpDataStore((state) => state.SetSnackMessage);
  const SetSnackBarType = EmpDataStore((state) => state.SetSnackBarType);
  const SetLoadingShow = EmpDataStore((state) => state.SetLoadingShow);
  const SetSnackBarShow = EmpDataStore((state) => state.SetSnackBarShow);
  const SetEvalList = EmpDataStore((state) => state.SetEvalList);
  const [ShowDiaCont, SetShowDiaCont] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      SetShowDiaCont(true);
    }, 3000);
  }, []);

  const CloseDialog = () => {
    SetRequestDialog();
  };
  const ObjST = (param) => {
    if (param === 1) {
      return (
        <Chip
          label="Aprroved"
          color="primary"
          icon={<CheckIcon />}
          size="small"
          sx={{ fontSize: 10, px: 5, py: 2 }}
        />
      );
    } else if (param === 2) {
      return (
        <Chip
          label="Declined"
          color="error"
          icon={<CloseIcon />}
          size="small"
          sx={{ fontSize: 10, px: 5, py: 2 }}
        />
      );
    } else {
      return (
        <Chip
          label="Waiting Approval"
          color="warning"
          icon={<HourglassBottomIcon />}
          size="small"
          sx={{ fontSize: 10, px: 5, py: 2 }}
        />
      );
    }
  };
  const sendRequest = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    fetch(APIURL + "employee/evaluation/requestevaluation.php", {
      method: "post",
      body: JSON.stringify({
        obj: Objective,
        soc: data.get("soc"),
        fn: data.get("fullname"),
        sn: data.get("svn"),
        se: data.get("sve"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetSnackMessage(item.content);
          if (item.id === 1) {
            SetSnackBarType("success");
            setTimeout(() => {
              SetSnackBarShow();
              fetch(APIURL + "employee/evaluation/listevaluations.php", {
                method: "post",
                body: JSON.stringify({
                  soc: EmpData[0].ref,
                }),
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Request-Headers":
                    "Content-Type, Authorization",
                  "Access-Control-Allow-Credentials": "true",
                },
              })
                .then((res) => res.json())
                .then((result) => {
                  SetEvalList(result);
                  SetLoadingShow();
                  CloseDialog();
                });
            }, 2000);
          } else {
            SetSnackBarType("error");
            setTimeout(() => {
              SetSnackBarShow();
              SetLoadingShow();
              CloseDialog();
            }, 2000);
          }
        });
      });
  };
  return (
    <Dialog open={RequestDialog} onClose={CloseDialog} maxWidth="lg" fullWidth>
      <DialogTitle color={"#2f3193"}>
        Request Evaluation <span className="dateview">(Supervisor)</span>
      </DialogTitle>
      <DialogContent>
        {ShowDiaCont ? (
          <Box
            component="form"
            onSubmit={sendRequest}
            noValidate
            sx={{ mt: 1 }}
          >
            {EmpData.map((e) => (
              <Grid key={e.lid} container spacing={3}>
                <div className="d-none">
                  <TextField id="soc" name="soc" defaultValue={e.ref} />
                  <TextField
                    id="fullname"
                    name="fullname"
                    defaultValue={e.fullname}
                  />
                </div>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <TextField
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    size="small"
                    id="svn"
                    label="Supervisor Name"
                    name="svn"
                    autoComplete="svn"
                    autoFocus
                    defaultValue={e.supervisor}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <TextField
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    size="small"
                    id="sve"
                    label="Supervisor Email"
                    name="sve"
                    autoComplete="sve"
                    defaultValue={e.svemail}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="body" sx={{ fontWeight: "bold" }}>
                    My Objectives ({ObjST(ObjectiveApp)})
                  </Typography>
                  {Objective != null ? (
                    <Box
                      sx={{
                        p: 2,
                        mt: 2,
                      }}
                    >
                      {HTMLReactParser(Objective)}

                      <></>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        p: 2,
                        mt: 2,
                      }}
                    >
                      Please Set Your Objectives
                    </Box>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ textAlign: "right" }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    <SendTwoToneIcon fontSize="small" sx={{ mr: 1 }} />
                    Send Request
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="error"
                    sx={{ mt: 3, mb: 2, ml: 2 }}
                    onClick={CloseDialog}
                  >
                    Close Form
                  </Button>
                </Grid>
              </Grid>
            ))}
          </Box>
        ) : (
          <Box sx={{ py: 5, textAlign: "center" }}>
            <GridLoader />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
