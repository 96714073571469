import { Box } from "@mui/material";
import { EmployeesStore } from "../../../../Store/";
import { Img } from "react-image";
import { EllipsisLoader } from "../../../Common/Loaders";
import defaultImage from "../../../../Assets/IMG/nophoto.jpg";
export default function ProfilePic() {
  const EmpData = EmployeesStore((state) => state.EmpData);
  return (
    <Box sx={{ m: 5, textAlign: "center" }}>
      <Img
        className="profilepic"
        src={[EmpData[0].profilepicture, defaultImage]}
        loader={<EllipsisLoader />}
      />
    </Box>
  );
}
