import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
  Grid,
  Button,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Alert,
} from "@mui/material";
import Cookies from "js-cookie";
import { useState } from "react";
import { IOMStore } from "../../../../Store";
import md5 from "md5";
import { APIURL } from "../../../../Context/configs";
export default function AddDialog() {
  const UpdateStaff = IOMStore((state) => state.UpdateIOM);
  const ChaneAddDialog = IOMStore((state) => state.ChaneAddDialog);
  const ChaneSnackBarShow = IOMStore((state) => state.ChaneSnackBarShow);
  const ChangeSnackMessage = IOMStore((state) => state.ChangeSnackMessage);
  const StaffAddDialog = IOMStore((state) => state.IOMAddDialog);
  const [showError, SetshowError] = useState(false);
  const [showWarning, SetshowWarning] = useState(false);
  const AddNewHR = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (
      data.get("fullname") == "" ||
      data.get("email") == "" ||
      data.get("username") == "" ||
      data.get("password") == "" ||
      data.get("cpassword") == ""
    ) {
      SetshowError(true);
      SetshowWarning(false);
    } else if (md5(data.get("password")) != md5(data.get("cpassword"))) {
      SetshowWarning(true);
      SetshowError(false);
    } else {
      SetshowError(false);
      SetshowWarning(false);
      fetch(APIURL + "manager/iom/iomadd.php", {
        method: "post",
        body: JSON.stringify({
          fn: data.get("fullname"),
          ph: data.get("phone"),
          em: data.get("email"),
          un: data.get("username"),
          pw: md5(data.get("password")),
          cpw: md5(data.get("cpassword")),
          IDEN: Cookies.get("userID"),
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Request-Headers": "Content-Type, Authorization",
          "Access-Control-Allow-Credentials": "true",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          result.forEach((item) => {
            ChangeSnackMessage(item.content);
            if (item.id == 1) {
              ChaneSnackBarShow();
              ChaneAddDialog();
              fetch(APIURL + "manager/iom/iomlist.php", {
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Request-Headers":
                    "Content-Type, Authorization",
                  "Access-Control-Allow-Credentials": "true",
                },
              })
                .then((res) => res.json())
                .then((result) => {
                  UpdateStaff(result);
                });
            }
          });
        });
    }
  };
  const CloseDialog = () => {
    ChaneAddDialog();
    SetshowError(false);
    SetshowWarning(false);
  };
  return (
    <Dialog open={StaffAddDialog} onClose={CloseDialog} maxWidth="lg" fullWidth>
      <DialogTitle color={"#2f3193"}>Add New IOM HR Member</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={AddNewHR} noValidate sx={{ mt: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={6}>
              <Typography sx={{ fontWeight: "bold" }} color={"#2f3193"}>
                Personal info
              </Typography>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  variant="standard"
                  margin="normal"
                  required={true}
                  fullWidth
                  size="small"
                  id="fullname"
                  label="Fullname"
                  name="fullname"
                  autoComplete="fullname"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  variant="standard"
                  margin="normal"
                  required={true}
                  fullWidth
                  size="small"
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  variant="standard"
                  margin="normal"
                  fullWidth
                  size="small"
                  id="phone"
                  label="Phone Number"
                  name="phone"
                  autoComplete="phone"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Typography sx={{ fontWeight: "bold" }} color={"#2f3193"}>
                Account info
              </Typography>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  variant="standard"
                  margin="normal"
                  required={true}
                  fullWidth
                  size="small"
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  variant="standard"
                  margin="normal"
                  type="password"
                  required={true}
                  fullWidth
                  size="small"
                  id="password"
                  label="Password"
                  name="password"
                  autoComplete="password"
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  variant="standard"
                  margin="normal"
                  type="password"
                  required={true}
                  fullWidth
                  size="small"
                  id="cpassword"
                  label="Confirm Password"
                  name="cpassword"
                  autoComplete="cpassword"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ padding: 3, mt: 5 }}>
              {showError && (
                <Alert variant="outlined" severity="error">
                  Please fill the required fields marked with * to complete the
                  form submittion.
                </Alert>
              )}
              {showWarning && (
                <Alert variant="outlined" severity="warning">
                  Password and Password confirmation is not matched
                </Alert>
              )}
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ textAlign: "right" }}>
              <Button
                type="submit"
                variant="contained"
                color="success"
                sx={{ mt: 3, mb: 2 }}
              >
                Add IOM HR Member
              </Button>
              <Button
                type="button"
                variant="contained"
                color="error"
                sx={{ mt: 3, mb: 2, ml: 2 }}
                onClick={CloseDialog}
              >
                Close Form
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
