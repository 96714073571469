import { Box, Typography } from "@mui/material";

export default function FRHead() {
  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="body2">
        Please Insert a fully Working and active email address with fully
        working with Whatsapp account phone number.{" "}
        <Typography variant="body" sx={{ color: "#ff6f00" }}>
          all your leaves, evalutions and other features need active contact
          details
        </Typography>
        .
      </Typography>
    </Box>
  );
}
