import {
  Container,
  Grid,
  Paper,
  BottomNavigation,
  BottomNavigationAction,
  Box,
} from "@mui/material";
import { RingLoader, FacebookLoader } from "../../Common/Loaders";
import { useEffect, useState } from "react";
import { APIURL } from "../../../Context/configs";
import { EmployeesStore } from "../../../Store/";
import { useParams } from "react-router-dom";
import {
  BCForEmp,
  ProfilePic,
  Document,
  Evaluations,
  GeneralInfo,
  History,
  JobInfo,
  Leaves,
  Payroll,
  SalaryInfo,
  EmpStatus,
  ActionMessage,
  Loading,
} from "../Elements/Emloyees";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import BusinessCenterTwoToneIcon from "@mui/icons-material/BusinessCenterTwoTone";
import LocalAtmTwoToneIcon from "@mui/icons-material/LocalAtmTwoTone";
import ListAltTwoToneIcon from "@mui/icons-material/ListAltTwoTone";
import ViewListTwoToneIcon from "@mui/icons-material/ViewListTwoTone";
import FolderCopyTwoToneIcon from "@mui/icons-material/FolderCopyTwoTone";
import FileCopyTwoToneIcon from "@mui/icons-material/FileCopyTwoTone";
import StarTwoToneIcon from "@mui/icons-material/StarTwoTone";
export default function EmployeeView() {
  const [isLoading, setisLoading] = useState(true);
  const [isLoading2, setisLoading2] = useState(true);
  const SetEmpData = EmployeesStore((state) => state.SetEmpData);
  const SetEmpSOC = EmployeesStore((state) => state.SetEmpSOC);
  const [NavOption, setNavOption] = useState(0);
  const { eid } = useParams();
  useEffect(() => {
    document.title = "StarsOrbit HRM - SOC Staff";
    fetch(APIURL + "manager/employees/employeeinformation.php", {
      method: "post",
      body: JSON.stringify({
        empkey: eid,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetEmpData(result);
        result.forEach((item) => {
          SetEmpSOC(item.ref);
          setisLoading(false);
          setisLoading2(false);
        });
      });
  }, []);
  const ChangeTab = (newValue) => {
    setisLoading2(true);
    setNavOption(newValue);
    setTimeout(() => {
      setisLoading2(false);
    }, 3000);
  };
  const ComponentToShow = () => {
    if (NavOption === 0) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={3} lg={2}>
            <ProfilePic />
            <EmpStatus />
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={10}>
            <GeneralInfo />
          </Grid>
        </Grid>
      );
    } else if (NavOption === 1) {
      return <JobInfo />;
    } else if (NavOption === 2) {
      return <SalaryInfo />;
    } else if (NavOption === 3) {
      return <Payroll />;
    } else if (NavOption === 4) {
      return <History />;
    } else if (NavOption === 5) {
      return <Leaves />;
    } else if (NavOption === 6) {
      return <Evaluations />;
    }
  };
  return (
    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <BCForEmp />
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {isLoading ? (
            <Paper elevation={3} className="siteLoader" sx={{ mt: 2 }}>
              <RingLoader />
            </Paper>
          ) : (
            <Paper elevation={3} sx={{ p: 2 }}>
              <BottomNavigation
                showLabels
                value={NavOption}
                onChange={(event, newValue) => {
                  ChangeTab(newValue);
                }}
              >
                <BottomNavigationAction
                  label="Genral Info"
                  icon={<InfoTwoToneIcon />}
                />
                <BottomNavigationAction
                  label="Job Info"
                  icon={<BusinessCenterTwoToneIcon />}
                />
                <BottomNavigationAction
                  label="Salary Info"
                  icon={<LocalAtmTwoToneIcon />}
                />
                <BottomNavigationAction
                  label="Payroll"
                  icon={<ListAltTwoToneIcon />}
                />
                <BottomNavigationAction
                  label="History"
                  icon={<ViewListTwoToneIcon />}
                />
                <BottomNavigationAction
                  label="Leaves"
                  icon={<FileCopyTwoToneIcon />}
                />
                <BottomNavigationAction
                  label="Evaluations"
                  icon={<StarTwoToneIcon />}
                />
              </BottomNavigation>
            </Paper>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {isLoading2 ? (
            <Box className="siteLoader" sx={{ mt: 2, py: 5 }}>
              <FacebookLoader />
            </Box>
          ) : (
            <ComponentToShow />
          )}
        </Grid>
      </Grid>
      <ActionMessage />
      <Loading />
    </Container>
  );
}
