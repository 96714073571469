import { useEffect, useState, Fragment } from "react";
import { styled } from "@mui/material/styles";
import { Divider, List, IconButton, Typography } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MenuSharpIcon from "@mui/icons-material/MenuSharp";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import InfoSharpIcon from "@mui/icons-material/InfoSharp";
import PaidSharpIcon from "@mui/icons-material/PaidSharp";
import PlaylistAddCheckCircleSharpIcon from "@mui/icons-material/PlaylistAddCheckCircleSharp";
import CloudCircleSharpIcon from "@mui/icons-material/CloudCircleSharp";
import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import FilePresentSharpIcon from "@mui/icons-material/FilePresentSharp";
import StarHalfSharpIcon from "@mui/icons-material/StarHalfSharp";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import MedicationLiquidSharpIcon from "@mui/icons-material/MedicationLiquidSharp";
import CampaignSharpIcon from "@mui/icons-material/CampaignSharp";
import FlagCircleSharpIcon from "@mui/icons-material/FlagCircleSharp";
import SchoolIcon from "@mui/icons-material/School";
import InfoIcon from "@mui/icons-material/Info";
import RssFeedSharpIcon from "@mui/icons-material/RssFeedSharp";
import { NavLink } from "react-router-dom";
import { APIURL } from "../../Context/configs";
import Cookies from "js-cookie";
const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));
const DrawerBody = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
export default function Navbar(props) {
  const [NavOpen, SetNavOpen] = useState(false);
  const [CT, SETCT] = useState([]);
  useEffect(() => {
    fetch(APIURL + "employee/general/getContract.php", {
      method: "post",
      body: JSON.stringify({
        ref: Cookies.get("userRef"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SETCT(result);
      });
  }, []);
  const handleDrawer = () => {
    SetNavOpen(!NavOpen);
  };
  const p = props.priv;
  return (
    <DrawerBody variant="permanent" open={NavOpen}>
      <DrawerHeader>
        <IconButton onClick={handleDrawer} sx={{ mr: 1 }}>
          {NavOpen ? <CloseSharpIcon /> : <MenuSharpIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List className="text-small">
        <NavLink
          className="navlink"
          to="/employee/generalinfo"
          title="General Information"
        >
          <InfoSharpIcon fontSize="small" sx={{ mx: 1 }} />
          <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
            General Information
          </Typography>
        </NavLink>
        <NavLink
          className="navlink"
          to="/employee/payrollinfo"
          title="Payroll Information"
        >
          <PaidSharpIcon fontSize="small" sx={{ mx: 1 }} />
          <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
            Payroll Information
          </Typography>
        </NavLink>
        <NavLink
          className="navlink"
          to="/employee/historyinfo"
          title="Job History"
        >
          <PlaylistAddCheckCircleSharpIcon fontSize="small" sx={{ mx: 1 }} />
          <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
            Job History
          </Typography>
        </NavLink>
        <NavLink
          className="navlink"
          to="/employee/supervisors"
          title="Supervisors"
        >
          <AccountCircleSharpIcon fontSize="small" sx={{ mx: 1 }} />
          <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
            Supervisors
          </Typography>
        </NavLink>
        {CT.map((c) => (
          <Fragment key={c.contract}>
            {c.contract === 1 && (
              <NavLink
                className="navlink"
                to="/employee/leaves"
                title="Vacations & Leaves"
              >
                <FilePresentSharpIcon fontSize="small" sx={{ mx: 1 }} />
                <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
                  Vacations & Leaves
                </Typography>
              </NavLink>
            )}
            {c.contract === 6 && (
              <NavLink
                className="navlink"
                to="/employee/leaves"
                title="Vacations & Leaves"
              >
                <FilePresentSharpIcon fontSize="small" sx={{ mx: 1 }} />
                <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
                  Vacations & Leaves
                </Typography>
              </NavLink>
            )}
          </Fragment>
        ))}
        <NavLink
          className="navlink"
          to="/employee/objective"
          title="Objectives"
        >
          <BusinessCenterIcon fontSize="small" sx={{ mx: 1 }} />
          <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
            Objectives
          </Typography>
        </NavLink>
        <NavLink
          className="navlink"
          to="/employee/evaluation"
          title="Evaluation"
        >
          <StarHalfSharpIcon fontSize="small" sx={{ mx: 1 }} />
          <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
            Evaluation
          </Typography>
        </NavLink>
        <NavLink
          className="navlink"
          to="/employee/training"
          title="Mandatory Training"
        >
          <SchoolIcon fontSize="small" sx={{ mx: 1 }} />
          <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
            Mandatory Training
          </Typography>
        </NavLink>
        <NavLink
          className="navlink"
          to="/employee/healtinsurance"
          title="Health Insurance"
        >
          <MedicationLiquidSharpIcon fontSize="small" sx={{ mx: 1 }} />
          <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
            Health Insurance
          </Typography>
        </NavLink>
        <NavLink
          className="navlink"
          to="/employee/complains"
          title="Complain Application"
        >
          <CampaignSharpIcon fontSize="small" sx={{ mx: 1 }} />
          <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
            Complain Application
          </Typography>
        </NavLink>
        <NavLink className="navlink" to="/employee/reports" title="Bug Report">
          <FlagCircleSharpIcon fontSize="small" sx={{ mx: 1 }} />
          <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
            Bug Report
          </Typography>
        </NavLink>
        <NavLink className="navlink" to="/employee/new" title=" What's New">
          <RssFeedSharpIcon fontSize="small" sx={{ mx: 1 }} />
          <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
            What's New
          </Typography>
        </NavLink>
      </List>
    </DrawerBody>
  );
}
