import {
  Paper,
  Box,
  TextField,
  Divider,
  Button,
  Typography,
} from "@mui/material";
import { EmpDataStore, EmployeesStore } from "../../../../Store";
import { APIURL } from "../../../../Context/configs";
export default function EmpInfo() {
  const EmpData = EmployeesStore((state) => state.EmpData);
  const SetEmpData = EmployeesStore((state) => state.SetEmpData);
  const SetSnackMessage = EmpDataStore((state) => state.SetSnackMessage);
  const SetSnackBarType = EmpDataStore((state) => state.SetSnackBarType);
  const SetLoadingShow = EmpDataStore((state) => state.SetLoadingShow);
  const SetSnackBarShow = EmpDataStore((state) => state.SetSnackBarShow);
  const sendRequest = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    fetch(APIURL + "employee/general/updateinformation.php", {
      method: "post",
      body: JSON.stringify({
        soc: EmpData[0].ref,
        fn: data.get("fullname"),
        em: data.get("email"),
        ph: data.get("phone"),
        dob: data.get("dob"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetSnackMessage(item.content);
          if (item.id === 1) {
            SetSnackBarType("success");
            setTimeout(() => {
              SetSnackBarShow();
              fetch(APIURL + "employee/general/listinformation.php", {
                method: "post",
                body: JSON.stringify({
                  soc: EmpData[0].ref,
                }),
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Request-Headers":
                    "Content-Type, Authorization",
                  "Access-Control-Allow-Credentials": "true",
                },
              })
                .then((res) => res.json())
                .then((result) => {
                  SetEmpData(result);
                });
              SetLoadingShow();
            }, 2000);
          } else {
            SetSnackBarType("error");
            setTimeout(() => {
              SetSnackBarShow();
              SetLoadingShow();
            }, 2000);
          }
        });
      });
  };
  return (
    <>
      <Box sx={{ py: 5, my: 5 }}>
        <Paper elevation={3} sx={{ p: 2, mt: 5, mb: 3 }}>
          <Typography variant="h6" color={"#2f3193"}>
            {EmpData[0].fullname} (
            <span className="dateview">{EmpData[0].ref}</span>)
          </Typography>
        </Paper>
      </Box>
      <Paper elevation={3} sx={{ p: 2, my: 3 }}>
        <Box sx={{ my: 2 }}>
          <Typography variant="body" className="bolder">
            Employee Information
          </Typography>
        </Box>
        <Divider />
        <Box component="form" onSubmit={sendRequest} sx={{ my: 3 }}>
          <Box>
            <TextField
              id="fullname"
              name="fullname"
              label="Fullname"
              variant="standard"
              fullWidth
              size="small"
              defaultValue={EmpData[0].fullname}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              id="email"
              name="email"
              label="Email"
              variant="standard"
              fullWidth
              type="email"
              size="small"
              defaultValue={EmpData[0].email}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              id="phone"
              name="phone"
              label="Phone Number"
              variant="standard"
              fullWidth
              size="small"
              type="phone"
              defaultValue={EmpData[0].phone}
            />
          </Box>
          <Box sx={{ my: 2 }}>
            <TextField
              id="dob"
              name="dob"
              label="Date of Birth"
              variant="standard"
              fullWidth
              type="date"
              size="small"
              defaultValue={EmpData[0].dob}
            />
          </Box>
          <Divider />
          <Box sx={{ mt: 2 }}>
            <Button
              type="submit"
              size="small"
              fullWidth
              variant="contained"
              color="success"
            >
              Save Information
            </Button>
          </Box>
        </Box>
      </Paper>
    </>
  );
}
