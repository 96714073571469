import { Grid, Typography, Box, Button, Paper, TextField } from "@mui/material";
import { APIURL } from "../../../../Context/configs";
import { SystemConfigStore } from "../../../../Store";
export default function CacheData() {
    const ChaneSnackBarShow = SystemConfigStore(
      (state) => state.ChaneSnackBarShow
    );
    const ChangeSnackMessage = SystemConfigStore(
      (state) => state.ChangeSnackMessage
    );
    const SetLoadingShow = SystemConfigStore((state) => state.SetLoadingShow);
    const StartCaching =()=>{
        SetLoadingShow();
        fetch(APIURL + "CacheData.php", {
            headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Request-Headers": "Content-Type, Authorization",
            "Access-Control-Allow-Credentials": "true",
            },
        })
        .then((res) => res.json())
        .then((result) => {
            result.forEach((item) => {
                ChangeSnackMessage(item.content);
                ChaneSnackBarShow();
                SetLoadingShow();
            });
        });
    }
  return (
    <Paper elevation={3} sx={{ mt: 2, p: 3 }}>
    <Typography variant="h5" color={"#2f3193"} sx={{ my: 2 }}>
      Cache Data
    </Typography>
    <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
          <Button
                size="small"
                fullWidth
                color="success"
                variant="contained"
                onClick={StartCaching}

              >
                Start Caching
              </Button>
          </Grid>
        </Grid>
  </Paper>
  )
}
