import { dashBoardStore } from "../../../../Store";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
export default function Last10Leaves() {
  const L10LE = dashBoardStore((state) => state.l10Leav);
  return (
    <>
      {L10LE.length == 0 ? (
        <Typography variant="h6" sx={{ textAlign: "center", py: 3 }}>
          No Data To Preview
        </Typography>
      ) : (
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ flex: 2 }}>SOC/REF</TableCell>
                <TableCell sx={{ flex: 2 }}>Fullname</TableCell>
                <TableCell sx={{ flex: 1 }}>Leave Type</TableCell>
                <TableCell sx={{ flex: 2 }}>From</TableCell>
                <TableCell sx={{ flex: 2 }}>To</TableCell>
                <TableCell sx={{ flex: 1 }}>Days</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {L10LE.map((row) => (
                <TableRow key={row.leave_id}>
                  <TableCell>{row.soc_ref}</TableCell>
                  <TableCell>{row.fullname}</TableCell>
                  <TableCell>{row.leave_type}</TableCell>
                  <TableCell>{row.fd}</TableCell>
                  <TableCell>{row.ed}</TableCell>
                  <TableCell>{row.nodays}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
