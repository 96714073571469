import { Paper, Grid, Box, Typography } from "@mui/material";
import { EmpDataStore } from "../../../../Store";
import Calendar from "rc-year-calendar";
import CircleIcon from "@mui/icons-material/Circle";
import { useState, useEffect } from "react";
import { EllipsisLoader } from "../../../Common/Loaders";
export default function LeaveCalendar() {
  const LeaveList = EmpDataStore((state) => state.LeaveList);
  const LeaveTaken = EmpDataStore((state) => state.LeaveTaken);
  const [isLoading, SetisLoading] = useState(true);
  const [CalendarData] = useState([]);
  useEffect(() => {
    if (LeaveList != null) {
      LeaveList.forEach((lv) => {
        let element;
        if (lv.hoso_status === 0) {
          element = {
            location: lv.reason,
            startDate: new Date(lv.fd),
            endDate: new Date(lv.ed),
            color: "#263238",
          };
        } else {
          if (
            lv.leave_type === "Uncertified Sick Leave" ||
            lv.leave_type === "Uncertified Sick Leave 1/2 Day"
          ) {
            element = {
              location: lv.reason,
              startDate: new Date(lv.fd),
              endDate: new Date(lv.ed),
              color: "#0d47a1",
            };
          } else if (lv.leave_type === "Certified Sick Leave") {
            element = {
              location: lv.reason,
              startDate: new Date(lv.fd),
              endDate: new Date(lv.ed),
              color: "#b71c1c",
            };
          } else if (lv.leave_type === "Maternity Leave") {
            element = {
              location: lv.reason,
              startDate: new Date(lv.fd),
              endDate: new Date(lv.ed),
              color: "#f50057",
            };
          } else if (
            lv.leave_type === "Annual Leave" ||
            lv.leave_type === "Annual Leave 1/2 Day"
          ) {
            element = {
              location: lv.reason,
              startDate: new Date(lv.fd),
              endDate: new Date(lv.ed),
              color: "#e65100",
            };
          } else if (lv.leave_type === "R & R Leave") {
            element = {
              location: lv.reason,
              startDate: new Date(lv.fd),
              endDate: new Date(lv.ed),
              color: "#3e2723",
            };
          } else {
            element = {
              location: lv.reason,
              startDate: new Date(lv.fd),
              endDate: new Date(lv.ed),
              color: "#1b5e20",
            };
          }
        }
        CalendarData.push(element);
      });
    }
    SetisLoading(false);
  }, []);

  return (
    <Grid container spacing={3} sx={{ mt: 2 }}>
      <Grid item xs={12} sm={12} md={12} lg={9}>
        <Typography variant="h6" color={"#2f3193"} sx={{ mb: 2 }}>
          Leaves Calendar
        </Typography>
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          {isLoading ? (
            <Box sx={{ mb: 2, py: 5, textAlign: "center" }}>
              <EllipsisLoader />
            </Box>
          ) : (
            <Box sx={{ mb: 2 }}>
              <Calendar
                displayHeader={false}
                enableRangeSelection={true}
                dataSource={CalendarData}
              />
            </Box>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={3}>
        <Typography variant="h6" color={"#2f3193"} sx={{ mb: 2 }}>
          Leaves Information
        </Typography>
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <Box sx={{ mb: 2, fontSize: "10pt" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
                color: "#263238",
              }}
            >
              <CircleIcon fontSize="12px" sx={{ mr: 2 }} />
              <Typography variant="body">Awaiting Approval</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
                color: "#0d47a1",
              }}
            >
              <CircleIcon fontSize="12px" sx={{ mr: 2 }} />
              <Typography variant="body">Uncertified Sick Leave</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
                color: "#b71c1c",
              }}
            >
              <CircleIcon fontSize="12px" sx={{ mr: 2 }} />
              <Typography variant="body">Certified Sick Leave</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
                color: "#e65100",
              }}
            >
              <CircleIcon fontSize="12px" sx={{ mr: 2 }} />
              <Typography variant="body">Annual Leave</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
                color: "#f50057",
              }}
            >
              <CircleIcon fontSize="12px" sx={{ mr: 2 }} />
              <Typography variant="body">Maternity Leave</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
                color: "#3e2723",
              }}
            >
              <CircleIcon fontSize="12px" sx={{ mr: 2 }} />
              <Typography variant="body">R & R Leave</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
                color: "#1b5e20",
              }}
            >
              <CircleIcon fontSize="12px" sx={{ mr: 2 }} />
              <Typography variant="body">Others</Typography>
            </Box>
          </Box>
        </Paper>
        <Typography variant="h6" color={"#2f3193"} sx={{ mb: 2 }}>
          Leaves Taken
        </Typography>
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          {LeaveTaken != null ? (
            <>
              {LeaveTaken.map((l) => (
                <Box key="1" sx={{ mb: 2, fontSize: "10pt" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                      color: "#263238",
                    }}
                  >
                    <CircleIcon fontSize="12px" sx={{ mr: 2 }} />
                    <Typography variant="body">
                      Awaiting Approval : {l.AA === null ? "0" : l.AA} Days
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                      color: "#0d47a1",
                    }}
                  >
                    <CircleIcon fontSize="12px" sx={{ mr: 2 }} />
                    <Typography variant="body">
                      Uncertified Sick Leave : {l.USL === null ? "0" : l.USL}{" "}
                      Days
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                      color: "#b71c1c",
                    }}
                  >
                    <CircleIcon fontSize="12px" sx={{ mr: 2 }} />
                    <Typography variant="body">
                      Certified Sick Leave : {l.CSL === null ? "0" : l.CSL} Days
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                      color: "#e65100",
                    }}
                  >
                    <CircleIcon fontSize="12px" sx={{ mr: 2 }} />
                    <Typography variant="body">
                      Annual Leave : {l.AL === null ? "0" : l.AL} Days
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                      color: "#f50057",
                    }}
                  >
                    <CircleIcon fontSize="12px" sx={{ mr: 2 }} />
                    <Typography variant="body">
                      Maternity Leave : {l.ML === null ? "0" : l.ML} Days
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                      color: "#3e2723",
                    }}
                  >
                    <CircleIcon fontSize="12px" sx={{ mr: 2 }} />
                    <Typography variant="body">
                      R & R Leave : {l.RRL === null ? "0" : l.RRL} Days
                    </Typography>
                  </Box>
                </Box>
              ))}
            </>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box sx={{ p: 5, textAlign: "center" }}>
                <Typography variant="body">No Data To Preview</Typography>
              </Box>
            </Grid>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
