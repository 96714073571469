import {
  IconButton,
  LinearProgress,
  Box,
  Typography,
  Chip,
  Button,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import RemoveCircleTwoToneIcon from "@mui/icons-material/RemoveCircleTwoTone";
import CheckCircleOutlineTwoToneIcon from "@mui/icons-material/CheckCircleOutlineTwoTone";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import HourglassTopTwoToneIcon from "@mui/icons-material/HourglassTopTwoTone";
import FilePresentTwoToneIcon from "@mui/icons-material/FilePresentTwoTone";
const StatusShow = (param, param2) => {
  if (param === 1) {
    return (
      <Chip
        size="small"
        label={param2}
        color="success"
        icon={<CheckCircleOutlineTwoToneIcon fontSize="small" />}
      ></Chip>
    );
  } else if (param === 2) {
    return (
      <Chip
        size="small"
        label={param2}
        color="error"
        icon={<HighlightOffTwoToneIcon fontSize="small" />}
      ></Chip>
    );
  } else {
    return (
      <Chip
        size="small"
        label={param2}
        color="warning"
        icon={<HourglassTopTwoToneIcon fontSize="small" />}
      ></Chip>
    );
  }
};
const LeaveStat = (param) => {
  if (param === 1) {
    return (
      <Chip
        size="small"
        label="Requested"
        color="info"
        icon={<HourglassTopTwoToneIcon fontSize="small" />}
      ></Chip>
    );
  } else if (param === 2) {
    return (
      <Chip
        size="small"
        label="Approved"
        color="success"
        icon={<CheckCircleOutlineTwoToneIcon fontSize="small" />}
      ></Chip>
    );
  } else if (param === 3) {
    return (
      <Chip
        size="small"
        label="Canceling"
        color="warning"
        icon={<HourglassTopTwoToneIcon fontSize="small" />}
      ></Chip>
    );
  } else if (param === 4) {
    return (
      <Chip
        size="small"
        label="Canceled"
        color="error"
        icon={<CheckCircleOutlineTwoToneIcon fontSize="small" />}
      ></Chip>
    );
  } else {
    return (
      <Chip
        size="small"
        label="Declined"
        color="secondary"
        icon={<HighlightOffTwoToneIcon fontSize="small" />}
      ></Chip>
    );
  }
};
export const PayrollCol = [
  {
    field: "ref",
    headerName: "SOC/REF",
    sortable: true,
    width: 150,
  },
  {
    field: "fullname",
    headerName: "Full Name",
    sortable: true,
    width: 200,
  },
  {
    field: "month",
    headerName: "Month",
    sortable: false,
  },
  {
    field: "year",
    headerName: "Year",
    sortable: false,
  },
  {
    field: "attendance",
    headerName: "Attendance",
    sortable: false,
  },
  {
    field: "basesalary",
    headerName: "Base Salary",
    sortable: false,
  },
  {
    field: "dpt",
    headerName: "Danger Pay",
    sortable: false,
  },
  {
    field: "communication",
    headerName: "Communication",
    sortable: false,
  },
  {
    field: "dpta",
    headerName: "DP/TA",
    sortable: false,
  },
  {
    field: "hiorg",
    headerName: "Health Insurance By IOM",
    sortable: false,
  },
  {
    field: "hi",
    headerName: "Health Insurance By Staff",
    sortable: false,
  },
  {
    field: "ssiom",
    headerName: "Social Security By IOM",
    sortable: false,
  },
  {
    field: "ssemp",
    headerName: "Social Security By Staff",
    sortable: false,
  },
  {
    field: "pit",
    headerName: "Personal Income Tax",
    sortable: false,
  },
  {
    field: "occupotional",
    headerName: "Occupational Insurance",
    sortable: false,
  },
  {
    field: "extrafees",
    headerName: "Extra Fees",
    sortable: false,
  },
  {
    field: "rnr",
    headerName: "R And R",
    sortable: false,
  },
  {
    field: "others",
    headerName: "Others/Retro",
    sortable: false,
  },

  {
    field: "netsalary",
    headerName: "Total Salary",
    sortable: false,
  },
  {
    field: "socfees",
    headerName: "SOC Fees",
    sortable: false,
  },
  {
    field: "grosssalary",
    headerName: "Net Salary",
    sortable: false,
  },
  {
    field: "finalsalary",
    headerName: "Final Salary",
    sortable: false,
  },
];
export const EmpPayrollCol = [
  {
    field: "month",
    headerName: "Month",
    sortable: false,
  },
  {
    field: "year",
    headerName: "Year",
    sortable: false,
  },
  {
    field: "attendance",
    headerName: "Attendance",
    sortable: false,
  },
  {
    field: "basesalary",
    headerName: "Base Salary",
    sortable: false,
  },
  {
    field: "dpt",
    headerName: "Danger Pay",
    sortable: false,
  },
  {
    field: "communication",
    headerName: "Communication",
    sortable: false,
  },
  {
    field: "dpta",
    headerName: "DP/TA",
    sortable: false,
  },

  {
    field: "grosssalary",
    headerName: "Net Salary",
    sortable: false,
  },

  {
    field: "hiorg",
    headerName: "Health Insurance By IOM",
    sortable: false,
  },
  {
    field: "hi",
    headerName: "Health Insurance By Staff",
    sortable: false,
  },
  {
    field: "ssiom",
    headerName: "Social Security By IOM",
    sortable: false,
  },
  {
    field: "ssemp",
    headerName: "Social Security By Staff",
    sortable: false,
  },
  {
    field: "pit",
    headerName: "Personal Income Tax",
    sortable: false,
  },
  {
    field: "occupotional",
    headerName: "Occupational Insurance",
    sortable: false,
  },
  {
    field: "rnr",
    headerName: "R And R",
    sortable: false,
  },
  {
    field: "extrafees",
    headerName: "Extra Fees",
    sortable: false,
  },
  {
    field: "others",
    headerName: "Others/Retro",
    sortable: false,
  },
  {
    field: "finalsalary",
    headerName: "Final Salary",
    sortable: false,
  },
];
export const EmpHistCol2 = [
  {
    field: "iscurrent",
    headerName: "Current",
    sortable: false,
    renderCell: (params) => (
      <Box sx={{ textAlign: "center" }}>
        {params.row.iscurrent === 1 ? (
          <CheckCircleTwoToneIcon color="success" />
        ) : (
          <RemoveCircleTwoToneIcon color="warning" />
        )}
      </Box>
    ),
  },
  {
    field: "startdate",
    headerName: "Start Date",
    sortable: true,
    width: 150,
  },
  {
    field: "enddate",
    headerName: "End Date",
    sortable: true,
    width: 150,
  },
  {
    field: "ct",
    headerName: "Contract Type",
    sortable: false,
    width: 150,
  },
  {
    field: "workinghours",
    headerName: "Working Hours",
    sortable: false,
  },
  {
    field: "hub",
    headerName: "Hub",
    sortable: false,
  },
  {
    field: "dutystation",
    headerName: "Duty Station",
    sortable: false,
  },
  {
    field: "department",
    headerName: "Divison",
    sortable: false,
  },
  {
    field: "jposition",
    headerName: "Position",
    sortable: false,
  },
  {
    field: "grade",
    headerName: "Grade",
    sortable: false,
  },
  {
    field: "staffgrouping",
    headerName: "Staff Grouping",
    sortable: false,
  },
  {
    field: "type",
    headerName: "Type",
    sortable: false,
  },
];
export const EmpHistCol = [
  {
    field: "iscurrent",
    headerName: "Current",
    sortable: false,
    renderCell: (params) => (
      <Box sx={{ textAlign: "center" }}>
        {params.row.iscurrent === 1 ? (
          <CheckCircleTwoToneIcon color="success" />
        ) : (
          <RemoveCircleTwoToneIcon color="warning" />
        )}
      </Box>
    ),
  },
  {
    field: "startdate",
    headerName: "Start Date",
    sortable: true,
    width: 150,
  },
  {
    field: "enddate",
    headerName: "End Date",
    sortable: true,
    width: 150,
  },
  {
    field: "ct",
    headerName: "Contract Type",
    sortable: false,
    width: 150,
  },
  {
    field: "workinghours",
    headerName: "Working Hours",
    sortable: false,
  },
  {
    field: "hub",
    headerName: "Hub",
    sortable: false,
  },
  {
    field: "dutystation",
    headerName: "Duty Station",
    sortable: false,
  },
  {
    field: "department",
    headerName: "Divison",
    sortable: false,
  },
  {
    field: "jposition",
    headerName: "Position",
    sortable: false,
  },
  {
    field: "grade",
    headerName: "Grade",
    sortable: false,
  },
  {
    field: "staffgrouping",
    headerName: "Staff Grouping",
    sortable: false,
  },
  {
    field: "type",
    headerName: "Type",
    sortable: false,
  },
  {
    field: "programme",
    headerName: "Programme",
    sortable: false,
  },
  {
    field: "generalliab",
    headerName: "General Liability",
    sortable: false,
  },
];
export const EmpLeaveCol = [
  {
    field: "leave_type",
    headerName: "Leave Type",
    sortable: true,
    width: 150,
  },
  {
    field: "fd",
    headerName: "From",
    sortable: true,
    width: 100,
  },
  {
    field: "ed",
    headerName: "To",
    sortable: false,
    width: 100,
  },
  {
    field: "nodays",
    headerName: "Days",
    sortable: false,
    width: 60,
  },
  {
    field: "reason",
    headerName: "Reasons",
    sortable: false,
    width: 150,
  },
  {
    field: "supervisor",
    headerName: "Supervisor",
    sortable: false,
    width: 175,
    renderCell: (params) => (
      <>{StatusShow(params.row.sv_status, params.row.sv_name)}</>
    ),
  },
  {
    field: "hoso",
    headerName: "Head Of Sub Office",
    sortable: false,
    width: 175,
    renderCell: (params) => (
      <>
        {params.row.with_hoso === 1 ? (
          <>{StatusShow(params.row.hoso_status, params.row.hoso_name)}</>
        ) : (
          <>{StatusShow(params.row.sv_status, params.row.sv_name)}</>
        )}
      </>
    ),
  },
  {
    field: "rpt",
    headerName: "Report",
    sortable: false,
    width: 90,
    renderCell: (params) => (
      <>
        {
          (params.row.has_report = 1 && (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              href={params.row.report}
              target="_blank"
              title="View Report"
            >
              <FilePresentTwoToneIcon fontSize="small" />
            </Button>
          ))
        }
      </>
    ),
  },
  {
    field: "status",
    headerName: "Stage",
    sortable: false,
    width: 120,
    renderCell: (params) => <>{LeaveStat(params.row.status)}</>,
  },
];
export const EvaluatedCol = [
  {
    field: "ref",
    headerName: "SOC/REF",
    sortable: true,
    width: 150,
  },
  {
    field: "fullname",
    headerName: "Full Name",
    sortable: true,
    width: 250,
  },
  {
    field: "email",
    headerName: "Email",
    sortable: false,
  },
  {
    field: "req_formonth",
    headerName: "Month",
    sortable: false,
  },
  {
    field: "req_foryear",
    headerName: "Year",
    sortable: false,
  },
  {
    field: "req_date",
    headerName: "Request Date",
    sortable: false,
  },
  {
    field: "progress",
    headerName: "Progress",
    sortable: false,
    width: 200,
    renderCell: (params) => (
      <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Box sx={{ width: "60%" }}>
          <LinearProgress
            variant="determinate"
            value={params.row.progress}
            color="success"
          />
        </Box>
        <Box sx={{ width: "40%", textAlign: "center" }}>
          <Typography variant="body2" color="text.secondary">
            {params.row.progress}%
          </Typography>
        </Box>
      </Box>
    ),
  },
  {
    field: "view",
    headerName: "View",
    sortable: false,
    renderCell: (params) => (
      <NavLink
        className="navlink"
        to={"/evaluation/" + params.row.eval_id + "/view/"}
        target="_blank"
      >
        <IconButton size="small" color="primary" variant="contained">
          <RemoveRedEyeIcon fontSize="small" />
        </IconButton>
      </NavLink>
    ),
  },
];
export const InEvLister = [
  {
    field: "req_formonth",
    headerName: "Month",
    sortable: false,
    width: 100,
  },
  {
    field: "req_foryear",
    headerName: "Year",
    sortable: false,
    width: 100,
  },
  {
    field: "req_date",
    headerName: "Req. Date",
    sortable: false,
    width: 100,
  },
  {
    field: "progress",
    headerName: "Progress",
    sortable: false,
    width: 150,
    renderCell: (params) => (
      <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Box sx={{ width: "60%" }}>
          <LinearProgress
            variant="determinate"
            value={params.row.progress}
            color="success"
          />
        </Box>
        <Box sx={{ width: "40%", textAlign: "center" }}>
          <Typography variant="body2" color="text.secondary">
            {params.row.progress}%
          </Typography>
        </Box>
      </Box>
    ),
  },
  {
    field: "view",
    headerName: "View",
    sortable: false,
    width: 75,
    renderCell: (params) => (
      <NavLink
        className="navlink"
        to={"/evaluation/" + params.row.eval_id + "/view/"}
        target="_blank"
      >
        <IconButton size="small" color="primary" variant="contained">
          <RemoveRedEyeIcon fontSize="small" />
        </IconButton>
      </NavLink>
    ),
  },
];
export const OjectivesReqCol = [
  {
    field: "ref",
    headerName: "SOC/REF",
    sortable: true,
    width: 150,
  },
  {
    field: "fullname",
    headerName: "Full Name",
    sortable: true,
    width: 250,
  },
  {
    field: "ReqDate",
    headerName: "Request Date",
    sortable: false,
    width: 150,
  },
  {
    field: "objectives",
    headerName: "Objectives",
    sortable: false,
    width: 570,
  },
];
export const OjectivesAppCol = [
  {
    field: "ref",
    headerName: "SOC/REF",
    sortable: true,
    width: 150,
  },
  {
    field: "fullname",
    headerName: "Full Name",
    sortable: true,
    width: 250,
  },
  {
    field: "ReqDate",
    headerName: "Approval Date",
    sortable: false,
    width: 150,
  },
  {
    field: "objectives",
    headerName: "Objectives",
    sortable: false,
    width: 570,
  },
];
export const OjectivesNRCol = [
  {
    field: "ref",
    headerName: "SOC/REF",
    sortable: true,
    width: 150,
  },
  {
    field: "fullname",
    headerName: "Full Name",
    sortable: true,
    width: 250,
  },
];
export const NotEvaluatedCol = [
  {
    field: "ref",
    headerName: "SOC/REF",
    sortable: true,
    width: 300,
  },
  {
    field: "fullname",
    headerName: "Full Name",
    sortable: true,
    width: 300,
  },
  {
    field: "email",
    headerName: "Email",
    sortable: false,
    width: 300,
  },
];
export const ExportEvaluattionCol = [
  {
    field: "ref",
    headerName: "SOC/REF",
    sortable: true,
    width: 150,
  },
  {
    field: "fullname",
    headerName: "Full Name",
    sortable: true,
    width: 250,
  },
  {
    field: "email",
    headerName: "Email",
    sortable: false,
    width: 250,
  },
  {
    field: "req_formonth",
    headerName: "Month",
    sortable: false,
  },
  {
    field: "req_foryear",
    headerName: "Year",
    sortable: false,
  },
  {
    field: "req_date",
    headerName: "Request Date",
    sortable: false,
  },
  {
    field: "progress",
    headerName: "Progress",
    sortable: false,
    width: 200,
    renderCell: (params) => (
      <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Box sx={{ width: "60%" }}>
          <LinearProgress
            variant="determinate"
            value={params.row.progress}
            color="success"
          />
        </Box>
        <Box sx={{ width: "40%", textAlign: "center" }}>
          <Typography variant="body2" color="text.secondary">
            {params.row.progress}%
          </Typography>
        </Box>
      </Box>
    ),
  },
];
export const LogsCol = [
  {
    field: "id",
    headerName: "#",
    sortable: false,
    width: 50,
  },
  {
    field: "logdate",
    headerName: "Date",
    sortable: true,
  },
  {
    field: "adminfullname",
    headerName: "Admin Name",
    sortable: true,
    width: 200,
  },
  {
    field: "adminposition",
    headerName: "Position",
    sortable: false,
    width: 150,
  },

  {
    field: "section",
    headerName: "Section",
    sortable: false,
    width: 200,
  },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    width: 350,
  },
];
export const EmployeesCol = [
  {
    field: "ref",
    headerName: "SOC/REF",
    sortable: true,
    width: 150,
    renderCell: (params) => (
      <NavLink className="navlink2" to={"/admin/employee/" + params.row.empkey}>
        {params.row.ref}
      </NavLink>
    ),
  },
  {
    field: "fullname",
    headerName: "Full Name",
    sortable: true,
    width: 300,
    renderCell: (params) => (
      <NavLink className="navlink2" to={"/admin/employee/" + params.row.empkey}>
        {params.row.fullname}
      </NavLink>
    ),
  },
  {
    field: "ct",
    headerName: "Contract",
    sortable: false,
  },
  {
    field: "hub",
    headerName: "Hub",
    sortable: false,
  },
  {
    field: "dutystation",
    headerName: "Duty Station",
    sortable: false,
  },
  {
    field: "department",
    headerName: "Divison",
    sortable: false,
  },
  {
    field: "jposition",
    headerName: "Position",
    sortable: false,
  },
  {
    field: "grade",
    headerName: "Grade",
    sortable: false,
  },
  {
    field: "staffgrouping",
    headerName: "Staff Grouping",
    sortable: false,
  },
  {
    field: "type",
    headerName: "Type",
    sortable: false,
  },
  {
    field: "programme",
    headerName: "Programme",
    sortable: false,
  },
];
export const EmployeesCol2 = [
  {
    field: "ref",
    headerName: "SOC/REF",
    sortable: true,
    width: 150,
    renderCell: (params) => (
      <NavLink className="navlink2" to={"/iom/employee/" + params.row.empkey}>
        {params.row.ref}
      </NavLink>
    ),
  },
  {
    field: "fullname",
    headerName: "Full Name",
    sortable: true,
    width: 300,
    renderCell: (params) => (
      <NavLink className="navlink2" to={"/iom/employee/" + params.row.empkey}>
        {params.row.fullname}
      </NavLink>
    ),
  },
  {
    field: "ct",
    headerName: "Contract",
    sortable: false,
  },
  {
    field: "hub",
    headerName: "Hub",
    sortable: false,
  },
  {
    field: "dutystation",
    headerName: "Duty Station",
    sortable: false,
  },
  {
    field: "department",
    headerName: "Divison",
    sortable: false,
  },
  {
    field: "jposition",
    headerName: "Position",
    sortable: false,
  },
  {
    field: "grade",
    headerName: "Grade",
    sortable: false,
  },
  {
    field: "staffgrouping",
    headerName: "Staff Grouping",
    sortable: false,
  },
  {
    field: "type",
    headerName: "Type",
    sortable: false,
  },
  {
    field: "programme",
    headerName: "Programme",
    sortable: false,
  },
];
export const ImportCols = [
  {
    field: "ref",
    headerName: "SOC/REF",
    sortable: true,
    width: 150,
  },
  {
    field: "name",
    headerName: "Full Name",
    sortable: true,
    width: 200,
  },
  {
    field: "hub",
    headerName: "Hub",
    sortable: false,
  },
  {
    field: "dutystation",
    headerName: "Duty Station",
    sortable: false,
  },
  {
    field: "dangerpay",
    headerName: "Danger Pay",
    sortable: false,
  },
  {
    field: "contract",
    headerName: "Type of Contract",
    sortable: false,
  },
  {
    field: "position",
    headerName: "Position",
    sortable: false,
  },
  {
    field: "department",
    headerName: "Divison",
    sortable: false,
  },
  {
    field: "staffgrouping",
    headerName: "Staff Grouping",
    sortable: false,
  },
  {
    field: "type",
    headerName: "Type",
    sortable: false,
  },
  {
    field: "programme",
    headerName: "Programme",
    sortable: false,
  },
  {
    field: "grade",
    headerName: "Grade",
    sortable: false,
  },
  {
    field: "basesalary",
    headerName: "Base Salary",
    sortable: false,
  },
  {
    field: "commun",
    headerName: "Communincation",
    sortable: false,
  },
  {
    field: "attendance",
    headerName: "Attendance",
    sortable: false,
  },
  {
    field: "dpta",
    headerName: "DP/TA",
    sortable: false,
  },
  {
    field: "others",
    headerName: "Others/Retro",
    sortable: false,
  },
  {
    field: "socfees",
    headerName: "SOC Fees",
    sortable: false,
  },
  {
    field: "grosssalary",
    headerName: "Gross Salary",
    sortable: false,
  },
  {
    field: "netsalary",
    headerName: "Net Salary",
    sortable: false,
  },
  {
    field: "generalliability",
    headerName: "General Liability",
    sortable: false,
  },
  {
    field: "occupational",
    headerName: "Occupational Insurance",
    sortable: false,
  },
  {
    field: "healthinsiom",
    headerName: "Health Insurance By IOM",
    sortable: false,
  },
  {
    field: "healthinsstaff",
    headerName: "Health Insurance By Staff",
    sortable: false,
  },
  {
    field: "socialseciom",
    headerName: "Social Security By IOM",
    sortable: false,
  },
  {
    field: "socialsecstaff",
    headerName: "Social Security By Staff",
    sortable: false,
  },
  {
    field: "pit",
    headerName: "Income Tax",
    sortable: false,
  },
  {
    field: "extrafees",
    headerName: "Extra Fees",
    sortable: false,
  },
  {
    field: "rnr",
    headerName: "R and R",
    sortable: false,
  },
  {
    field: "finalsalary",
    headerName: "Final Salary",
    sortable: false,
  },
  {
    field: "actions",
    headerName: "Actions",
    sortable: false,
  },
  {
    field: "workinghours",
    headerName: "Working Hours",
    sortable: false,
  },
  {
    field: "svn",
    headerName: "SVN Hired",
    sortable: false,
  },
  {
    field: "feod",
    headerName: "First EOD",
    sortable: false,
  },
  {
    field: "contractstart",
    headerName: "Contract Start",
    sortable: false,
  },
  {
    field: "contractend",
    headerName: "Contract Expiry",
    sortable: false,
  },
  {
    field: "dob",
    headerName: "Date Of Birth",
    sortable: false,
  },
  {
    field: "age",
    headerName: "Age",
    sortable: false,
  },
  {
    field: "gender",
    headerName: "Gender",
    sortable: false,
  },
  {
    field: "nationality",
    headerName: "Nationality",
    sortable: false,
  },
  {
    field: "link",
    headerName: "Link",
    sortable: false,
  },
  {
    field: "accnt1",
    headerName: "Bank Accnt #1",
    sortable: false,
  },
  {
    field: "accnt2",
    headerName: "Bank Accnt #2",
    sortable: false,
  },
  {
    field: "accnt3",
    headerName: "Bank Accnt #3",
    sortable: false,
  },
  {
    field: "bank",
    headerName: "Bank Name",
    sortable: false,
  },
];
export const SoftwareCols = [
  {
    field: "ref",
    headerName: "SOC/REF",
    sortable: true,
    width: 150,
  },
  {
    field: "fullname",
    headerName: "Full Name",
    sortable: true,
    width: 200,
  },
  {
    field: "cur_month",
    headerName: "Month",
    sortable: false,
    width: 100,
  },
  {
    field: "cur_year",
    headerName: "Year",
    sortable: true,
    width: 100,
  },
  {
    field: "hub",
    headerName: "Hub",
    sortable: false,
  },
  {
    field: "dutystation",
    headerName: "Duty Station",
    sortable: false,
  },
  {
    field: "havedanger",
    headerName: "Danger Pay",
    sortable: false,
  },
  {
    field: "contract",
    headerName: "Type of Contract",
    sortable: false,
  },
  {
    field: "jposition",
    headerName: "Position",
    sortable: false,
  },
  {
    field: "divison",
    headerName: "Divison",
    sortable: false,
  },
  {
    field: "staffgrouping",
    headerName: "Staff Grouping",
    sortable: false,
  },
  {
    field: "programme",
    headerName: "Programme",
    sortable: false,
  },
  {
    field: "grade",
    headerName: "Grade",
    sortable: false,
  },
  {
    field: "basesalary",
    headerName: "Base Salary",
    sortable: false,
  },
  {
    field: "communication",
    headerName: "Communincation",
    sortable: false,
  },
  {
    field: "attendance",
    headerName: "Attendance",
    sortable: false,
  },
  {
    field: "absence",
    headerName: "Absence",
    sortable: false,
  },
  {
    field: "dpta",
    headerName: "DP/TA",
    sortable: false,
  },
  {
    field: "retro_others",
    headerName: "Others/Retro",
    sortable: false,
  },
  {
    field: "soc_fees",
    headerName: "SOC Fees",
    sortable: false,
  },
  {
    field: "netsalary",
    headerName: "Net Salary",
    sortable: false,
  },
  {
    field: "totalsalary",
    headerName: "Total Salary",
    sortable: false,
  },
  {
    field: "generalliab",
    headerName: "General Liability",
    sortable: false,
  },
  {
    field: "occu_ins",
    headerName: "Occupational Insurance",
    sortable: false,
  },
  {
    field: "health_ins_iom",
    headerName: "Health Insurance By IOM",
    sortable: false,
  },
  {
    field: "health_ins_staff",
    headerName: "Health Insurance By Staff",
    sortable: false,
  },
  {
    field: "social_security_iom",
    headerName: "Social Security By IOM",
    sortable: false,
  },
  {
    field: "social_security_staff",
    headerName: "Social Security By Staff",
    sortable: false,
  },
  {
    field: "pit",
    headerName: "Income Tax",
    sortable: false,
  },
  {
    field: "rnr",
    headerName: "R and R",
    sortable: false,
  },
  {
    field: "extrafees",
    headerName: "Extra Fees",
    sortable: false,
  },
  {
    field: "finalsalary",
    headerName: "Final Salary",
    sortable: false,
  },
  {
    field: "salaryactions",
    headerName: "Actions",
    sortable: false,
  },
  {
    field: "workinghours",
    headerName: "Working Hours",
    sortable: false,
  },
  {
    field: "svn",
    headerName: "SVN Hired",
    sortable: false,
  },
  {
    field: "wbs1",
    headerName: "1WBS For Salary",
    sortable: false,
  },
  {
    field: "perc1",
    headerName: "Perc 1",
    sortable: false,
  },
  {
    field: "alloc1",
    headerName: "Allocation 1",
    sortable: false,
  },
  {
    field: "com1",
    headerName: "Commment WBS 1",
    sortable: false,
  },
  {
    field: "wbs2",
    headerName: "2WBS For Salary",
    sortable: false,
  },
  {
    field: "perc2",
    headerName: "Perc 2",
    sortable: false,
  },
  {
    field: "alloc2",
    headerName: "Allocation 2",
    sortable: false,
  },
  {
    field: "com2",
    headerName: "Commment WBS 2",
    sortable: false,
  },
  {
    field: "wbs3",
    headerName: "3WBS For Salary",
    sortable: false,
  },
  {
    field: "perc3",
    headerName: "Perc 3",
    sortable: false,
  },
  {
    field: "alloc3",
    headerName: "Allocation 3",
    sortable: false,
  },
  {
    field: "com3",
    headerName: "Commment WBS 3",
    sortable: false,
  },
  {
    field: "wbs4",
    headerName: "4WBS For Salary",
    sortable: false,
  },
  {
    field: "perc4",
    headerName: "Perc 4",
    sortable: false,
  },
  {
    field: "alloc4",
    headerName: "Allocation 4",
    sortable: false,
  },
  {
    field: "com4",
    headerName: "Commment WBS 4",
    sortable: false,
  },
  {
    field: "wbs5",
    headerName: "5WBS For Salary",
    sortable: false,
  },
  {
    field: "perc5",
    headerName: "Perc 5",
    sortable: false,
  },
  {
    field: "alloc5",
    headerName: "Allocation 5",
    sortable: false,
  },
  {
    field: "com5",
    headerName: "Commment WBS 5",
    sortable: false,
  },
  {
    field: "wbs6",
    headerName: "6WBS For Salary",
    sortable: false,
  },
  {
    field: "perc6",
    headerName: "Perc 6",
    sortable: false,
  },
  {
    field: "alloc6",
    headerName: "Allocation 6",
    sortable: false,
  },
  {
    field: "com6",
    headerName: "Commment WBS 6",
    sortable: false,
  },
  {
    field: "wbs7",
    headerName: "7WBS For Salary",
    sortable: false,
  },
  {
    field: "perc7",
    headerName: "Perc 7",
    sortable: false,
  },
  {
    field: "alloc7",
    headerName: "Allocation 7",
    sortable: false,
  },
  {
    field: "com7",
    headerName: "Commment WBS 7",
    sortable: false,
  },
  {
    field: "wbs8",
    headerName: "8WBS For Salary",
    sortable: false,
  },
  {
    field: "perc8",
    headerName: "Perc 8",
    sortable: false,
  },
  {
    field: "alloc8",
    headerName: "Allocation 8",
    sortable: false,
  },
  {
    field: "com8",
    headerName: "Commment WBS 8",
    sortable: false,
  },
  {
    field: "fod",
    headerName: "First EOD",
    sortable: false,
  },
  {
    field: "hiringdate",
    headerName: "SOC EOD",
    sortable: false,
  },
  {
    field: "startdate",
    headerName: "Contract Start",
    sortable: false,
  },
  {
    field: "enddate",
    headerName: "Contract Expiry",
    sortable: false,
  },
  {
    field: "upgradeable",
    headerName: "Elibible For Upgrade",
    sortable: false,
  },
  {
    field: "emplink",
    headerName: "Link",
    sortable: false,
  },
  {
    field: "gender",
    headerName: "Gender",
    sortable: false,
  },
  {
    field: "dob",
    headerName: "Date Of Birth",
    sortable: false,
  },
  {
    field: "age",
    headerName: "Age",
    sortable: false,
  },
  {
    field: "maritalstatus",
    headerName: "Marital Status",
    sortable: false,
  },
  {
    field: "nationality",
    headerName: "Nationality",
    sortable: false,
  },
  {
    field: "email",
    headerName: "Email",
    sortable: false,
  },
  {
    field: "phone",
    headerName: "Phone Number",
    sortable: false,
  },
  {
    field: "bankname",
    headerName: "Bank Name",
    sortable: false,
  },
  {
    field: "accnt1",
    headerName: "Bank Accnt #1",
    sortable: false,
  },
  {
    field: "accnt2",
    headerName: "Bank Accnt #2",
    sortable: false,
  },
  {
    field: "accnt3",
    headerName: "Bank Accnt #3",
    sortable: false,
  },
  {
    field: "remarks",
    headerName: "Remarks",
    sortable: false,
  },
];
