import {
  Container,
  Grid,
  Breadcrumbs,
  Typography,
  Paper,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Evaluated,
  NotEvaluated,
  ExportEvaluations,
  RequestedObjectives,
  ApprovedObjectives,
  NoObjectives,
} from "../Elements/Evaluations";
import TaskIcon from "@mui/icons-material/Task";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ImportExportIcon from "@mui/icons-material/ImportExport";
export default function Evaluation() {
  const [NavOption, setNavOption] = useState(0);
  useEffect(() => {
    document.title = "StarsOrbit HRM - Evaluations";
  }, []);

  return (
    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink className="breabcrumblink" to="/admin">
              Admin Panel
            </NavLink>
            <Typography color="text.primary">Evaluations</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <BottomNavigation
              showLabels
              value={NavOption}
              onChange={(event, newValue) => {
                setNavOption(newValue);
              }}
            >
              <BottomNavigationAction label="Evaluated" icon={<StarIcon />} />
              <BottomNavigationAction
                label="Not Evaluated"
                icon={<StarBorderIcon />}
              />
              <BottomNavigationAction
                label="Evaluation Export"
                icon={<ImportExportIcon />}
              />
              <BottomNavigationAction
                label="Requested Objectives"
                icon={<AssignmentTurnedInIcon />}
              />
              <BottomNavigationAction
                label="Approved Objectives"
                icon={<TaskIcon />}
              />
              <BottomNavigationAction
                label="Not Requested Objectives"
                icon={<AssignmentLateIcon />}
              />
            </BottomNavigation>
          </Paper>
        </Grid>
      </Grid>
      {NavOption === 0 && <Evaluated />}
      {NavOption === 1 && <NotEvaluated />}
      {NavOption === 2 && <ExportEvaluations />}
      {NavOption === 3 && <RequestedObjectives />}
      {NavOption === 4 && <ApprovedObjectives />}
      {NavOption === 5 && <NoObjectives />}
    </Container>
  );
}
