import { Snackbar, Alert } from "@mui/material";
import { EvaluationProceessStore } from "../../../../Store";

export default function ActionMessage() {
  const SetSnackBarShow = EvaluationProceessStore(
    (state) => state.SetSnackBarShow
  );
  const SnackBarShow = EvaluationProceessStore((state) => state.SnackBarShow);
  const SnackBarMessage = EvaluationProceessStore(
    (state) => state.SnackBarMessage
  );
  const SnackBarType = EvaluationProceessStore((state) => state.SnackBarType);
  return (
    <Snackbar
      autoHideDuration={3000}
      open={SnackBarShow}
      onClose={SetSnackBarShow}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        onClose={SetSnackBarShow}
        variant="filled"
        severity={SnackBarType}
        sx={{ width: "100%" }}
      >
        {SnackBarMessage}
      </Alert>
    </Snackbar>
  );
}
