import { Paper, Grid, TextField, Typography, MenuItem } from "@mui/material";
import { EmployeesStore } from "../../../../Store/";
export default function GeneralInfo() {
  const EmpData = EmployeesStore((state) => state.EmpData);
  return (
    <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" color={"#2f3193"} sx={{ my: 2 }}>
        General Information
      </Typography>
      {EmpData.map((emp) => (
        <Grid key={emp.lid} container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="ref"
              name="ref"
              label="SOC/REF"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.ref}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="fullname"
              name="fullname"
              label="Fullname"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.fullname}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="email"
              name="email"
              label="Email"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.email}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="phone"
              name="phone"
              label="Phone"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.phone}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="dob"
              name="dob"
              label="Date Of Birth"
              type="date"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.dob}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="fod"
              name="fod"
              type="date"
              label="First EOD"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.fod}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="hiringdate"
              name="hiringdate"
              type="date"
              label="Hiring Date"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.hiringdate}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="svn"
              name="svn"
              label="SVN"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.svn}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="gender"
              name="gender"
              select
              label="Gender"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.gender}
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="nationality"
              name="nationality"
              label="Nationality"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.nationality}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              id="emplink"
              name="emplink"
              label="Link"
              variant="standard"
              fullWidth
              disabled
              defaultValue={emp.emplink}
            />
          </Grid>
        </Grid>
      ))}
    </Paper>
  );
}
