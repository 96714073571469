import { Paper, Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { LeaveProceessStore } from "../../../../Store";
import { APIURL } from "../../../../Context/configs";
export default function LeaveData() {
  const Leave = LeaveProceessStore((state) => state.Leave);
  const LeaveBal = LeaveProceessStore((state) => state.LeaveBal);
  const SetLeaveBal = LeaveProceessStore((state) => state.SetLeaveBal);
  const [IL, SetIL] = useState(true);
  useEffect(() => {
    fetch(APIURL + "portalhome/leave/viewbalance.php", {
      method: "post",
      body: JSON.stringify({
        soc: Leave[0].soc_ref,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetLeaveBal(result);
        SetIL(false);
      });
  }, []);

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Box sx={{ my: 1 }}>
        <Typography variant="body2">
          Leave Type: <span className="dateview">{Leave[0].leave_type}</span>
        </Typography>
      </Box>
      <Box sx={{ my: 1 }}>
        <Typography variant="body2">
          From: <span className="dateview">{Leave[0].fd}</span>
        </Typography>
      </Box>
      <Box sx={{ my: 1 }}>
        <Typography variant="body2">
          To: <span className="dateview">{Leave[0].ed}</span>
        </Typography>
      </Box>
      <Box sx={{ my: 1 }}>
        <Typography variant="body2">
          Total Days: <span className="dateview">{Leave[0].nodays} day</span>
        </Typography>
      </Box>
      <Box sx={{ my: 1 }}>
        <Typography variant="body2">
          Reason: <span className="dateview">{Leave[0].reason}</span>
        </Typography>
      </Box>
      {!IL && (
        <>
          <Box sx={{ my: 1 }}>
            <Typography variant="body2">
              Anuual Leave Balance:{" "}
              <span className="dateview">
                {LeaveBal[0].normalleavebalance} days
              </span>
            </Typography>
          </Box>
          <Box sx={{ my: 1 }}>
            <Typography variant="body2">
              Sick Leave Balance:{" "}
              <span className="dateview">
                {LeaveBal[0].sickleavebalance} days
              </span>
            </Typography>
          </Box>
          <Box sx={{ my: 1 }}>
            <Typography variant="body2">
              Cumulative Leave Balance:{" "}
              <span className="dateview">
                {LeaveBal[0].previeusbalance} days
              </span>
            </Typography>
          </Box>
        </>
      )}
    </Paper>
  );
}
