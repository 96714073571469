import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { ObjectiveProcessStore } from "../../../../Store";
import HTMLReactParser from "html-react-parser";
import { APIURL } from "../../../../Context/configs";
export default function UpdatePage() {
  const Objective = ObjectiveProcessStore((state) => state.Objective);
  const SetSnackBarShow = ObjectiveProcessStore(
    (state) => state.SetSnackBarShow
  );
  const SetLoadingShow = ObjectiveProcessStore((state) => state.SetLoadingShow);
  const SetSnackMessage = ObjectiveProcessStore(
    (state) => state.SetSnackMessage
  );
  const SetSnackBarType = ObjectiveProcessStore(
    (state) => state.SetSnackBarType
  );
  const AprroveOBJ = () => {
    SetLoadingShow();
    fetch(APIURL + "portalhome/objective/ApproveObj.php", {
      method: "post",
      body: JSON.stringify({
        tkn: Objective[0].objtoken,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetSnackMessage(item.content);
          if (item.id == 1) {
            SetSnackBarType("success");
          } else {
            SetSnackBarType("error");
          }
        });
        setTimeout(() => {
          SetSnackBarShow();
          SetLoadingShow();
        }, 2000);
      });
  };
  const DeclineOBJ = () => {
    SetLoadingShow();
    fetch(APIURL + "portalhome/objective/DeclineObj.php", {
      method: "post",
      body: JSON.stringify({
        tkn: Objective[0].objtoken,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetSnackMessage(item.content);
          if (item.id == 1) {
            SetSnackBarType("success");
          } else {
            SetSnackBarType("error");
          }
        });
        setTimeout(() => {
          SetSnackBarShow();
          SetLoadingShow();
        }, 2000);
      });
  };
  return (
    <Box>
      <Paper
        elevation={2}
        sx={{
          backgroundColor: "#333",
          px: 1,
          py: 3,
          display: "flex",
          alignItems: "center",
        }}
      >
        <BusinessCenterIcon sx={{ mx: 3, color: "#fff" }} />
        <Typography variant="h6" color={"#fff"}>
          Objective Update Request From ({Objective[0].ref}){" "}
          {Objective[0].fullname}
        </Typography>
      </Paper>
      <Paper elevation={3} sx={{ my: 2, p: 2 }}>
        <Box>
          <Typography variant="h6" color={"#2f3193"}>
            Objectives
          </Typography>
        </Box>
        <Box sx={{ my: 2 }}>
          <Divider />
        </Box>
        <Box>{HTMLReactParser(Objective[0].objectives)}</Box>
        <Box sx={{ my: 2 }}>
          <Divider />
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ mx: 1 }}
            onClick={AprroveOBJ}
          >
            Approve Objectives
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{ mx: 1 }}
            onClick={DeclineOBJ}
          >
            Decline Objectives
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
