import { Typography } from "@mui/material";
import { SystemConfigStore } from "../../../../Store";
import { RollerLoader } from "../../../Common/Loaders";

export default function Loading() {
  const LoadingShow = SystemConfigStore((state) => state.LoadingShow);
  return (
    <>
      {LoadingShow && (
        <div className="submitLoader2">
          <RollerLoader />
          <Typography color={"#ffffff"}>Proccessing ...</Typography>
        </div>
      )}
    </>
  );
}
