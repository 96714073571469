import { Paper, Grid, TextField, Typography, MenuItem } from "@mui/material";
import { EmployeesStore } from "../../../../Store/";
import { APIURL } from "../../../../Context/configs";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import { useState, useEffect } from "react";

export default function JobInfo() {
  const [Contracts, SetContracts] = useState([]);
  const EmpData = EmployeesStore((state) => state.EmpData);
  useEffect(() => {
    fetch(APIURL + "manager/employees/employeecontracts.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetContracts(result);
      });
  }, []);
  return (
    <>
      <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
        <Typography variant="h6" color={"#2f3193"} sx={{ my: 2 }}>
          Job Information
        </Typography>
        {EmpData.map((emp) => (
          <Grid container spacing={3} key={emp.lid}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="ct"
                name="ct"
                select
                disabled
                label="Contract Type"
                variant="standard"
                fullWidth
                defaultValue={emp.contract}
              >
                {Contracts.map((c) => (
                  <MenuItem key={c.con_id} value={c.con_id}>
                    {c.ct}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="sd"
                name="sd"
                type="date"
                label="Start Date"
                variant="standard"
                fullWidth
                disabled
                defaultValue={emp.startdate}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="ed"
                name="ed"
                type="date"
                label="End Date"
                variant="standard"
                fullWidth
                disabled
                defaultValue={emp.enddate}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="wh"
                name="wh"
                label="Working Hours"
                type="number"
                variant="standard"
                fullWidth
                disabled
                defaultValue={emp.workinghours}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                id="hub"
                name="hub"
                label="Hub"
                variant="standard"
                fullWidth
                disabled
                defaultValue={emp.hub}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                id="dep"
                name="dep"
                label="Divison"
                variant="standard"
                fullWidth
                disabled
                defaultValue={emp.department}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                id="ds"
                name="ds"
                label="Duty Station"
                variant="standard"
                fullWidth
                disabled
                defaultValue={emp.dutystation}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                id="po"
                name="po"
                label="Position"
                variant="standard"
                fullWidth
                disabled
                defaultValue={emp.jposition}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                id="gl"
                name="gl"
                label="General Liability"
                variant="standard"
                fullWidth
                disabled
                defaultValue={emp.generalliab}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                id="gr"
                name="gr"
                label="Grade"
                variant="standard"
                fullWidth
                disabled
                defaultValue={emp.grade}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                id="sg"
                name="sg"
                label="Staff Grouping"
                variant="standard"
                fullWidth
                disabled
                defaultValue={emp.staffgrouping}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                id="et"
                name="et"
                label="Type"
                variant="standard"
                fullWidth
                disabled
                defaultValue={emp.type}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                id="pr"
                name="pr"
                label="Programme"
                variant="standard"
                fullWidth
                disabled
                defaultValue={emp.programme}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                id="act"
                name="act"
                label="Action"
                variant="standard"
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
        ))}
      </Paper>
      <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
        <Typography variant="h6" color={"#2f3193"} sx={{ my: 2 }}>
          Supervisor Information
        </Typography>
        {EmpData.map((emp) => (
          <Grid container spacing={3} key={emp.lid}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                id="svn"
                name="svn"
                label="Supervisor Name"
                variant="standard"
                fullWidth
                disabled
                defaultValue={emp.supervisor}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                id="sve"
                name="sve"
                label="Supervisor Email"
                variant="standard"
                fullWidth
                disabled
                defaultValue={emp.svemail}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                id="hosn"
                name="hosn"
                label="Second Level Supervisor Name"
                variant="standard"
                fullWidth
                disabled
                defaultValue={emp.hos}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                id="hose"
                name="hose"
                label="Second Level Supervisor Email"
                variant="standard"
                fullWidth
                disabled
                defaultValue={emp.hose}
              />
            </Grid>
          </Grid>
        ))}
      </Paper>
    </>
  );
}
