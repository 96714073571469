import { create } from "zustand";
const EvaluationProceessStore = create((set) => ({
  Eval: [],
  SnackBarShow: false,
  SnackBarType: "success",
  SnackBarMessage: "",
  LoadingShow: false,
  SetEval: (data) => set((state) => ({ Eval: data })),
  SetSnackBarShow: () =>
    set((state) => ({ SnackBarShow: !state.SnackBarShow })),
  SetLoadingShow: () => set((state) => ({ LoadingShow: !state.LoadingShow })),
  SetSnackMessage: (mes) => set((state) => ({ SnackBarMessage: mes })),
  SetSnackBarType: (t) => set((state) => ({ SnackBarType: t })),
}));

export default EvaluationProceessStore;
