import { useState } from "react";
import { styled } from "@mui/material/styles";
import { Divider, List, IconButton, Typography } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MenuSharpIcon from "@mui/icons-material/MenuSharp";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import DashboardSharpIcon from "@mui/icons-material/DashboardSharp";
import SupervisedUserCircleSharpIcon from "@mui/icons-material/SupervisedUserCircleSharp";
import MonetizationOnSharpIcon from "@mui/icons-material/MonetizationOnSharp";
import StarHalfSharpIcon from "@mui/icons-material/StarHalfSharp";
import MedicationLiquidSharpIcon from "@mui/icons-material/MedicationLiquidSharp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import InfoIcon from "@mui/icons-material/Info";
import RssFeedSharpIcon from "@mui/icons-material/RssFeedSharp";
import { NavLink } from "react-router-dom";
const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));
const DrawerBody = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
export default function Navbar(props) {
  const [NavOpen, SetNavOpen] = useState(false);

  const handleDrawer = () => {
    SetNavOpen(!NavOpen);
  };
  const p = props.priv;
  return (
    <DrawerBody variant="permanent" open={NavOpen}>
      <DrawerHeader>
        <IconButton onClick={handleDrawer} sx={{ mr: 1 }}>
          {NavOpen ? <CloseSharpIcon /> : <MenuSharpIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List className="text-small">
        <NavLink className="navlink" to="/iom/dashboard" title="Dashboard">
          <DashboardSharpIcon fontSize="small" sx={{ mx: 1 }} />
          <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
            Dashboard
          </Typography>
        </NavLink>
        <NavLink className="navlink" to="/iom/employees" title="IOM Employees">
          <SupervisedUserCircleSharpIcon fontSize="small" sx={{ mx: 1 }} />
          <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
            IOM Employees
          </Typography>
        </NavLink>
        <NavLink className="navlink" to="/iom/payroll" title="Payroll">
          <MonetizationOnSharpIcon fontSize="small" sx={{ mx: 1 }} />
          <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
            Payroll
          </Typography>
        </NavLink>
        <NavLink className="navlink" to="/iom/evaluation" title="Evaluations">
          <StarHalfSharpIcon fontSize="small" sx={{ mx: 1 }} />
          <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
            Evaluations
          </Typography>
        </NavLink>
        <NavLink
          className="navlink"
          to="/iom/healtinsurance"
          title="Health Insurance"
        >
          <MedicationLiquidSharpIcon fontSize="small" sx={{ mx: 1 }} />
          <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
            Health Insurance
          </Typography>
        </NavLink>
        <NavLink className="navlink" to="/iom/software" title="Software Data">
          <RequestQuoteIcon fontSize="small" sx={{ mx: 1 }} />
          <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
            Software Data
          </Typography>
        </NavLink>
        <NavLink className="navlink" to="/iom/account" title="My Profile">
          <AccountCircleIcon fontSize="small" sx={{ mx: 1 }} />
          <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
            My Profile
          </Typography>
        </NavLink>
        <NavLink className="navlink" to="/iom/new" title="What's New">
          <RssFeedSharpIcon fontSize="small" sx={{ mx: 1 }} />
          <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
            What's New
          </Typography>
        </NavLink>
      </List>
    </DrawerBody>
  );
}
