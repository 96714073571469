import {
  Box,
  TextField,
  Typography,
  Button,
  Alert,
  Paper,
  Divider,
} from "@mui/material";
import { useRef, useState } from "react";
import Cookies from "js-cookie";
import { NavLink } from "react-router-dom";
import { APIURL } from "../../../../Context/configs";
import { EllipsisLoader } from "../../../Common/Loaders";
import { userLoginStore } from "../../../../Store";

export default function LoginForm() {
  const LoginAction = userLoginStore((state) => state.setIsLoggedIn);
  const RoleAction = userLoginStore((state) => state.setuserRole);
  const RefAction = userLoginStore((state) => state.setuserRef);
  const IDAction = userLoginStore((state) => state.setuserID);
  const ROLETOGO = userLoginStore((state) => state.userRole);
  const [isLoading, setisLoading] = useState(false);
  const [LoginResponse, setLoginResponse] = useState(false);
  const [DidSubmit, SetDidSubmit] = useState(false);
  const RedLink = useRef();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetDidSubmit(true);
    setisLoading(true);
    fetch(APIURL + "portalhome/userlogin/userlogin.php", {
      method: "post",
      body: JSON.stringify({
        un: data.get("username"),
        pw: data.get("password"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          if (item.LOGGED === 1) {
            Cookies.set("loggedIn", true, { expires: 30, path: "/" });
            Cookies.set("NewRun", true, { expires: 30, path: "/" });
            Cookies.set("userArea", item.ROL, { expires: 30, path: "/" });
            Cookies.set("userRef", item.REF, { expires: 30, path: "/" });
            Cookies.set("userID", item.IDEN, { expires: 30, path: "/" });
            LoginAction(true);
            RoleAction(item.ROL);
            RefAction(item.REF);
            IDAction(item.IDEN);
            setTimeout(() => {
              RedLink.current.click();
            }, 5000);
            setLoginResponse(true);
          } else {
            setLoginResponse(false);
          }
          setisLoading(false);
        });
      });
  };
  return (
    <Box
      sx={{
        marginTop: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography variant="h5" sx={{ textAlign: "center", mb: 2 }}>
          Sign in
        </Typography>
        <Divider />
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            size="small"
            label="User Name"
            name="username"
            autoComplete="username"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            size="small"
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Box sx={{ textAlign: "center" }}>
            {DidSubmit ? (
              isLoading ? (
                <EllipsisLoader />
              ) : (
                <>
                  {LoginResponse ? (
                    <Alert severity="success">
                      Successfully logged in, redirecting in 5 seconds
                    </Alert>
                  ) : (
                    <Alert severity="error">
                      Invalid username or password please try again.
                    </Alert>
                  )}
                </>
              )
            ) : null}
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <NavLink
            className="d-none"
            to={"/" + ROLETOGO}
            ref={RedLink}
          ></NavLink>
          <Box sx={{ textAlign: "center" }}>
            <NavLink className="navlink3" to="/reset">
              Forgot Your Password ?
            </NavLink>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
