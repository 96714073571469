import { Box, Grid, Button } from "@mui/material";
import { EmpDataStore } from "../../../../Store";
import FilePresentTwoToneIcon from "@mui/icons-material/FilePresentTwoTone";
export default function LeavesNav() {
  const SetRequestDialog = EmpDataStore((state) => state.SetRequestDialog);
  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
              lg: "block",
              xl: "block",
            },
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
              lg: "block",
              xl: "block",
            },
          }}
        ></Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Button
            size="small"
            fullWidth
            color="success"
            variant="outlined"
            onClick={SetRequestDialog}
          >
            <FilePresentTwoToneIcon fontSize="small" sx={{ mr: 1 }} />
            Request Leave
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
