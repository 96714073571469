import {
  Grid,
  Typography,
  Paper,
  Box,
  TextField,
  MenuItem,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useState } from "react";
import { EmployeesCol2 } from "../../../../Context/DataGrids";
import { EmployeesStore } from "../../../../Store";

export default function EmployeesLister() {
  const EmpList = EmployeesStore((state) => state.EmpList);
  const [ItemsPerPage, SetItemsPerPage] = useState(25);
  const HandleChange = (e) => {
    SetItemsPerPage(e.target.value);
  };
  return (
    <Box sx={{ mt: 3, mb: 3 }}>
      <Typography variant="h5" color={"#2f3193"}>
        IOM Employees List
      </Typography>

      <Paper elevation={3} sx={{ mt: 3, p: 2 }}>
        <Grid container spacing={3} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={12} md={8} lg={8}></Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              select
              size="small"
              fullWidth
              label="Rows Per Page"
              defaultValue={ItemsPerPage}
              onChange={HandleChange}
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={250}>250</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box sx={{ width: "100%" }}>
              <DataGrid
                rows={EmpList}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                  },
                }}
                columns={EmployeesCol2}
                pageSize={ItemsPerPage}
                rowsPerPageOptions={[ItemsPerPage]}
                disableSelectionOnClick
                autoHeight
                width={"100%"}
                disableColumnMenu
                density="compact"
                getRowId={(row) => row.empkey}
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
