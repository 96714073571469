import {
  Grid,
  Typography,
  Paper,
  Box,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { PayrollCol } from "../../../../Context/DataGrids";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useEffect, useState } from "react";
import { PayrollStore } from "../../../../Store";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
export default function ViewPayroll(props) {
  const [DataToView, SetDataToView] = useState([]);
  const [ItemsPerPage, SetItemsPerPage] = useState(10);
  const [PRLoad, SetPRLoad] = useState(false);
  const [SheetName, SetSheetName] = useState("");
  const ChaneDeleteDialog = PayrollStore((state) => state.ChaneDeleteDialog);
  const EditInfoToHandleM = PayrollStore((state) => state.EditInfoToHandleM);
  const EditInfoToHandleY = PayrollStore((state) => state.EditInfoToHandleY);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  useEffect(() => {
    SetDataToView(props.data);
    if (props.datarype === "all") {
      SetSheetName("Payroll From The Start Of Time");
    } else {
      SetSheetName("Payroll For " + props.datadate);
    }
  }, []);
  const ShowTopText = () => {
    if (props.datarype === "all") {
      return (
        <Typography variant="h6" className="text-soc">
          View All Payroll
        </Typography>
      );
    } else {
      return (
        <Typography variant="h6" className="text-soc">
          View Payroll For <span className="dateview">{props.datadate}</span>
        </Typography>
      );
    }
  };
  const DeletePayrollActn = () => {
    EditInfoToHandleY(props.year);
    EditInfoToHandleM(props.month);
    ChaneDeleteDialog();
  };
  const HandleChange = (e) => {
    SetItemsPerPage(e.target.value);
  };
  const exportToCSV = (csvData, fileName) => {
    SetPRLoad(true);
    csvData = [
      {
        id: "#",
        ref: "SOC/REF",
        fullname: "Full Name",
        ct: "Contract Type",
        dpt: "Danger Pay",
        startdate: "Contract Start",
        enddate: "Contract Expiry",
        workinghours: "Working Hours",
        month: "Month",
        year: "Year",
        hub: "Hub",
        dutystation: "Duty Station",
        department: "Department",
        jposition: "Position",
        grade: "Grade",
        staffgrouping: "Staff Grouping",
        type: "Type",
        attendance: "Attendance",
        basesalary: "Base Salary",
        communication: "Communication",
        dpta: "DP/TA",
        hiorg: "Health Insurance By IOM",
        hi: "Health Insurance By Staff",
        ssiom: "Social Security By IOM",
        ssemp: "Social Security By Staff",
        pit: "Personal Income Tax",
        extrafees: "Extra Fees",
        others: "Others/Retro",
        netsalary: "Net Salary",
        socfees: "SOC Fees",
        grosssalary: "Gross Salary",
        finalsalary: "Final Salary",
        actions: "Actions",
        REM: "Remarks",
      },
      ...csvData,
    ];
    setTimeout(() => {
      SetPRLoad(false);
      const ws = XLSX.utils.json_to_sheet(csvData, { skipHeader: true });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }, 2000);
  };
  return (
    <>
      <Paper elevation={3} sx={{ mt: 3, p: 2 }}>
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {ShowTopText()}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <TextField
              select
              size="small"
              fullWidth
              label="Rows Per Page"
              defaultValue={ItemsPerPage}
              onChange={HandleChange}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} sx={{ textAlign: "right" }}>
            <Button
              variant="contained"
              color="success"
              size="small"
              title="Export Payroll To Excel"
              onClick={() => exportToCSV(props.data, SheetName)}
            >
              {PRLoad ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <InsertDriveFileIcon />
              )}
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box sx={{ width: "100%" }}>
              <DataGrid
                rows={DataToView}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                  },
                }}
                columns={PayrollCol}
                pageSize={ItemsPerPage}
                rowsPerPageOptions={[ItemsPerPage]}
                disableSelectionOnClick
                autoHeight
                width={"100%"}
                disableColumnMenu
                density="compact"
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
