import {
  Breadcrumbs,
  Grid,
  Typography,
  Alert,
  AlertTitle,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { EmployeesStore } from "../../../../Store";

export default function BC(props) {
  const EmpData = EmployeesStore((state) => state.EmpData);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink className="breabcrumblink" to="/employee">
            Employee Control Panel
          </NavLink>
          <Typography color="text.primary">{props.data}</Typography>
        </Breadcrumbs>
      </Grid>
      {EmpData[0].email === "" && (
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ my: 3 }}>
          <Alert severity="error" variant="outlined">
            <AlertTitle>
              No Email Address is Associated with the Account
            </AlertTitle>
            <Typography variant="body" sx={{ my: 2 }}>
              Please Provide an Email Address For Your Password Reset, Evaluatio
              Progress And Leaves Notification.
            </Typography>{" "}
            <NavLink to="/employee/generalinfo" className="bolder">
              Click Her To Add Your Email
            </NavLink>
          </Alert>
        </Grid>
      )}
    </Grid>
  );
}
