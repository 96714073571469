import { Box, Grid, Button } from "@mui/material";
import { EmpDataStore } from "../../../../Store";
import StarBorderPurple500TwoToneIcon from "@mui/icons-material/StarBorderPurple500TwoTone";
export default function EvalNav() {
  const SetRequestDialog = EmpDataStore((state) => state.SetRequestDialog);
  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm={12}
          md={9}
          lg={9}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
              lg: "block",
              xl: "block",
            },
          }}
        ></Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Button
            size="small"
            fullWidth
            color="success"
            variant="outlined"
            onClick={SetRequestDialog}
          >
            <StarBorderPurple500TwoToneIcon fontSize="small" sx={{ mr: 1 }} />
            Request Evaluation
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
