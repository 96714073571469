import {
  Grid,
  Box,
  Button,
  Paper,
  Typography,
  Chip,
  Divider,
} from "@mui/material";
import { EmpDataStore } from "../../../../Store";
import HTMLReactParser from "html-react-parser";
import CheckIcon from "@mui/icons-material/Check";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CloseIcon from "@mui/icons-material/Close";
export default function ObjectiveEditor() {
  const Objective = EmpDataStore((state) => state.Objective);
  const ObjectiveOpt = EmpDataStore((state) => state.ObjectiveOpt);
  const ObjectiveApp = EmpDataStore((state) => state.ObjectiveApp);
  const SetObjectiveDialog = EmpDataStore((state) => state.SetObjectiveDialog);
  const SetRequestDialog = EmpDataStore((state) => state.SetRequestDialog);
  return (
    <Paper elevation={3} sx={{ my: 5, p: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6" color={"#2f3193"}>
            Objectives
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {Objective != null ? (
            <Box
              sx={{
                p: 2,
                mt: 2,
              }}
            >
              {HTMLReactParser(Objective)}

              <></>
            </Box>
          ) : (
            <Box
              sx={{
                p: 2,
                mt: 2,
              }}
            >
              Please Set Your Objectives
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          {ObjectiveOpt === 1 ? (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={SetObjectiveDialog}
            >
              Edit Objectives
            </Button>
          ) : (
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={SetRequestDialog}
            >
              Request Permission
            </Button>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
              lg: "block",
              xl: "block",
            },
          }}
        ></Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} sx={{ textAlign: "end" }}>
          {ObjectiveApp === 1 ? (
            <Chip
              label="Aprroved"
              color="primary"
              icon={<CheckIcon />}
              size="small"
              sx={{ fontSize: 10, px: 5, py: 2 }}
            />
          ) : (
            <>
              {ObjectiveApp === 2 ? (
                <Chip
                  label="Declined"
                  color="error"
                  icon={<CloseIcon />}
                  size="small"
                  sx={{ fontSize: 10, px: 5, py: 2 }}
                />
              ) : (
                <Chip
                  label="Waiting Approval"
                  color="warning"
                  icon={<HourglassBottomIcon />}
                  size="small"
                  sx={{ fontSize: 10, px: 5, py: 2 }}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
