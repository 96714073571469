import {
  Paper,
  Grid,
  TextField,
  Button,
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Snackbar,
  Alert,
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { APIURL, STORAGEAPI } from "../../../../Context/configs";
import * as XLSX from "xlsx";
import Cookies from "js-cookie";
import { useState } from "react";
import { FacebookLoader } from "../../../Common/Loaders";

export default function LeaveImporter() {
  const [LeaveBals, SetLeaveBals] = useState([]);
  const [isOpen, SetisOpen] = useState(false);
  const [SnackBarShow, SetSnackBarShow] = useState(false);
  const [SnackBarMessage, ChangeSnackMessage] = useState("");
  const [ImportingLoader, SetImportingLoader] = useState(false);
  const StartUpload = (event) => {
    event.preventDefault();
    let A = [];
    SetImportingLoader(true);
    const f = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      for (let i = 1; i < data.length; i++) {
        A.push({
          al: data[i][1],
          sl: data[i][2],
          pl: data[i][3],
          soc: data[i][0],
        });
      }
    };
    reader.readAsBinaryString(f);
    setTimeout(() => {
      SetLeaveBals(A);
      SetImportingLoader(false);
      SetisOpen(true);
      event.target.value = "";
    }, 3000);
  };
  const handleClose = () => {
    SetisOpen(false);
  };
  const ConfirmData = () => {
    fetch(APIURL + "manager/leaves/leaveimport.php", {
      method: "post",
      body: JSON.stringify({
        data: LeaveBals,
        IDEN: Cookies.get("userID"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          ChangeSnackMessage(item.content);
          SetSnackBarShow(true);
        });
      });
    handleClose();
  };
  const ChaneSnackBarShow = () => {
    SetSnackBarShow(false);
  };
  return (
    <>
      <Box sx={{ mt: 3, mb: 3 }}>
        <Typography variant="h5" color={"#2f3193"}>
          Import Leaves Sheet
        </Typography>
        <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ textAlign: "right" }}
            >
              <Button
                href={STORAGEAPI + "templates/leaves.xlsx"}
                target="_blank"
                variant="outlined"
                color="info"
              >
                <CloudDownloadIcon sx={{ mr: 2 }} />
                Download Template
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                margin="normal"
                required
                fullWidth
                size="small"
                name="file"
                type="file"
                accept=".xlsx"
                onChange={StartUpload}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {ImportingLoader && (
                <Box sx={{ textAlign: "center", pt: 2, pb: 2 }}>
                  <FacebookLoader />
                </Box>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Confirm Data Before Insertion</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "40%" }}>SOC/REF</TableCell>
                  <TableCell align="right" sx={{ width: "20%" }}>
                    Annual Balance
                  </TableCell>
                  <TableCell align="right" sx={{ width: "20%" }}>
                    Sick Balance
                  </TableCell>
                  <TableCell align="right" sx={{ width: "20%" }}>
                    Cumulative Balance
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {LeaveBals.map((row) => (
                  <TableRow key={row.soc}>
                    <TableCell sx={{ width: "40%" }}>{row.soc}</TableCell>
                    <TableCell align="right" sx={{ width: "20%" }}>
                      {row.al}
                    </TableCell>
                    <TableCell align="right" sx={{ width: "20%" }}>
                      {row.sl}
                    </TableCell>
                    <TableCell align="right" sx={{ width: "20%" }}>
                      {row.pl}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="success" onClick={ConfirmData}>
            Confirm Data
          </Button>
          <Button variant="contained" color="error" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={SnackBarShow}
        autoHideDuration={3000}
        onClose={ChaneSnackBarShow}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity="info"
          variant="outlined"
          sx={{
            "& .MuiAlert-message": { textAlign: "center", width: "inherit" },
          }}
        >
          {SnackBarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
