import { RollerLoader } from "../../../Common/Loaders";
import { ObjectiveProcessStore } from "../../../../Store";

export default function Loading() {
  const LoadingShow = ObjectiveProcessStore((state) => state.LoadingShow);
  return (
    <>
      {LoadingShow && (
        <div className="submitLoader">
          <RollerLoader />
        </div>
      )}
    </>
  );
}
