import { Grid } from "@mui/material";
import L1 from "../../../../Assets/IMG/01.png";
import L2 from "../../../../Assets/IMG/02.png";
import L3 from "../../../../Assets/IMG/03.png";
import L4 from "../../../../Assets/IMG/04.png";
import L5 from "../../../../Assets/IMG/05.png";
import L6 from "../../../../Assets/IMG/06.png";
import L7 from "../../../../Assets/IMG/07.png";
import { CircleLoader } from "../../../Common/Loaders";
import { Img } from "react-image";
export default function Logos() {
  return (
    <Grid
      container
      sx={{
        mt: 3,
      }}
      spacing={3}
    >
      <Grid
        item
        lg
        md
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
            xl: "block",
          },
        }}
      >
        <Img className="LoginImage" src={L1} loader={<CircleLoader />} />
      </Grid>
      <Grid
        item
        lg
        md
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
            xl: "block",
          },
        }}
      >
        <Img className="LoginImage" src={L2} loader={<CircleLoader />} />
      </Grid>
      <Grid
        item
        lg
        md
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
            xl: "block",
          },
        }}
      >
        <Img className="LoginImage" src={L3} loader={<CircleLoader />} />
      </Grid>
      <Grid
        item
        lg
        md
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
            xl: "block",
          },
        }}
      >
        <Img className="LoginImage" src={L4} loader={<CircleLoader />} />
      </Grid>
      <Grid
        item
        lg
        md
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
            xl: "block",
          },
        }}
      >
        <Img className="LoginImage" src={L5} loader={<CircleLoader />} />
      </Grid>
      <Grid
        item
        lg
        md
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
            xl: "block",
          },
        }}
      >
        <Img className="LoginImage" src={L6} loader={<CircleLoader />} />
      </Grid>
      <Grid
        item
        lg
        md
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
            xl: "block",
          },
        }}
      >
        <Img className="LoginImage" src={L7} loader={<CircleLoader />} />
      </Grid>
    </Grid>
  );
}
