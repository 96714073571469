import { Paper, Grid, Typography } from "@mui/material";
import { EmpDataStore } from "../../../../Store";
export default function LeaveBalances() {
  const LeaveBalances = EmpDataStore((state) => state.LeaveBalances);
  return (
    <Paper elevation={3} sx={{ mt: 2, p: 2, border: "1px #333 solid" }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Typography variant="body" sx={{ fontWeight: "bold" }}>
            Sick Leave Balance :{" "}
            <Typography variant="body" color={"#e65100"}>
              {LeaveBalances[0].sickleavebalance}
            </Typography>{" "}
            Days
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Typography variant="body" sx={{ fontWeight: "bold" }}>
            Annual Leave Balance :{" "}
            <Typography variant="body" color={"#d50000"}>
              {LeaveBalances[0].normalleavebalance}
            </Typography>{" "}
            Days
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Typography variant="body" sx={{ fontWeight: "bold" }}>
            Cumulative Leave Balance :{" "}
            <Typography variant="body" color={"#9c27b0"}>
              {LeaveBalances[0].previeusbalance}
            </Typography>{" "}
            Days
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
