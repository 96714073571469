import {
  Paper,
  Box,
  TextField,
  Typography,
  Grid,
  Button,
  Alert,
} from "@mui/material";
import { useState } from "react";
import { EmployeesStore } from "../../../../Store";
import SystemSecurityUpdateGoodIcon from "@mui/icons-material/SystemSecurityUpdateGood";
import { APIURL } from "../../../../Context/configs";
import Cookies from "js-cookie";
export default function FRForm() {
  const EmpData = EmployeesStore((state) => state.EmpData);
  const SetEmpData = EmployeesStore((state) => state.SetEmpData);
  const [submitted, setsubmitted] = useState(false);
  const [RespMsg, SetRespMsg] = useState("");
  const [MessageAction, setMessageAction] = useState("success");
  const UpdateInfo = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    fetch(APIURL + "employee/actions/updateContacts.php", {
      method: "post",
      body: JSON.stringify({
        em: data.get("email"),
        ph: data.get("phone"),
        soc: EmpData[0].ref,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetRespMsg(item.content);
          if (item.id === 1) {
            setMessageAction("success");
            setTimeout(() => {
              fetch(APIURL + "employee/general/listinformation.php", {
                method: "post",
                body: JSON.stringify({
                  soc: Cookies.get("userRef"),
                }),
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Request-Headers":
                    "Content-Type, Authorization",
                  "Access-Control-Allow-Credentials": "true",
                },
              })
                .then((res) => res.json())
                .then((result) => {
                  SetEmpData(result);
                });
            }, 3000);
          } else {
            setMessageAction("warning");
          }
          setsubmitted(true);
        });
      });
  };
  return (
    <Paper elevation={3} sx={{ mt: 2, p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6" sx={{ color: "#2f3193" }}>
            Add Conctact Details
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {EmpData.map((e) => (
            <Box
              key={e.ref}
              component="form"
              onSubmit={UpdateInfo}
              sx={{ mt: 1 }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="email"
                    name="email"
                    label="Email Address"
                    variant="standard"
                    fullWidth
                    required
                    type="email"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="phone"
                    name="phone"
                    label="Phone Number"
                    variant="standard"
                    fullWidth
                    type="phone"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {submitted && (
                    <Alert variant="outlined" severity={MessageAction}>
                      {RespMsg}
                    </Alert>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ textAlign: "right" }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    type="submit"
                  >
                    <SystemSecurityUpdateGoodIcon
                      fontSize="small"
                      sx={{ mr: 1 }}
                    />{" "}
                    Update Info
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
}
