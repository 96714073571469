import { Box, Container, Grid } from "@mui/material";
import LOGO from "../../../Assets/IMG/logo.png";
import { Img } from "react-image";
import { CircleLoader, RollerLoader } from "../../Common/Loaders";
import { Routes, Route, useParams } from "react-router-dom";
import {
  View,
  FocalPoint,
  HeadOfSubOffice,
  EvaluatoinHeader,
  EvaluationData,
  ActionMessage,
  Loading,
  OtherEvaluation,
} from "../Elements/Evaluation";
import { EvaluationProceessStore } from "../../../Store";
import { useEffect, useState } from "react";
import { APIURL } from "../../../Context/configs";
import LoginFooter from "../Elements/LoginPage/LoginFooter";

export default function Evaluation() {
  const { ev } = useParams();
  const [isLoading, setisLoading] = useState(true);
  const SetEval = EvaluationProceessStore((state) => state.SetEval);
  useEffect(() => {
    document.title = "StarsOrbit HRM - Evaluation";
    fetch(APIURL + "portalhome/evaluation/viewevaluation.php", {
      method: "post",
      body: JSON.stringify({
        ev: ev,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setisLoading(false);
        SetEval(result);
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <Box className="siteLoader">
          <RollerLoader />
        </Box>
      ) : (
        <Box sx={{ my: 2 }}>
          <Container maxWidth="lg">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Img
                  className="LoginImage"
                  src={LOGO}
                  loader={<CircleLoader />}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <EvaluatoinHeader />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Routes>
                  <Route exact path="/" element={<View />} />
                  <Route exact path="/focalpoint" element={<FocalPoint />} />
                  <Route
                    exact
                    path="/headofsuboffice"
                    element={<HeadOfSubOffice />}
                  />
                  <Route exact path="/view" element={<View />} />
                </Routes>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <EvaluationData />
                <OtherEvaluation />
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
      <LoginFooter />
      <ActionMessage />
      <Loading />
    </>
  );
}
