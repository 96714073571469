import { TrainingStore } from "../../../../Store";
import { Paper, Button, Grid, Typography, Box } from "@mui/material";
export default function TrainingList() {
  const DataToShow = TrainingStore((state) => state.DataToShow);
  return (
    <Paper elevation={3} sx={{ border: "1px #999 solid", mt: 4, mb: 4, p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h5" className="text-soc">
            Current Training
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {DataToShow === null ? (
            <Typography
              variant="h5"
              textAlign={"center"}
              fontWeight={"bold"}
              marginY={2}
            >
              No Data To Preview
            </Typography>
          ) : (
            <Box>
              {DataToShow.map((t) => (
                <Box key={t.tr_id} sx={{ my: 2 }}>
                  <Button
                    href={t.tr_link}
                    target="_blank"
                    color="primary"
                    fullWidth
                    variant="contained"
                  >
                    {t.tr_name}
                  </Button>
                </Box>
              ))}
            </Box>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
