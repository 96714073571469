import { Snackbar, Alert } from "@mui/material";
import { EmpDataStore } from "../../../../Store";

export default function ActionMessage() {
  const SetSnackBarShow = EmpDataStore((state) => state.SetSnackBarShow);
  const SnackBarShow = EmpDataStore((state) => state.SnackBarShow);
  const SnackBarMessage = EmpDataStore((state) => state.SnackBarMessage);
  const SnackBarType = EmpDataStore((state) => state.SnackBarType);
  return (
    <Snackbar
      autoHideDuration={3000}
      open={SnackBarShow}
      onClose={SetSnackBarShow}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        onClose={SetSnackBarShow}
        variant="filled"
        severity={SnackBarType}
        sx={{ width: "100%" }}
      >
        {SnackBarMessage}
      </Alert>
    </Snackbar>
  );
}
