import NewFeatures from "../Elements/WhatsNew/NewFeatures";
import { Container, Grid, Breadcrumbs, Typography, Paper } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { RingLoader } from "../../Common/Loaders";
export default function WhatsNew() {
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    document.title = "StarsOrbit HRM - Whats's New";
    setTimeout(() => {
      setisLoading(false);
    }, 3000);
  }, []);

  return (
    <Container maxWidth={false} sx={{ mt: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink className="breabcrumblink" to="/iom">
              IOM HR Panel
            </NavLink>
            <Typography color="text.primary">Whats</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Typography variant="h5" color={"#2f3193"} sx={{ mb: 3, mt: 3 }}>
        New Features
      </Typography>
      {isLoading ? (
        <Paper elevation={3} className="siteLoader">
          <RingLoader />
        </Paper>
      ) : (
        <NewFeatures />
      )}
    </Container>
  );
}
