import { Grid, Typography, Box, Button, Paper, TextField } from "@mui/material";
import Cookies from "js-cookie";
import { APIURL } from "../../../../Context/configs";
import { SystemConfigStore } from "../../../../Store";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
export default function SystemSetting() {
  const SysConfShow = SystemConfigStore((state) => state.SysConfShow);
  const SetSysConfShow = SystemConfigStore((state) => state.SetSysConfShow);
  const ChaneSnackBarShow = SystemConfigStore(
    (state) => state.ChaneSnackBarShow
  );
  const ChangeSnackMessage = SystemConfigStore(
    (state) => state.ChangeSnackMessage
  );
  const UpdateSetting = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    fetch(APIURL + "manager/portaldata/updatesysconf.php", {
      method: "post",
      body: JSON.stringify({
        value: data.get("value"),
        confkey: data.get("confkey"),
        confname: data.get("confname"),
        IDEN: Cookies.get("userID"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          ChangeSnackMessage(item.content);
          if (item.id == 1) {
            ChaneSnackBarShow();
            fetch(APIURL + "manager/portaldata/systemconfig.php", {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Request-Headers": "Content-Type, Authorization",
                "Access-Control-Allow-Credentials": "true",
              },
            })
              .then((res) => res.json())
              .then((result) => {
                SetSysConfShow(result);
              });
          }
        });
      });
  };
  return (
    <Paper elevation={3} sx={{ mt: 2, p: 3 }}>
      <Typography variant="h5" color={"#2f3193"} sx={{ my: 2 }}>
        System Setting
      </Typography>
      {SysConfShow.map((c) => (
        <Box
          component="form"
          onSubmit={UpdateSetting}
          noValidate
          className="border-bottom"
          sx={{ p: 2 }}
          key={c.configid}
        >
          <Grid container spacing={3}>
            <Grid item xs={8} sm={8} md={10} lg={10}>
              <Typography variant="body">{c.configname}</Typography>
              <div className="d-none">
                <TextField
                  type="hidden"
                  id="confname"
                  name="confname"
                  value={c.configname}
                />
                <TextField
                  type="hidden"
                  id="confkey"
                  name="confkey"
                  value={c.configkey}
                />
              </div>
            </Grid>
            <Grid item xs={4} sm={4} md={2} lg={2}>
              {c.configvalue === 1 ? (
                <>
                  <div className="d-none">
                    <TextField
                      type="hidden"
                      id="value"
                      name="value"
                      value={0}
                    />
                  </div>
                  <Button
                    size="small"
                    fullWidth
                    color="error"
                    variant="contained"
                    type="submit"
                  >
                    <CancelIcon fontSize="small" sx={{ mr: 2 }} />
                    De Activate
                  </Button>
                </>
              ) : (
                <>
                  <div className="d-none">
                    <TextField
                      type="hidden"
                      id="value"
                      name="value"
                      value={1}
                    />
                  </div>
                  <Button
                    size="small"
                    fullWidth
                    color="success"
                    variant="contained"
                    type="submit"
                  >
                    <CheckCircleIcon fontSize="small" sx={{ mr: 2 }} />
                    Activate
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      ))}
    </Paper>
  );
}
