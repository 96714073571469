import { create } from "zustand";
const ObjectiveProcessStore = create((set) => ({
  Objective: [],
  SnackBarShow: false,
  SnackBarType: "success",
  SnackBarMessage: "",
  LoadingShow: false,
  SetObjective: (data) => set((state) => ({ Objective: data })),
  SetSnackBarShow: () =>
    set((state) => ({ SnackBarShow: !state.SnackBarShow })),
  SetLoadingShow: () => set((state) => ({ LoadingShow: !state.LoadingShow })),
  SetSnackMessage: (mes) => set((state) => ({ SnackBarMessage: mes })),
  SetSnackBarType: (t) => set((state) => ({ SnackBarType: t })),
}));

export default ObjectiveProcessStore;
