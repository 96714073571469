import { Box, Button } from "@mui/material";
import { Fragment } from "react";
import { EmployeesStore } from "../../../../Store/";
import Cookies from "js-cookie";
import { APIURL } from "../../../../Context/configs";
export default function EmpStatus() {
  const EmpData = EmployeesStore((state) => state.EmpData);
  const SetEmpData = EmployeesStore((state) => state.SetEmpData);
  const ChaneSnackBarShow = EmployeesStore((state) => state.ChaneSnackBarShow);
  const ChangeSnackMessage = EmployeesStore(
    (state) => state.ChangeSnackMessage
  );
  const ChangeStatus = (p1, p2, p3, p4, p5) => {
    fetch(APIURL + "manager/employees/employeestatuschange.php", {
      method: "post",
      body: JSON.stringify({
        soc: p1,
        fn: p2,
        lid: p3,
        st: p4,
        IDEN: Cookies.get("userID"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          ChangeSnackMessage(item.content);
          if (item.id == 1) {
            ChaneSnackBarShow();
            fetch(APIURL + "manager/employees/employeeinformation.php", {
              method: "post",
              body: JSON.stringify({
                empkey: p5,
              }),
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Request-Headers": "Content-Type, Authorization",
                "Access-Control-Allow-Credentials": "true",
              },
            })
              .then((res) => res.json())
              .then((result) => {
                SetEmpData(result);
              });
          }
        });
      });
  };
  return (
    <Box sx={{ mt: 5 }}>
      {EmpData.map((emp) => (
        <Fragment key={emp.lid}>
          {emp.status === 1 ? (
            <Button
              size="small"
              fullWidth
              variant="contained"
              color="success"
              onClick={() =>
                ChangeStatus(emp.ref, emp.fullname, emp.lid, 0, emp.empkey)
              }
            >
              De Activate
            </Button>
          ) : (
            <Button
              size="small"
              fullWidth
              variant="contained"
              color="error"
              onClick={() =>
                ChangeStatus(emp.ref, emp.fullname, emp.lid, 1, emp.empkey)
              }
            >
              Activate
            </Button>
          )}
        </Fragment>
      ))}
    </Box>
  );
}
