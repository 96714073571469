import { create } from "zustand";
const SoftwareStore = create((set) => ({
  DataToShow: [],
  ShowDeleteDialog: false,
  InfoToHandleM: 0,
  InfoToHandleY: 0,
  SnackBarShow: false,
  SnackBarMessage: "",
  NewPayShow: false,
  LoadingShow: false,
  SetDataToShow: (data) => set((state) => ({ DataToShow: data })),
  EditInfoToHandleM: (inf) => set((state) => ({ InfoToHandleM: inf })),
  EditInfoToHandleY: (inf) => set((state) => ({ InfoToHandleY: inf })),
  ChaneDeleteDialog: () =>
    set((state) => ({ ShowDeleteDialog: !state.ShowDeleteDialog })),
  ChaneSnackBarShow: () =>
    set((state) => ({ SnackBarShow: !state.SnackBarShow })),
  ChaneLoadingShow: () => set((state) => ({ LoadingShow: !state.LoadingShow })),
  ChaneNewPayShow: () => set((state) => ({ NewPayShow: !state.NewPayShow })),
  ChangeSnackMessage: (mes) => set((state) => ({ SnackBarMessage: mes })),
}));

export default SoftwareStore;
