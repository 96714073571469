import { Paper, TextField, Typography, Box } from "@mui/material";
import { EmpDataStore, EmployeesStore } from "../../../../Store";
import { APIURL } from "../../../../Context/configs";
import axios from "axios";
import { ClassNames } from "@emotion/react";
export default function FileUploader() {
  const EmpData = EmployeesStore((state) => state.EmpData);
  const SetFileList = EmpDataStore((state) => state.SetFileList);
  const SetSnackMessage = EmpDataStore((state) => state.SetSnackMessage);
  const SetSnackBarType = EmpDataStore((state) => state.SetSnackBarType);
  const SetSnackBarShow = EmpDataStore((state) => state.SetSnackBarShow);
  const SetLoadingShow = EmpDataStore((state) => state.SetLoadingShow);
  const startUpload = (event) => {
    if (event.target.value != null) {
      SetLoadingShow();
      const formData = new FormData();
      formData.append("f", event.target.files[0]);
      formData.append("soc", EmpData[0].ref);
      formData.append("dir", EmpData[0].empkey);
      axios
        .post(APIURL + "employee/documents/newdocument.php", formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then(function (response) {
          response.data.forEach((item) => {
            SetSnackMessage(item.data);
            if (item.id === 1) {
              SetSnackBarType("success");
              fetch(APIURL + "employee/documents/listdocuments.php", {
                method: "post",
                body: JSON.stringify({
                  soc: EmpData[0].ref,
                }),
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Request-Headers":
                    "Content-Type, Authorization",
                  "Access-Control-Allow-Credentials": "true",
                },
              })
                .then((res) => res.json())
                .then((result) => {
                  SetFileList(result);
                });
            } else {
              SetSnackBarType("error");
            }
            SetSnackBarShow();
            SetLoadingShow();
          });
        });
      event.target.value = null;
    }
  };
  return (
    <Paper elevation={3} sx={{ p: 2, my: 3 }}>
      <Typography variant="h6" color={"#2f3193"}>
        Upload A File
      </Typography>
      <Box sx={{ my: 1 }}>
        <TextField
          variant="standard"
          margin="normal"
          required
          fullWidth
          type="file"
          size="small"
          id="rpt"
          onChange={startUpload}
          name="rpt"
        />
      </Box>
      <Box sx={{ my: 1, textAlign: "center" }} className="text-small">
        Allowed Files (
        <span className="dateview">
          'gif', 'png',
          'jpg','jpeg','doc','docx','xls','xlsx','ppt','pptx','ppsx','pdf','txt','zip','rar','mp4','mp3'
        </span>
        )
      </Box>
      <Box sx={{ my: 1, textAlign: "center" }} className="text-small">
        Maximum File Size (<span className="dateview">30 mb</span>)
      </Box>
    </Paper>
  );
}
