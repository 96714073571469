import { create } from "zustand";
const EvaluationStore = create((set) => ({
  Evaluated: [],
  SetEvaluated: (data) => set((state) => ({ Evaluated: data })),
  NotEvaluated: [],
  SetNotEvaluated: (data) => set((state) => ({ NotEvaluated: data })),
  ReqOBJ: [],
  SetReqOBJ: (data) => set((state) => ({ ReqOBJ: data })),
  AppOBJ: [],
  SetAppOBJ: (data) => set((state) => ({ AppOBJ: data })),
  NotRObj: [],
  SetNotRObj: (data) => set((state) => ({ NotRObj: data })),
}));

export default EvaluationStore;
