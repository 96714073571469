import { Paper, Grid, Typography, Box, TextField, Button } from "@mui/material";
import { EmployeesStore } from "../../../../Store/";
import { APIURL } from "../../../../Context/configs";
import { useState, useEffect } from "react";
import Calendar from "rc-year-calendar";
import { EllipsisLoader } from "../../../Common/Loaders";
import CircleIcon from "@mui/icons-material/Circle";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { EmpLeaveCol } from "../../../../Context/DataGrids";
export default function Leaves() {
  const [LB, SetLB] = useState([]);
  const [LL, SetLL] = useState([]);
  const [LT, SetLT] = useState([]);
  const [CalendarData] = useState([]);
  const [isLoading, SetisLoading] = useState(true);
  const EmpData = EmployeesStore((state) => state.EmpData);
  useEffect(() => {
    fetch(APIURL + "organization/employees/employeeleavbal.php", {
      method: "post",
      body: JSON.stringify({
        soc: EmpData[0].ref,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetLB(result);
      });
    fetch(APIURL + "organization/employees/employeeleavestaken.php", {
      method: "post",
      body: JSON.stringify({
        soc: EmpData[0].ref,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetLT(result);
      });
    fetch(APIURL + "organization/employees/employeeleaves.php", {
      method: "post",
      body: JSON.stringify({
        soc: EmpData[0].ref,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetLL(result);
        if (result != null) {
          result.forEach((lv) => {
            let element;
            if (lv.hoso_status === 0) {
              element = {
                location: lv.reason,
                startDate: new Date(lv.fd),
                endDate: new Date(lv.ed),
                color: "#263238",
              };
            } else {
              if (
                lv.leave_type === "Uncertified Sick Leave" ||
                lv.leave_type === "Uncertified Sick Leave 1/2 Day"
              ) {
                element = {
                  location: lv.reason,
                  startDate: new Date(lv.fd),
                  endDate: new Date(lv.ed),
                  color: "#0d47a1",
                };
              } else if (lv.leave_type === "Certified Sick Leave") {
                element = {
                  location: lv.reason,
                  startDate: new Date(lv.fd),
                  endDate: new Date(lv.ed),
                  color: "#b71c1c",
                };
              } else if (lv.leave_type === "Maternity Leave") {
                element = {
                  location: lv.reason,
                  startDate: new Date(lv.fd),
                  endDate: new Date(lv.ed),
                  color: "#f50057",
                };
              } else if (
                lv.leave_type === "Annual Leave" ||
                lv.leave_type === "Annual Leave 1/2 Day"
              ) {
                element = {
                  location: lv.reason,
                  startDate: new Date(lv.fd),
                  endDate: new Date(lv.ed),
                  color: "#e65100",
                };
              } else if (lv.leave_type === "R & R Leave") {
                element = {
                  location: lv.reason,
                  startDate: new Date(lv.fd),
                  endDate: new Date(lv.ed),
                  color: "#3e2723",
                };
              } else {
                element = {
                  location: lv.reason,
                  startDate: new Date(lv.fd),
                  endDate: new Date(lv.ed),
                  color: "#1b5e20",
                };
              }
            }
            CalendarData.push(element);
          });
        }
      });
    SetisLoading(false);
  }, [CalendarData]);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
          <Typography variant="h6" color={"#2f3193"} sx={{ mb: 2 }}>
            Current Balances
          </Typography>
          {LB.map((leavbal) => (
            <Grid key={leavbal.soc_ref} container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  fullWidth
                  type="number"
                  id="ab"
                  size="small"
                  inputProps={{ step: ".5" }}
                  name="ab"
                  label="Annual Leave Balance"
                  defaultValue={leavbal.normalleavebalance}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  fullWidth
                  type="number"
                  id="sb"
                  size="small"
                  name="sb"
                  inputProps={{ step: ".5" }}
                  label="Sick Leave Balance"
                  defaultValue={leavbal.sickleavebalance}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  fullWidth
                  type="number"
                  id="cb"
                  size="small"
                  name="cb"
                  inputProps={{ step: ".5" }}
                  label="Cumulative Leave Balance"
                  defaultValue={leavbal.previeusbalance}
                  disabled
                />
              </Grid>
            </Grid>
          ))}
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
          <Typography variant="h6" color={"#2f3193"} sx={{ mb: 2 }}>
            Leaves List
          </Typography>
          <Box sx={{ mb: 2 }}>
            {LL != null ? (
              <DataGrid
                rows={LL}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                  },
                }}
                columns={EmpLeaveCol}
                pageSize={25}
                rowsPerPageOptions={[25]}
                disableSelectionOnClick
                autoHeight
                width={"100%"}
                disableColumnMenu
                density="compact"
                getRowId={(row) => row.leave_id}
              />
            ) : (
              <Box sx={{ p: 5, textAlign: "center" }}>
                <Typography variant="h6">No Data To Preview</Typography>
              </Box>
            )}
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={9}>
        <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
          <Typography variant="h6" color={"#2f3193"} sx={{ mb: 2 }}>
            Leaves Calendar
          </Typography>
          {isLoading ? (
            <Box sx={{ mb: 2, py: 5, textAlign: "center" }}>
              <EllipsisLoader />
            </Box>
          ) : (
            <Box sx={{ mb: 2 }}>
              <Calendar
                displayHeader={false}
                enableRangeSelection={true}
                dataSource={CalendarData}
              />
            </Box>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={3}>
        <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
          <Typography variant="h6" color={"#2f3193"} sx={{ mb: 2 }}>
            Leaves Information
          </Typography>
          {LL != null ? (
            <Box sx={{ mb: 2, fontSize: "10pt" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                  color: "#263238",
                }}
              >
                <CircleIcon fontSize="12px" sx={{ mr: 2 }} />
                <Typography variant="body">Awaiting Approval</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                  color: "#0d47a1",
                }}
              >
                <CircleIcon fontSize="12px" sx={{ mr: 2 }} />
                <Typography variant="body">Uncertified Sick Leave</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                  color: "#b71c1c",
                }}
              >
                <CircleIcon fontSize="12px" sx={{ mr: 2 }} />
                <Typography variant="body">Certified Sick Leave</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                  color: "#e65100",
                }}
              >
                <CircleIcon fontSize="12px" sx={{ mr: 2 }} />
                <Typography variant="body">Annual Leave</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                  color: "#f50057",
                }}
              >
                <CircleIcon fontSize="12px" sx={{ mr: 2 }} />
                <Typography variant="body">Maternity Leave</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                  color: "#3e2723",
                }}
              >
                <CircleIcon fontSize="12px" sx={{ mr: 2 }} />
                <Typography variant="body">R & R Leave</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                  color: "#1b5e20",
                }}
              >
                <CircleIcon fontSize="12px" sx={{ mr: 2 }} />
                <Typography variant="body">Others</Typography>
              </Box>
            </Box>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box sx={{ p: 5, textAlign: "center" }}>
                <Typography variant="body">No Data To Preview</Typography>
              </Box>
            </Grid>
          )}
        </Paper>
        <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
          <Typography variant="h6" color={"#2f3193"} sx={{ mb: 2 }}>
            Taken Leaves
          </Typography>
          {LL != null ? (
            <>
              {LT.map((l) => (
                <Box key="1" sx={{ mb: 2, fontSize: "10pt" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                      color: "#263238",
                    }}
                  >
                    <CircleIcon fontSize="12px" sx={{ mr: 2 }} />
                    <Typography variant="body">
                      Awaiting Approval : {l.AA === null ? "0" : l.AA} Days
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                      color: "#0d47a1",
                    }}
                  >
                    <CircleIcon fontSize="12px" sx={{ mr: 2 }} />
                    <Typography variant="body">
                      Uncertified Sick Leave : {l.USL === null ? "0" : l.USL}{" "}
                      Days
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                      color: "#b71c1c",
                    }}
                  >
                    <CircleIcon fontSize="12px" sx={{ mr: 2 }} />
                    <Typography variant="body">
                      Certified Sick Leave : {l.CSL === null ? "0" : l.CSL} Days
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                      color: "#e65100",
                    }}
                  >
                    <CircleIcon fontSize="12px" sx={{ mr: 2 }} />
                    <Typography variant="body">
                      Annual Leave : {l.AL === null ? "0" : l.AL} Days
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                      color: "#f50057",
                    }}
                  >
                    <CircleIcon fontSize="12px" sx={{ mr: 2 }} />
                    <Typography variant="body">
                      Maternity Leave : {l.ML === null ? "0" : l.ML} Days
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                      color: "#3e2723",
                    }}
                  >
                    <CircleIcon fontSize="12px" sx={{ mr: 2 }} />
                    <Typography variant="body">
                      R & R Leave : {l.RRL === null ? "0" : l.RRL} Days
                    </Typography>
                  </Box>
                </Box>
              ))}
            </>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box sx={{ p: 5, textAlign: "center" }}>
                <Typography variant="body">No Data To Preview</Typography>
              </Box>
            </Grid>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
