import { Box, Typography } from "@mui/material";

export default function BugsRepHeader() {
  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="body2">
        This Form is to be used when you find some of the portal functionalities
        is not working or working in a wrong way,{" "}
        <Typography variant="body" sx={{ color: "#ff6f00" }}>
          Be Advised that reports from this form will be sent to the portal
          developer (Minass Solutions) not to Stars Orbit Team
        </Typography>
        ,{" "}
        <Typography variant="body" sx={{ mt: 1, color: "#d50000" }}>
          Any report have a content not related with what this form is for will
          be ignonred
        </Typography>
        .
      </Typography>
    </Box>
  );
}
