import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  Box,
  TextField,
} from "@mui/material";
import { EmpDataStore, EmployeesStore } from "../../../../Store";
import { APIURL } from "../../../../Context/configs";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import { useEffect, useState } from "react";
import { GridLoader } from "../../../Common/Loaders";

export default function SecondLevel() {
  const EmpData = EmployeesStore((state) => state.EmpData);
  const SecondDialog = EmpDataStore((state) => state.SecondDialog);
  const SetSecondDialog = EmpDataStore((state) => state.SetSecondDialog);
  const SetSnackMessage = EmpDataStore((state) => state.SetSnackMessage);
  const SetSnackBarType = EmpDataStore((state) => state.SetSnackBarType);
  const SetLoadingShow = EmpDataStore((state) => state.SetLoadingShow);
  const SetSnackBarShow = EmpDataStore((state) => state.SetSnackBarShow);
  const EvalToDo = EmpDataStore((state) => state.EvalToDo);
  const [ShowDiaCont, SetShowDiaCont] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      SetShowDiaCont(true);
    }, 3000);
  }, []);

  const CloseDialog = () => {
    SetSecondDialog();
  };
  const sendRequest = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    fetch(APIURL + "employee/evaluation/requestsecondevaluation.php", {
      method: "post",
      body: JSON.stringify({
        ev: EvalToDo,
        soc: data.get("soc"),
        fn: data.get("fullname"),
        sn: data.get("svn"),
        se: data.get("sve"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetSnackMessage(item.content);
          if (item.id === 1) {
            SetSnackBarType("success");
            setTimeout(() => {
              SetSnackBarShow();
              SetLoadingShow();
              CloseDialog();
            }, 2000);
          } else {
            SetSnackBarType("error");
            setTimeout(() => {
              SetSnackBarShow();
              SetLoadingShow();
              CloseDialog();
            }, 2000);
          }
        });
      });
  };
  return (
    <Dialog open={SecondDialog} onClose={CloseDialog} maxWidth="md" fullWidth>
      <DialogTitle color={"#2f3193"}>
        Request Evaluation{" "}
        <span className="dateview">(Second Level Supervisor)</span>
      </DialogTitle>
      <DialogContent>
        {ShowDiaCont ? (
          <Box
            component="form"
            onSubmit={sendRequest}
            noValidate
            sx={{ mt: 1 }}
          >
            {EmpData.map((e) => (
              <Grid key={e.lid} container spacing={3}>
                <div className="d-none">
                  <TextField id="soc" name="soc" defaultValue={e.ref} />
                  <TextField id="ev" name="ev" defaultValue={EvalToDo} />
                  <TextField
                    id="fullname"
                    name="fullname"
                    defaultValue={e.fullname}
                  />
                </div>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    size="small"
                    id="svn"
                    label="Second Level Supervisor Name"
                    name="svn"
                    autoComplete="svn"
                    autoFocus
                    defaultValue={e.hos}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    size="small"
                    id="sve"
                    label="Second Level Supervisor Email"
                    name="sve"
                    autoComplete="sve"
                    defaultValue={e.hosemal}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ textAlign: "right" }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    <SendTwoToneIcon fontSize="small" sx={{ mr: 1 }} />
                    Send Request
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="error"
                    sx={{ mt: 3, mb: 2, ml: 2 }}
                    onClick={CloseDialog}
                  >
                    Close Form
                  </Button>
                </Grid>
              </Grid>
            ))}
          </Box>
        ) : (
          <Box sx={{ py: 5, textAlign: "center" }}>
            <GridLoader />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
