import { Box, Typography } from "@mui/material";

export default function ComplainHeader() {
  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="body2">
        This Form is to be used when have a complain about any work issue or
        wrong data in your profile,{" "}
        <Typography variant="body" sx={{ color: "#ff6f00" }}>
          Be Advised that reports from this form will be sent to Stars Orbit
          Team
        </Typography>
        .
      </Typography>
    </Box>
  );
}
