import { Typography, Box, Paper, TableContainer } from "@mui/material";
import { SystemConfigStore } from "../../../../Store";
import { useEffect, useState } from "react";
import { FacebookLoader } from "../../../Common/Loaders";
import { LogsCol } from "../../../../Context/DataGrids";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
export default function SystemLogs() {
  const [isLoading, setisLoading] = useState(true);
  const [DataToView, SetDataToView] = useState([]);
  const LogsShow = SystemConfigStore((state) => state.LogsShow);
  useEffect(() => {
    SetDataToView(LogsShow);
    setTimeout(() => {
      setisLoading(false);
    }, 3000);
  }, []);
  return (
    <Paper elevation={3} sx={{ mt: 2, p: 3 }}>
      <Typography variant="h5" color={"#2f3193"} sx={{ my: 2 }}>
        System Logs
      </Typography>
      {isLoading ? (
        <Box sx={{ width: "100%", textAlign: "center", p: 3 }}>
          <FacebookLoader />
        </Box>
      ) : (
        <Box
          sx={{ width: "100%", textAlign: "center", p: 3 }}
          className="text-small"
        >
          {DataToView != null && (
            <DataGrid
              rows={DataToView}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              columns={LogsCol}
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              autoHeight
              disableColumnMenu
              density="compact"
              sx={{ flexGrow: 1 }}
            />
          )}
        </Box>
      )}
    </Paper>
  );
}
