import {
  Paper,
  TextField,
  MenuItem,
  Typography,
  Grid,
  Box,
  Button,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { NotEvaluatedCol } from "../../../../Context/DataGrids";
import { EvaluationStore } from "../../../../Store";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import { RingLoader } from "../../../Common/Loaders";
import { APIURL } from "../../../../Context/configs";
export default function NotEvaluated() {
  const [ItemsPerPage, SetItemsPerPage] = useState(10);
  const [isLoading, SetisLoading] = useState(true);
  const DataToView = EvaluationStore((state) => state.NotEvaluated);
  const SetNotEvaluated = EvaluationStore((state) => state.SetNotEvaluated);
  useEffect(() => {
    fetch(APIURL + "organization/evaluation/evaluationsnotrequested.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetNotEvaluated(result);
        SetisLoading(false);
      });
  }, []);
  const HandleChange = (e) => {
    SetItemsPerPage(e.target.value);
  };
  const SendReminder = () => {
    console.log("reminder");
  };
  return (
    <Paper elevation={3} sx={{ mt: 3, p: 2 }}>
      {isLoading ? (
        <Box className="siteLoader">
          <RingLoader />
        </Box>
      ) : (
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h6" className="text-soc">
              View Not Evaluated Employees
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            {DataToView === null ? null : (
              <TextField
                select
                size="small"
                fullWidth
                label="Rows Per Page"
                defaultValue={ItemsPerPage}
                onChange={HandleChange}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </TextField>
            )}
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} sx={{ textAlign: "right" }}>
            {DataToView === null ? null : (
              <Button
                size="small"
                variant="contained"
                color="error"
                onClick={SendReminder}
              >
                <TipsAndUpdatesIcon sx={{ mr: 1 }} fontSize="small" /> Send
                Reminder
              </Button>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 3 }}>
            <Box sx={{ width: "100%" }}>
              {DataToView === null ? (
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  No Data To Preview
                </Typography>
              ) : (
                <DataGrid
                  rows={DataToView}
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 250 },
                    },
                  }}
                  columns={NotEvaluatedCol}
                  pageSize={ItemsPerPage}
                  rowsPerPageOptions={[ItemsPerPage]}
                  disableSelectionOnClick
                  autoHeight
                  disableColumnMenu
                  density="compact"
                />
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}
