import {
  Paper,
  TextField,
  Typography,
  Grid,
  Box,
  Button,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import { APIURL } from "../../../../Context/configs";
import { FacebookLoader } from "../../../Common/Loaders";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { ExportEvaluattionCol } from "../../../../Context/DataGrids";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
export default function ExportEvaluations() {
  const [DataLoader, SetDataLoader] = useState(false);
  const [ShowData, SetShowData] = useState(false);
  const [DataToView, SetDataToView] = useState([]);
  const [ItemsPerPage, SetItemsPerPage] = useState(10);
  const [PRLoad, SetPRLoad] = useState(false);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const HandleChange = (e) => {
    SetItemsPerPage(e.target.value);
  };
  const LoadData = (event) => {
    event.preventDefault();
    SetDataLoader(true);
    SetShowData(true);
    const data = new FormData(event.currentTarget);

    fetch(APIURL + "manager/evaluation/evaluationsexport.php", {
      method: "post",
      body: JSON.stringify({
        sd: data.get("sd"),
        ed: data.get("ed"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetDataToView(result);
        SetDataLoader(false);
      });
  };
  const exportToCSV = (csvData, fileName) => {
    SetPRLoad(true);
    csvData = [
      {
        id: "#",
        ref: "SOC/REF",
        fullname: "Full Name",
        email: "Email",
        req_formonth: "Month",
        req_foryear: "Year",
        req_date: "Request Date",
        hub: "Hub",
        dutystation: "Duty Station",
        department: "Divison",
        jposition: "Position",
        objectives: "Objectives",
        contract_duration: "Contract Duration",
        ds_name: "Direct Supervisor",
        rating: "Rating",
        ds_comment: "Comment",
        ds_comment_date: "Comment Date",
        sl_name: "Head of Sub Office",
        sl_comment: "Comment",
        sl_comment_date: "Comment Date",
        staff_agreement: "Staff Agreement",
        staff_comment: "Comment",
        staff_comment_date: "Comment Date",
        progress: "Progress",
      },
      ...csvData,
    ];
    setTimeout(() => {
      SetPRLoad(false);
      const ws = XLSX.utils.json_to_sheet(csvData, { skipHeader: true });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }, 2000);
  };
  return (
    <Paper elevation={3} sx={{ mt: 3, p: 2 }}>
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6" className="text-soc">
            Export Evaluation
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5}></Grid>
      </Grid>
      <Box component="form" onSubmit={LoadData}>
        <Grid container spacing={1} sx={{ mb: 3 }}>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <TextField
              size="small"
              fullWidth
              type="date"
              name="sd"
              helperText="Select Starting Date"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <TextField
              size="small"
              fullWidth
              type="date"
              name="ed"
              helperText="Select Ending Date"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Button variant="contained" fullWidth color="success" type="submit">
              Fetch Data
            </Button>
          </Grid>
        </Grid>
      </Box>
      {ShowData && (
        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {DataLoader ? (
              <Box className="siteLoader">
                <FacebookLoader />
              </Box>
            ) : (
              <Box sx={{ width: "100%" }}>
                {DataToView === null && (
                  <Typography variant="h6" sx={{ textAlign: "center", py: 4 }}>
                    No Data To Preview
                  </Typography>
                )}
                {DataToView != null && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        select
                        size="small"
                        fullWidth
                        label="Rows Per Page"
                        defaultValue={ItemsPerPage}
                        onChange={HandleChange}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      sx={{ textAlign: "right" }}
                    >
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        title="Export Payroll To Excel"
                        onClick={() =>
                          exportToCSV(DataToView, "Evaluation List")
                        }
                      >
                        {PRLoad ? (
                          <CircularProgress
                            size={24}
                            color="inherit"
                            sx={{ mr: 1 }}
                          />
                        ) : (
                          <InsertDriveFileIcon sx={{ mr: 1 }} />
                        )}
                        Export To Excel
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <DataGrid
                        rows={DataToView}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            csvOptions: { disableToolbarButton: true },
                            printOptions: { disableToolbarButton: true },
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 250 },
                          },
                        }}
                        columns={ExportEvaluattionCol}
                        pageSize={ItemsPerPage}
                        rowsPerPageOptions={[ItemsPerPage]}
                        disableSelectionOnClick
                        autoHeight
                        disableColumnMenu
                        density="compact"
                      />
                    </Grid>
                  </Grid>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}
