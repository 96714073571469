import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
  Grid,
  Button,
  Typography,
  Alert,
} from "@mui/material";
import Cookies from "js-cookie";
import { useState, Fragment } from "react";
import { IOMStore } from "../../../../Store";
import { APIURL } from "../../../../Context/configs";
export default function EditDialog() {
  const UpdateStaff = IOMStore((state) => state.UpdateIOM);
  const ChaneEditDialog = IOMStore((state) => state.ChaneEditDialog);
  const ChaneSnackBarShow = IOMStore((state) => state.ChaneSnackBarShow);
  const ChangeSnackMessage = IOMStore((state) => state.ChangeSnackMessage);
  const StaffEditDialog = IOMStore((state) => state.IOMEditDialog);
  const StaffToHandle = IOMStore((state) => state.IOMToHandle);
  const Staff = IOMStore((state) => state.IOMlist);
  const [formAction, setformAction] = useState("info");
  const [showWarning, SetshowWarning] = useState(false);
  const UpdateHR = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    fetch(APIURL + "manager/iom/iomedit.php", {
      method: "post",
      body: JSON.stringify({
        fn: data.get("fullname"),
        ph: data.get("phone"),
        em: data.get("email"),
        un: data.get("username"),
        pw: data.get("password"),
        cpw: data.get("cpassword"),
        ref: data.get("ref"),
        act: formAction,
        IDEN: Cookies.get("userID"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          ChangeSnackMessage(item.content);
          if (item.id == 1) {
            ChaneSnackBarShow();
            ChaneEditDialog();
            fetch(APIURL + "manager/iom/iomlist.php", {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Request-Headers": "Content-Type, Authorization",
                "Access-Control-Allow-Credentials": "true",
              },
            })
              .then((res) => res.json())
              .then((result) => {
                UpdateStaff(result);
              });
          }
        });
      });
  };
  const CloseDialog = () => {
    ChaneEditDialog();
    SetshowWarning(false);
    setformAction("info");
  };
  return (
    <Dialog
      open={StaffEditDialog}
      onClose={CloseDialog}
      maxWidth="md"
      fullWidth
    >
      {Staff.filter((st) => st.staff_id === StaffToHandle).map((row) => (
        <Fragment key={row.staff_id}>
          <DialogTitle color={"#2f3193"}>
            Edit IOM HR Member {row.fullname}
          </DialogTitle>
          <DialogContent>
            <Box component="form" onSubmit={UpdateHR} noValidate sx={{ mt: 1 }}>
              <Grid container spacing={3}>
                <Grid item lg={12} md={12} sm={12} sx={{ display: "none" }}>
                  <TextField
                    type="hidden"
                    id="ref"
                    label="ref"
                    name="ref"
                    autoComplete="ref"
                    value={row.ref}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} sx={{ textAlign: "right" }}>
                  <Button color="primary" onClick={() => setformAction("info")}>
                    Personal Information
                  </Button>
                  <Button
                    color="warning"
                    onClick={() => setformAction("login")}
                  >
                    Login Information
                  </Button>
                </Grid>
                {formAction === "info" && (
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography sx={{ fontWeight: "bold" }} color={"#2f3193"}>
                      Personal info
                    </Typography>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required={true}
                        fullWidth
                        size="small"
                        id="fullname"
                        label="Fullname"
                        name="fullname"
                        autoComplete="fullname"
                        autoFocus
                        defaultValue={row.fullname}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required={true}
                        fullWidth
                        size="small"
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        defaultValue={row.email}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        fullWidth
                        size="small"
                        id="phone"
                        label="Phone Number"
                        name="phone"
                        autoComplete="phone"
                        defaultValue={row.phone}
                      />
                    </Grid>
                  </Grid>
                )}
                {formAction === "login" && (
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography sx={{ fontWeight: "bold" }} color={"#2f3193"}>
                      Account info
                    </Typography>
                    <Grid item lg={12} md={12} sm={12} sx={{ display: "none" }}>
                      <TextField
                        type="hidden"
                        id="fullname"
                        label="fullname"
                        name="fullname"
                        autoComplete="fullname"
                        value={row.fullname}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required={true}
                        fullWidth
                        size="small"
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        defaultValue={row.username}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        type="password"
                        fullWidth
                        size="small"
                        id="password"
                        label="Password"
                        name="password"
                        autoComplete="password"
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        type="password"
                        fullWidth
                        size="small"
                        id="cpassword"
                        label="Confirm Password"
                        name="cpassword"
                        autoComplete="cpassword"
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} md={12} lg={12} sx={{ padding: 3, mt: 5 }}>
                  {showWarning && (
                    <Alert variant="outlined" severity="warning">
                      Password and Password confirmation is not matched
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ textAlign: "right" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Update IOM HR Member
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="error"
                    sx={{ mt: 3, mb: 2, ml: 2 }}
                    onClick={CloseDialog}
                  >
                    Close Form
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </Fragment>
      ))}
    </Dialog>
  );
}
