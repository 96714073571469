import { Box } from "@mui/material";

export default function LoginFooter() {
  const SetYear = new Date().getFullYear();
  return (
    <Box sx={{ mt: 4, textAlign: "center" }}>
      <p className="text-soc text-small">
        © Copyright Stars Orbit {SetYear} All rights reserved.
        <br />
        Powered By{" "}
        <a href="https://minassa.tech" target="_blanck" className="text-soc">
          Minassa Solutions.
        </a>
      </p>
    </Box>
  );
}
