import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
  Grid,
  Button,
  MenuItem,
  TextField,
} from "@mui/material";
import { SoftwareStore } from "../../../../Store";
import { APIURL, FilterMonths } from "../../../../Context/configs";
import { useState } from "react";
import { read, utils } from "xlsx";
import { FacebookLoader } from "../../../Common/Loaders";
import { SoftwareCols } from "../../../../Context/DataGrids";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
export default function NewPayroll() {
  const SetOpenDia = SoftwareStore((state) => state.ChaneNewPayShow);
  const OpenDia = SoftwareStore((state) => state.NewPayShow);
  const SetLoadingShow = SoftwareStore((state) => state.ChaneLoadingShow);
  const ChaneSnackBarShow = SoftwareStore((state) => state.ChaneSnackBarShow);
  const ChangeSnackMessage = SoftwareStore((state) => state.ChangeSnackMessage);
  const [itemsPerPage, SetItemsPerPage] = useState(25);
  const [isloading, Setisloading] = useState(false);
  const [StartViewer, SetStartViewer] = useState(false);
  const [DataToView, SetDataToView] = useState([]);
  const CloseDialog = () => {
    SetOpenDia();
    SetStartViewer(false);
    Setisloading(false);
  };
  const StartUpload = (event) => {
    if (event.target.value != "") {
      Setisloading(true);
      SetStartViewer(true);
      const files = event.target.files;
      const f = files[0];
      const reader = new FileReader();
      reader.onload = function (event) {
        const data = event.target.result;
        let readedData = read(data, { type: "binary", cellDates: true });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];
        const dataParse = utils.sheet_to_json(ws, { header: 0 });
        SetDataToView(dataParse);
      };
      setTimeout(() => {
        reader.readAsBinaryString(f);
        Setisloading(false);
        event.target.value = "";
      }, 25000);
    }
  };
  const HandleChange = (e) => {
    SetItemsPerPage(e.target.value);
  };
  const ConfirmData = () => {
    SetLoadingShow();
    fetch(APIURL + "manager/software/importdata.php", {
      method: "post",
      body: JSON.stringify({
        iData: DataToView,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          ChangeSnackMessage(item.content);
          SetLoadingShow();
          CloseDialog();
          ChaneSnackBarShow();
        });
      });
  };
  return (
    <Dialog open={OpenDia} onClose={CloseDialog} maxWidth="xl" fullWidth>
      <DialogTitle color={"#2f3193"}>
        Import New Payroll Sheet For Software Link
      </DialogTitle>
      <DialogContent>
        <Box>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              size="small"
              name="file"
              type="file"
              accept=".xlsx"
              onChange={StartUpload}
            />
          </Grid>
        </Box>
        {StartViewer && (
          <Box sx={{ my: 2 }}>
            {isloading ? (
              <Box sx={{ textAlign: "center" }}>
                <FacebookLoader />
              </Box>
            ) : (
              <Grid container spacing={3}>
                <Grid item sm={12} xs={12} md={4} lg={4} sx={{ my: 1 }}>
                  <TextField
                    select
                    size="small"
                    fullWidth
                    label="Rows Per Page"
                    defaultValue={itemsPerPage}
                    onChange={HandleChange}
                  >
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={200}>200</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <DataGrid
                    getRowId={(row) => row.ref}
                    rows={DataToView}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        csvOptions: { disableToolbarButton: true },
                        printOptions: { disableToolbarButton: true },
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 250 },
                      },
                    }}
                    columns={SoftwareCols}
                    pageSize={itemsPerPage}
                    rowsPerPageOptions={[itemsPerPage]}
                    disableSelectionOnClick
                    autoHeight
                    width={"100%"}
                    disableColumnMenu
                    density="compact"
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {StartViewer && (
          <>
            {!isloading && (
              <Button variant="contained" color="success" onClick={ConfirmData}>
                Confirm Data
              </Button>
            )}
          </>
        )}
        <Button variant="contained" color="error" onClick={CloseDialog}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
