import { Box, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { RingLoader } from "../../Common/Loaders";
import BC from "../Elements/Common/BC";
import NewFeatures from "../Elements/WhatsNew/NewFeatures";

export default function Toturial() {
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    document.title = "StarsOrbit HRM - Whats's New";
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  }, []);
  return (
    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
      <BC data="Whats's New" />
      {isLoading ? (
        <Box sx={{ py: 5, my: 5, textAlign: "center" }}>
          <RingLoader />
        </Box>
      ) : (
        <NewFeatures />
      )}
    </Container>
  );
}
