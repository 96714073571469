import { Box, Typography } from "@mui/material";

export default function EditSuperVisors() {
  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="body2">
        Be Advised that changing this form data will not change the previous
        Evaluation And Leave Requests.
      </Typography>
    </Box>
  );
}
